import {UPLOAD_BIOMETRIC_LOADING , UPLOAD_BIOMETRIC_SUCCESS , UPLOAD_BIOMETRIC_FAILURE ,UPLOAD_BIOMETRIC_PROGRESS, CLEAN_UPLOAD_BIOMETRIC_DATA} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const Biometric = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            progress:null
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                progress:null
            }
    
    
        case CLEAN_UPLOAD_BIOMETRIC_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                progress:null
            }
    
        
        case UPLOAD_BIOMETRIC_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                progress:null
            }
        
        
        case UPLOAD_BIOMETRIC_PROGRESS:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                progress:action.progress
            }


        case UPLOAD_BIOMETRIC_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,  
                progress:null
            }
        case UPLOAD_BIOMETRIC_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],          
                progress:null

            }

        default:
        return state
    
    }
    }
    
    
    
export default Biometric;
    