// material
import { styled } from '@material-ui/core/styles';
import { Button, Card,CardHeader,CardContent, Typography, Stack } from '@material-ui/core';
// utils
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const RowStyle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

// ----------------------------------------------------------------------

export default function SelfInfo() {
  
    const UserData = useSelector(state => state.login.data) 


  return (
    <Card className='dashBoardCardBorder'>
      <CardHeader title="UserInfo" />
      <CardContent>
        <Stack spacing={2}>
          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Name
            </Typography>
            <Typography variant="subtitle1">{UserData.name}</Typography>
          </RowStyle>

          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Mobile
            </Typography>
            <Typography variant="subtitle1">{UserData.mobile}</Typography>
          </RowStyle>

          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Email
            </Typography>
            <Typography variant="subtitle1">{UserData.email}</Typography>
          </RowStyle>        
          <RowStyle>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Address
            </Typography>
            <Typography variant="subtitle1">{UserData.address}</Typography>
          </RowStyle>        
        </Stack>

      </CardContent>
    </Card>
  );
}
