import axios from "axios";
import {Base_URL} from '../config/BaseUrlConfig';

import {addLinksBegin , addLinksSuccess , addLinksFailure} from './ActionCreator';
import {getLinksBegin , getLinksSuccess , getLinksFailure} from './ActionCreator';

export const addLinks = (data) => {

    return (dispatch) => {
        dispatch(addLinksBegin());

        axios.post(Base_URL + '/admin/addDownloadLink', data)
            .then((res) => {
                dispatch(addLinksSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addLinksFailure(err.response.data));

                }
            })
    }
}


export const getLinks = (x) => {

    return (dispatch) => {
        dispatch(getLinksBegin());

        axios.get(Base_URL + '/admin/getDownloadLinks', {params:{exam_id:x}})
            .then((res) => {
                dispatch(getLinksSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getLinksFailure(err.response.data));

                }
            })
    }
}

