import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import cubeIcon from '@iconify/icons-eva/cube-fill';
import historyIcon from '@iconify/icons-ant-design/history';
import checkIcon from '@iconify/icons-ant-design/check-circle';
import cIcon from '@iconify/icons-ant-design/carry-out-fill';
import exportIcon from '@iconify/icons-ant-design/export';
import downloadIcon from '@iconify/icons-eva/download-fill';


// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;


const sidebarConfig = [
  {
    subheader:'Dashboard',
    items :[
      {
        title: 'dashboard',
        path: '/dashboard',
        icon: getIcon(pieChart2Fill)
      }
    ]
  },
  {
    subheader:'Pre Exam',
    items :[      
      {
        title: 'Exam Profile',
        path: '/exam',
        icon: getIcon(fileTextFill)
      },  
      {
        title: 'Center Details',
        path: '/center/all-list',
        icon: getIcon(cubeIcon)
      },
      {
        title: 'CI Details',
        path: '/ci/details',
        icon: getIcon(peopleFill)
      },
      {
        title: 'Add Download Links',
        path: '/add-link',
        icon: getIcon(downloadIcon)
      }
    ]
  },
  {
    subheader:'During Exam',
    items :[            
      {
        title: 'Verify Data',
        path: '/verify-data',
        icon: getIcon(checkIcon)
      },  
      {
        title: 'Re-Upload Requests',
        path: '/rejection-request/list',
        icon: getIcon(peopleFill)
      }
    ]
  },
  {
    subheader:'Post Exam',
    items :[                   
      {
        title: 'Center Data',
        path: '/post-exam/center-data',
        icon: getIcon(cIcon)
      },
      {
        title: 'Export Data',
        path: '/post-exam/export-data',
        icon: getIcon(exportIcon)
      }
    ]
  },
  {
    subheader:'History',
    items :[                           
      {
        title: 'History',
        path: '/history/all-ci-history',
        icon: getIcon(historyIcon)
      }

    ]
  }

  
];


export default sidebarConfig;
