import React, {useEffect, useState} from 'react';

// material
import { 
  Box, 
  Container, 
  Typography, 
  Grid,
  Card,
  CardHeader,
  CardContent
} from '@material-ui/core';

// components
import Page from '../../../components/Page';
import SlotList from './components/slotList';
import SelfInfo from './components/selfInfo';
import CenterInfo from './components/centerInfo';
import UploadedData from './components/dataStatus';
import { useSelector, useDispatch } from 'react-redux';

// Api

import {CiBackupStatus} from 'src/Actions/CiActions/BackupStatus'


export default function DashboardApp() {
  const dispatch = useDispatch();
  
  const UserData = useSelector(state => state.login.data);
  
  const [val, setVal] = useState({
    exam_id:UserData.exam_id,
    ec_id:UserData.ec_id
  })
  

  useEffect(() => {
    dispatch(CiBackupStatus(val))


  }, [])

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi {UserData.name}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={6}>            
            <SelfInfo />
            <SlotList />              
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <CenterInfo />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card className='dashboardAdminUploadedDataCard'>
              <CardHeader title="Uploaded Data Status"  />  
              <CardContent>
                <UploadedData />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        
      </Container>
    </Page>
  );
}
