import React, {useState} from 'react';


import {useSelector} from 'react-redux'

import AdminRoute from './AdminRoutes'
import CiRoute from './CiRoutes'
import LoginRoute from './login'

// ----------------------------------------------------------------------

const App2 = () => {
  
  
  const auth = useSelector((state)=> state.login.data &&  state.login.data.token && state.login.data.token !== null && state.login.data.token !==  undefined && state.login.data.token !== "" ? state.login.data.token : null  )
  const authRole = useSelector((state)=> state.login.data &&  state.login.data.role && state.login.data.role !== null && state.login.data.role !==  undefined && state.login.data.role !== "" ? state.login.data.role : null  )
  // console.log(store.getState().login.data.token)

  

  return (
  <>
        {auth && authRole==="Admin" ? <AdminRoute/> : auth && authRole==="CI" ? <CiRoute/> :<LoginRoute />}
        {/* {auth === "login-token" ? <Route1/> : <Route1/>} */}
</>
    
  );
}


export default App2;
