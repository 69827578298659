import React, {useEffect, useState} from 'react';
import { filter } from 'lodash';
import {useNavigate} from 'react-router-dom'

// material
import { 
  Badge, 
  Grid, 
  Stack,
  TextField,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Switch,
  Tooltip,
  Breadcrumbs,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Select,
  Button,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DatePicker from '@material-ui/lab/DatePicker';
import { useTheme } from '@material-ui/styles';
// Icons
import { Icon } from '@iconify/react';

import branchIcon from '@iconify/icons-ant-design/deployment-unit-outline';

import plusFill from '@iconify/icons-eva/plus-fill';
// components
import Page from '../../../components/Page';
import { UserListHead, UserListToolbar } from '../../../components/_dashboard/user';
import {tostS   , tostE, tostW } from '../../../config/Toast'
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { useSelector , useDispatch  } from 'react-redux';

// API


import {addExam, getExam, setExamPrimary} from '../../../Actions/AdminActions/Exam'
import { cleanExamData, cleanExamSlotData, loginSuccess } from '../../../Actions/ActionCreator';
import {getAvlExamSlot, addExamSlot} from '../../../Actions/AdminActions/Slots'
import moment from 'moment';





// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'exam_name', label: 'Exam Name' },
  { id: 'from_date', label: 'Starting Date' },
  { id: 'to_date', label: 'End Date' },
  { id: 'number_of_centres', label: 'Number of Centers' },
  { id: 'center_view', label:"Allocate Centers"  },
  { id: 'slot_view', label:"Add Slot"  },
  { id: 'set_primary_exam', label:"Set Primary"  },
  // { id: 'active_status', label:"Active Status"  },
  // { id: 'disable_exam', label:"Disable Exam"  }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.exam_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function ExamScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('exam_name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };


  const listData = useSelector(state => state.Exam.getData && 
    Array.isArray(state.Exam.getData) && 
    state.Exam.getData.length ? state.Exam.getData : [])


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const filteredUsers = applySortFilter(listData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;


    const a_id = useSelector(state => state.login.data.admin_id);
    

  const [values, setValues] = useState({
    exam_name : "",
    number_of_centres :"",
    admin_id : a_id, 
    from_date  : new Date(Date.now()), 
    to_date  : new Date(Date.now()), 
  })

  const handleChange = (event) => {
    setValues({
        ...values,
        [event.target.name]:event.target.value
    })
  }

  const handleFromDateChange = (date) =>{
    
    setValues({
      ...values,
      ['from_date']:date
    })
  }
  const handleToDateChange = (date) =>{
    setValues({
      ...values,
      ['to_date']:date
    })
  }

  const handleSubmit = () => {
    if(values.exam_name==="" || values.exam_name===null || values.exam_name===undefined){
      tostW("Please Enter Exam Name");
    }else if(values.number_of_centres==="" || values.number_of_centres===null || values.number_of_centres===undefined){
      tostW("Please Enter Number of Center in Exam!!");
    }else if(values.number_of_centres==="" || values.number_of_centres===null || values.number_of_centres===undefined){
      tostW("Please Enter Number of Center in Exam!!");
    }else if(moment(values.from_date).isSame(moment(values.to_date))){
      tostW("Start date  and end date can't be same!!");
    }else if(moment(values.from_date).isAfter(moment(values.to_date))){
      tostW("Start Date must be less than End Date!!");
    }else{
      new Promise((resolve, reject) => {
        dispatch(addExam(values))
        // console.log(values)
        resolve()
      }).then(() => {
        setValues({
          ...values,
          ['exam_name'] : "",
          ['number_of_centres'] :"",
          ['from_date']  : new Date(Date.now()), 
          ['to_date']  : new Date(Date.now()), 
        })
      })

    }
  }



  const AddedSlotData = useSelector(state => state.Slots.avlData && Array.isArray(state.Slots.avlData) && state.Slots.avlData.length ? state.Slots.avlData : [])

  const LoginData = useSelector(state=> state.login.data)

  const AddMsg = useSelector(state => state.Exam.data && state.Exam.data.status && state.Exam.data.status.message ? state.Exam.data.status.message : null);
  const AddErr = useSelector(state => state.Exam.error && state.Exam.error.message ? state.Exam.error.message : null);
  const SetMsg = useSelector(state => state.Exam.setData && state.Exam.setData && state.Exam.setData.message ? state.Exam.setData.message : null);
  const SetErr = useSelector(state => state.Exam.setError && state.Exam.setError.message ? state.Exam.setError.message : null);
  const SlotAddMsg = useSelector(state => state.Slots.data && state.Slots.data.status && state.Slots.data.status.message ? state.Slots.data.status.message : null);
  const SlotAddErr = useSelector(state => state.Slots.error && state.Slots.error.message ? state.Slots.error.message : null);


  const setExamFun = (id, data) => {
    data['exam_id'] = id;
    dispatch(loginSuccess(data));
    
  }

  useEffect(() =>{
    dispatch(getExam())
    let x  = new Promise((resolve ,reject)=>{
    
      if(AddMsg !== null)
      {
        tostS(AddMsg)
      }
      if(AddErr !== null)
      {
        tostE(AddErr)
      }
      if(SetMsg !== null)
      {
        tostS(SetMsg)
        
      }
      if(SetErr !== null)
      {
        tostE(SetErr)
      }
      if(SlotAddMsg !== null)
      {
        tostS(SlotAddMsg)
      }
      if(SlotAddErr !== null)
      {
        tostE(SlotAddErr)
      }

      resolve()
  
    })
    x.then( () => {
      dispatch(cleanExamData())
      dispatch(cleanExamSlotData())
      dispatch(getExam())
    })
    

  }, [AddErr, AddMsg, SlotAddMsg, SlotAddErr, SetMsg, SetErr])


  const handleNavigateToCenter = (data) =>{
    navigate('/exam-center/'+data.exam_id, {state:data})
  }

  const handleSwitchPrimary = (data) =>{
    dispatch(setExamPrimary(data.exam_id))
    setExamFun(data.exam_id, LoginData)
  }

// +++++++++++++++++++++++++++ Add Slot To Exam Start +++++++++++++++++++++++++++++++++++++++++++++++++

const [open, setOpen] = useState({
  exam_id:"",
  admin_id:a_id,
  show:false,
  slot_name:"",
  exam_name:""
});


const handleOpen = (d) => {
  dispatch(getAvlExamSlot(d.exam_id))
  setOpen({
    ...open,
    exam_id:d.exam_id,
    exam_name:d.exam_name,
    show:true
  })
}

const handleClose = () =>{
  setOpen({
    ...open,
    exam_id:"",
    show:false,
    slot_name:"",
    exam_name:""
  })
}

const handleSlotChange = (event) => {
  setOpen({
    ...open,
    ['slot_name']:(event.target.value).toUpperCase()
  })
}

const handleSubmitSlot = () => {
  if(open.slot_name.length){
    const sendData = {
      "slot_name" : open.slot_name,
      "exam_id" : open.exam_id,
      "admin_id" : open.admin_id
    }  
  
    dispatch(addExamSlot(sendData))

  }else{
    tostW("Please Enter Slot Name")
  }
  setOpen({
    ...open,
    exam_id:"",
    show:false,
    slot_name:"",
    exam_name:""
  })
}

// +++++++++++++++++++++++++++ Add Slot To Exam End+++++++++++++++++++++++++++++++++++++++++++++++++



  return (
    <Page title="Exam Profile">

      <Dialog disableBackdropClick={true} open={open.show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{open.exam_name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant='overline'>Already Added Slots :</Typography>
            <Stack direction="row" alignItems="start" md={5} flexWrap="wrap" justifyContent="space-between">
              {Array.isArray(AddedSlotData) && AddedSlotData.length ?
                AddedSlotData.map((data) => (
                <Typography variant='body2'>{data.slot_name},</Typography>

                ))
              :
              <Typography variant='body2'>None</Typography>
              
              }

            </Stack>
            <Divider />
            Add Slots to Exam
            
          </DialogContentText>
          <Grid container spacing={3}>                                
            <Grid item mt={2}>
              <TextField
                fullWidth                
                placeholder="Slot Name"
                name="slot_name"
                onChange={handleSlotChange}
                required
                value={open.slot_name}
                variant="outlined"
              />
            </Grid>                                            
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmitSlot} color="primary">
            Save Details
          </Button>
        </DialogActions>
      </Dialog>
      
      <Container maxWidth="xl" style={{backgroundColor:theme.palette.background.paper, elevation:15, marginBottom:'15px'}}>
        <Stack sx={{ pb: 3, pt:3 }}>
          <Typography variant="h4" gutterBottom>
            Create Exam Profile
          </Typography>
          
        </Stack>
        {/* <Grid item xs={12}>
          <Breadcrumbs>            
            <Typography sx={{ color: 'text.primary' }}>Exam Profile</Typography>
          </Breadcrumbs>
        </Grid> */}
        
        <Grid container spacing={3}>
          <Grid item md={6} sm={12}>
            <TextField
            fullWidth            
            name="exam_name"
            label="Exam Name"
            value={values.exam_name}
            onChange = {handleChange}
          />
          </Grid>
          <Grid item md={6} sm={12}>
            <TextField
              fullWidth            
              name="number_of_centres"
              label="Number of Centers"
              type="number"
              value={values.number_of_centres}
              onChange = {handleChange}
            />
          </Grid>
          <Grid item md={6} sm={12}>
            <FormControl fullWidth>
              <DatePicker
                label="From date" 
                value={values.from_date}         
                onChange={(date) => handleFromDateChange(date)}              
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} sm={12}>
            <FormControl fullWidth>
              <DatePicker
                  label="To date"                  
                  value={values.to_date}         
                  onChange={(date) => handleToDateChange(date)}              
                  inputFormat="dd/MM/yyyy"
                  renderInput={(params) => <TextField {...params} />}
                />
            </FormControl>
          </Grid>
        </Grid>
        
        
        
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"          
          onClick={() => {handleSubmit()}}
          sx={{ my: 2 }}
        >
          Create Exam Profile 
        </LoadingButton>
          
      </Container>
      
          <Card className='cardBorder'>
            <CardHeader title="Exam Details" />
            <UserListToolbar              
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listData.length}                    
                    onRequestSort={handleRequestSort}                    
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { exam_id, exam_name , from_date , to_date , number_of_centres, is_primary, is_active } = row;
                        const isItemSelected = selected.indexOf(exam_id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={exam_id}
                            tabIndex={-1}
                            className="tableRowBottomBorder"
                            
                          >                                                        
                            <TableCell>
                              <Badge variant='standard' badgeContent={is_primary ? "P" : null} color="primary">
                                <Typography>
                                  {exam_name}

                                </Typography>
                              </Badge>
                            </TableCell>
                            <TableCell>{moment(from_date).format("MMMM Do YYYY")}</TableCell>
                            <TableCell>{moment(to_date).format("MMMM Do YYYY")}</TableCell>                          
                            <TableCell>{number_of_centres}</TableCell>
                            
                            <TableCell>  
                                <Tooltip title="Center Details">
                                  <Icon icon={branchIcon} onClick = {() => {handleNavigateToCenter({"exam_id":exam_id, "exam_name":exam_name , "from_date":from_date , "to_date":to_date , "number_of_centres":number_of_centres})}} width={24} height={24} style={{marginRight:20 , cursor : "pointer"}} />                                                 

                                </Tooltip>
                                
                            </TableCell>
                            <TableCell spacing={3}>  
                                
                                <Tooltip title="Add Slots">
                                  <Icon icon={plusFill} onClick = {() => {handleOpen({"exam_id":exam_id, "exam_name":exam_name})}} width={24} height={24} style={{marginRight:20 , cursor : "pointer"}} />                                                 

                                </Tooltip>
                            </TableCell>
                            <TableCell spacing={3}>  
                                
                                <Tooltip title="Set Exam As Primary">
                                <Switch checked={is_primary} onClick = {()=> {handleSwitchPrimary({"exam_id":exam_id})}} />


                                </Tooltip>
                            </TableCell>
                            {/* <TableCell style={{color:is_active?"green":"red"}}>{is_active ? "Active" : "In-Active"}</TableCell>
                            <TableCell>  
                                
                                <Tooltip title="Disable Exam">
                                <Switch checked={is_active} onClick = {()=> {handleSwitchPrimary({"exam_id":exam_id})}} />


                                </Tooltip>
                            </TableCell> */}

                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        
        
    </Page>
  );
}
