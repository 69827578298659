import {GET_DASHBOARD_LOADING , GET_DASHBOARD_SUCCESS , GET_DASHBOARD_FAILURE} from '../Actions/Types';
import {SET_SLOT, CLEAN_ALL_DATA} from '../Actions/Types'

const Dashboard = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            getData:[{}],
            getError:{},
            slot:null
        }
    }

    switch(action.type) {
        
        case CLEAN_ALL_DATA:
            return {
                ...state,
                loading:false,
                getError: {},
                getData : {},
                slot:null
            }
        
        case SET_SLOT:
            return {
                ...state,
                slot:action.data
                
            }
        case GET_DASHBOARD_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                loading:false,                
                getData:action.data,
                getError:{}
            }
        case GET_DASHBOARD_FAILURE:
            return {
                ...state,
                loading:false,                
                getData:{},
                getError:action.error,
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default Dashboard;
    