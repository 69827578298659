// import axios from "../Config/Interceptor1";


import axios from 'axios'


import { Base_URL } from '../../config/BaseUrlConfig'


import {loginBegin , loginSuccess , loginFailure} from '../ActionCreator'





export const login = (session) => {
    console.log(session);
    var y = {
      mobile : session.mobile,
      password : session.password
    }
    return (dispatch) => {
        dispatch(loginBegin());

        axios.post(Base_URL + '/admin/loginadmin', y)
            .then((res) => {              
              var temp  = res.data.obj
              if(res.data.obj)
              {
                
                temp['token'] = res.headers.authorization
              }

                localStorage.setItem('token', res.headers.authorization);
                dispatch(loginSuccess(temp));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(loginFailure(err.response.data));

                }
            })
    }
}

