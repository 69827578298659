export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const LOGOUT_LOADING = "LOGOUT_LOADING";
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';


export const CLEAN_ALL_DATA = 'CLEAN_ALL_DATA';

export const SET_SLOT = 'SET_SLOT';

export const GET_COUNTRY_DATA_LOADING = "GET_COUNTRY_DATA_LOADING";
export const GET_COUNTRY_DATA_SUCCESS = 'GET_COUNTRY_DATA_SUCCESS';
export const GET_COUNTRY_DATA_FAILURE = 'GET_COUNTRY_DATA_FAILURE';

export const GET_STATE_DATA_LOADING = "GET_STATE_DATA_LOADING";
export const GET_STATE_DATA_SUCCESS = 'GET_STATE_DATA_SUCCESS';
export const GET_STATE_DATA_FAILURE = 'GET_STATE_DATA_FAILURE';

export const GET_CITY_DATA_LOADING = "GET_CITY_DATA_LOADING";
export const GET_CITY_DATA_SUCCESS = 'GET_CITY_DATA_SUCCESS';
export const GET_CITY_DATA_FAILURE = 'GET_CITY_DATA_FAILURE';

export const GET_DASHBOARD_LOADING = "GET_DASHBOARD_LOADING";
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

// Admin Exam Profiles

export const ADD_EXAM_LOADING = "ADD_EXAM_LOADING";
export const ADD_EXAM_SUCCESS = 'ADD_EXAM_SUCCESS';
export const ADD_EXAM_FAILURE = 'ADD_EXAM_FAILURE';

export const GET_EXAM_LOADING = "GET_EXAM_LOADING";
export const GET_EXAM_SUCCESS = 'GET_EXAM_SUCCESS';
export const GET_EXAM_FAILURE = 'GET_EXAM_FAILURE';

export const SET_EXAM_PRIMARY_LOADING = "SET_EXAM_PRIMARY_LOADING";
export const SET_EXAM_PRIMARY_SUCCESS = 'SET_EXAM_PRIMARY_SUCCESS';
export const SET_EXAM_PRIMARY_FAILURE = 'SET_EXAM_PRIMARY_FAILURE';


export const CLEAN_EXAM_DATA = 'CLEAN_EXAM_DATA';

// Admin Exam Slots

export const ADD_EXAM_SLOT_LOADING = "ADD_EXAM_SLOT_LOADING";
export const ADD_EXAM_SLOT_SUCCESS = 'ADD_EXAM_SLOT_SUCCESS';
export const ADD_EXAM_SLOT_FAILURE = 'ADD_EXAM_SLOT_FAILURE';

export const GET_CENTER_SLOT_LOADING = "GET_CENTER_SLOT_LOADING";
export const GET_CENTER_SLOT_SUCCESS = 'GET_CENTER_SLOT_SUCCESS';
export const GET_CENTER_SLOT_FAILURE = 'GET_CENTER_SLOT_FAILURE';


export const GET_AVL_SLOT_LOADING = "GET_AVL_SLOT_LOADING";
export const GET_AVL_SLOT_SUCCESS = 'GET_AVL_SLOT_SUCCESS';
export const GET_AVL_SLOT_FAILURE = 'GET_AVL_SLOT_FAILURE';


export const CLEAN_EXAM_SLOT_DATA = 'CLEAN_EXAM_SLOT_DATA';

// Admin Exam Centers

export const ADD_EXAM_CENTER_LOADING = "ADD_EXAM_CENTER_LOADING";
export const ADD_EXAM_CENTER_SUCCESS = 'ADD_EXAM_CENTER_SUCCESS';
export const ADD_EXAM_CENTER_FAILURE = 'ADD_EXAM_CENTER_FAILURE';

export const ALLOCATE_EXAM_CENTER_LOADING = "ALLOCATE_EXAM_CENTER_LOADING";
export const ALLOCATE_EXAM_CENTER_SUCCESS = 'ALLOCATE_EXAM_CENTER_SUCCESS';
export const ALLOCATE_EXAM_CENTER_FAILURE = 'ALLOCATE_EXAM_CENTER_FAILURE';


export const GET_ALL_CENTER_LOADING = "GET_ALL_CENTER_LOADING";
export const GET_ALL_CENTER_SUCCESS = 'GET_ALL_CENTER_SUCCESS';
export const GET_ALL_CENTER_FAILURE = 'GET_ALL_CENTER_FAILURE';

export const GET_AVL_CENTER_LOADING = "GET_AVL_CENTER_LOADING";
export const GET_AVL_CENTER_SUCCESS = 'GET_AVL_CENTER_SUCCESS';
export const GET_AVL_CENTER_FAILURE = 'GET_AVL_CENTER_FAILURE';

export const GET_EXAM_CENTER_LOADING = "GET_EXAM_CENTER_LOADING";
export const GET_EXAM_CENTER_SUCCESS = 'GET_EXAM_CENTER_SUCCESS';
export const GET_EXAM_CENTER_FAILURE = 'GET_EXAM_CENTER_FAILURE';

export const CLEAN_EXAM_CENTER_DATA = 'CLEAN_EXAM_CENTER_DATA';



// Admin Exam CI

export const ADD_CI_LOADING = "ADD_CI_LOADING";
export const ADD_CI_SUCCESS = 'ADD_CI_SUCCESS';
export const ADD_CI_FAILURE = 'ADD_CI_FAILURE';

export const GET_CENTER_CI_LOADING = "GET_CENTER_CI_LOADING";
export const GET_CENTER_CI_SUCCESS = 'GET_CENTER_CI_SUCCESS';
export const GET_CENTER_CI_FAILURE = 'GET_CENTER_CI_FAILURE';

export const GET_ALL_CI_LOADING = "GET_ALL_CI_LOADING";
export const GET_ALL_CI_SUCCESS = 'GET_ALL_CI_SUCCESS';
export const GET_ALL_CI_FAILURE = 'GET_ALL_CI_FAILURE';

export const CLEAN_CI_DATA = 'CLEAN_CI_DATA';


// Re Upload Requests

export const APPROVE_UPLOAD_REQUEST_LOADING = "APPROVE_UPLOAD_REQUEST_LOADING";
export const APPROVE_UPLOAD_REQUEST_SUCCESS = 'APPROVE_UPLOAD_REQUEST_SUCCESS';
export const APPROVE_UPLOAD_REQUEST_FAILURE = 'APPROVE_UPLOAD_REQUEST_FAILURE';

export const GET_UPLOAD_REQUEST_LOADING = "GET_UPLOAD_REQUEST_LOADING";
export const GET_UPLOAD_REQUEST_SUCCESS = 'GET_UPLOAD_REQUEST_SUCCESS';
export const GET_UPLOAD_REQUEST_FAILURE = 'GET_UPLOAD_REQUEST_FAILURE';


export const CLEAN_UPLOAD_REQUEST_DATA = 'CLEAN_UPLOAD_REQUEST_DATA';


// All CI History

export const GET_ALL_CI_HISTORY_LOADING = "GET_ALL_CI_HISTORY_LOADING";
export const GET_ALL_CI_HISTORY_SUCCESS = 'GET_ALL_CI_HISTORY_SUCCESS';
export const GET_ALL_CI_HISTORY_FAILURE = 'GET_ALL_CI_HISTORY_FAILURE';


// Admin Password Change

export const CHANGE_ADMIN_PASS_LOADING = "CHANGE_ADMIN_PASS_LOADING";
export const CHANGE_ADMIN_PASS_SUCCESS = 'CHANGE_ADMIN_PASS_SUCCESS';
export const CHANGE_ADMIN_PASS_FAILURE = 'CHANGE_ADMIN_PASS_FAILURE';

export const CHANGE_ADMIN_PROFILE_PIC_LOADING = "CHANGE_ADMIN_PROFILE_PIC_LOADING";
export const CHANGE_ADMIN_PROFILE_PIC_SUCCESS = 'CHANGE_ADMIN_PROFILE_PIC_SUCCESS';
export const CHANGE_ADMIN_PROFILE_PIC_FAILURE = 'CHANGE_ADMIN_PROFILE_PIC_FAILURE';


export const CLEAN_PROFILE_DATA = 'CLEAN_PROFILE_DATA';




// Verify Data

export const VERIFY_DATA_LOADING = "VERIFY_DATA_LOADING";
export const VERIFY_DATA_SUCCESS = 'VERIFY_DATA_SUCCESS';
export const VERIFY_DATA_FAILURE = 'VERIFY_DATA_FAILURE';

export const GET_VERIFIED_DATA_LOADING = "GET_VERIFIED_DATA_LOADING";
export const GET_VERIFIED_DATA_SUCCESS = 'GET_VERIFIED_DATA_SUCCESS';
export const GET_VERIFIED_DATA_FAILURE = 'GET_VERIFIED_DATA_FAILURE';


export const CLEAN_VERIFIED_DATA = 'CLEAN_VERIFIED_DATA';



// Data Links

export const ADD_LINKS_LOADING = "ADD_LINKS_LOADING";
export const ADD_LINKS_SUCCESS = 'ADD_LINKS_SUCCESS';
export const ADD_LINKS_FAILURE = 'ADD_LINKS_FAILURE';

export const GET_LINKS_LOADING = "GET_LINKS_LOADING";
export const GET_LINKS_SUCCESS = 'GET_LINKS_SUCCESS';
export const GET_LINKS_FAILURE = 'GET_LINKS_FAILURE';


export const CLEAN_LINKS_DATA = 'CLEAN_LINKS_DATA';



// Post Exam Data

export const GET_MASTER_BACKUP_LOADING = "GET_MASTER_BACKUP_LOADING";
export const GET_MASTER_BACKUP_SUCCESS = 'GET_MASTER_BACKUP_SUCCESS';
export const GET_MASTER_BACKUP_FAILURE = 'GET_MASTER_BACKUP_FAILURE';



/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

CI Type

++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */


// Upload Annexure

export const GET_UPLOADED_DATA_LOADING = "GET_UPLOADED_DATA_LOADING";
export const GET_UPLOADED_DATA_SUCCESS = 'GET_UPLOADED_DATA_SUCCESS';
export const GET_UPLOADED_DATA_FAILURE = 'GET_UPLOADED_DATA_FAILURE';

// Request To Re upload

export const REQ_UPLOAD_DATA_LOADING = "REQ_UPLOAD_DATA_LOADING";
export const REQ_UPLOAD_DATA_SUCCESS = 'REQ_UPLOAD_DATA_SUCCESS';
export const REQ_UPLOAD_DATA_FAILURE = 'REQ_UPLOAD_DATA_FAILURE';

export const CLEAN_UPLOADED_DATA = 'CLEAN_UPLOADED_DATA';

// Upload Annexure

export const UPLOAD_ANNEXURE_LOADING = "UPLOAD_ANNEXURE_LOADING";
export const UPLOAD_ANNEXURE_SUCCESS = 'UPLOAD_ANNEXURE_SUCCESS';
export const UPLOAD_ANNEXURE_FAILURE = 'UPLOAD_ANNEXURE_FAILURE';
export const UPLOAD_ANNEXURE_PROGRESS = 'UPLOAD_ANNEXURE_PROGRESS';

export const CLEAN_UPLOAD_ANNEXURE_DATA = 'CLEAN_UPLOAD_ANNEXURE_DATA';

// Upload Biometric Backup

export const UPLOAD_BIOMETRIC_LOADING = "UPLOAD_BIOMETRIC_LOADING";
export const UPLOAD_BIOMETRIC_SUCCESS = 'UPLOAD_BIOMETRIC_SUCCESS';
export const UPLOAD_BIOMETRIC_FAILURE = 'UPLOAD_BIOMETRIC_FAILURE';
export const UPLOAD_BIOMETRIC_PROGRESS = 'UPLOAD_BIOMETRIC_PROGRESS';

export const CLEAN_UPLOAD_BIOMETRIC_DATA = 'CLEAN_UPLOAD_BIOMETRIC_DATA';

// Upload Server Backup

export const UPLOAD_SERVER_LOADING = "UPLOAD_SERVER_LOADING";
export const UPLOAD_SERVER_SUCCESS = 'UPLOAD_SERVER_SUCCESS';
export const UPLOAD_SERVER_FAILURE = 'UPLOAD_SERVER_FAILURE';
export const UPLOAD_SERVER_PROGRESS = 'UPLOAD_SERVER_PROGRESS';

export const CLEAN_UPLOAD_SERVER_DATA = 'CLEAN_UPLOAD_SERVER_DATA';

// Upload Attendance Sheet

export const UPLOAD_ATT_SHEET_LOADING = "UPLOAD_ATT_SHEET_LOADING";
export const UPLOAD_ATT_SHEET_SUCCESS = 'UPLOAD_ATT_SHEET_SUCCESS';
export const UPLOAD_ATT_SHEET_FAILURE = 'UPLOAD_ATT_SHEET_FAILURE';
export const UPLOAD_ATT_SHEET_PROGRESS = 'UPLOAD_ATT_SHEET_PROGRESS';

export const CLEAN_UPLOAD_ATT_SHEET_DATA = 'CLEAN_UPLOAD_ATT_SHEET_DATA';


// Upload Response Summary

export const UPLOAD_RES_SHEET_LOADING = "UPLOAD_RES_SHEET_LOADING";
export const UPLOAD_RES_SHEET_SUCCESS = 'UPLOAD_RES_SHEET_SUCCESS';
export const UPLOAD_RES_SHEET_FAILURE = 'UPLOAD_RES_SHEET_FAILURE';
export const UPLOAD_RES_SHEET_PROGRESS = 'UPLOAD_RES_SHEET_PROGRESS';

export const CLEAN_UPLOAD_RES_SHEET_DATA = 'CLEAN_UPLOAD_RES_SHEET_DATA';


// Upload Catelina File

export const UPLOAD_CATELINA_LOADING = "UPLOAD_CATELINA_LOADING";
export const UPLOAD_CATELINA_SUCCESS = 'UPLOAD_CATELINA_SUCCESS';
export const UPLOAD_CATELINA_FAILURE = 'UPLOAD_CATELINA_FAILURE';
export const UPLOAD_CATELINA_PROGRESS = 'UPLOAD_CATELINA_PROGRESS';

export const CLEAN_UPLOAD_CATELINA_DATA = 'CLEAN_UPLOAD_CATELINA_DATA';

// Upload Rejection Summary

export const CI_BACKUP_STATUS_LOADING = "CI_BACKUP_STATUS_LOADING";
export const CI_BACKUP_STATUS_SUCCESS = 'CI_BACKUP_STATUS_SUCCESS';
export const CI_BACKUP_STATUS_FAILURE = 'CI_BACKUP_STATUS_FAILURE';


// Get Backup Status

export const ADD_REJ_SUMMARY_LOADING = "ADD_REJ_SUMMARY_LOADING";
export const ADD_REJ_SUMMARY_SUCCESS = 'ADD_REJ_SUMMARY_SUCCESS';
export const ADD_REJ_SUMMARY_FAILURE = 'ADD_REJ_SUMMARY_FAILURE';

export const CLEAN_ADD_REJ_SUMMARY_DATA = 'CLEAN_ADD_REJ_SUMMARY_DATA';

export const UPLOAD_REJ_SUMMARY_LOADING = "UPLOAD_REJ_SUMMARY_LOADING";
export const UPLOAD_REJ_SUMMARY_SUCCESS = 'UPLOAD_REJ_SUMMARY_SUCCESS';
export const UPLOAD_REJ_SUMMARY_FAILURE = 'UPLOAD_REJ_SUMMARY_FAILURE';
export const UPLOAD_REJ_SUMMARY_PROGRESS = 'UPLOAD_REJ_SUMMARY_PROGRESS';

export const CLEAN_UPLOAD_REJ_SUMMARY_DATA = 'CLEAN_UPLOAD_REJ_SUMMARY_DATA';




// CI Password Change

export const CHANGE_CI_PASS_LOADING = "CHANGE_CI_PASS_LOADING";
export const CHANGE_CI_PASS_SUCCESS = 'CHANGE_CI_PASS_SUCCESS';
export const CHANGE_CI_PASS_FAILURE = 'CHANGE_CI_PASS_FAILURE';

export const CHANGE_CI_PROFILE_PIC_LOADING = "CHANGE_CI_PROFILE_PIC_LOADING";
export const CHANGE_CI_PROFILE_PIC_SUCCESS = 'CHANGE_CI_PROFILE_PIC_SUCCESS';
export const CHANGE_CI_PROFILE_PIC_FAILURE = 'CHANGE_CI_PROFILE_PIC_FAILURE';


export const CLEAN_CI_PROFILE_DATA = 'CLEAN_CI_PROFILE_DATA';
