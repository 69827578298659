import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import { CiBackupStatusBegin, CiBackupStatusSuccess, CiBackupStatusFailure } from "../ActionCreator";

export const CiBackupStatus = (data) => {

   return (dispatch) => {
       dispatch(CiBackupStatusBegin());

       axios.get(Base_URL + '/admin/getCIBackupStatus' , {params:data})
           .then((res) => {
               dispatch(CiBackupStatusSuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   console.log("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(CiBackupStatusFailure(err.response.data));

               }
           })
   }
}
