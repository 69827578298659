import {CHANGE_ADMIN_PASS_LOADING , CHANGE_ADMIN_PASS_SUCCESS , CHANGE_ADMIN_PASS_FAILURE, CLEAN_PROFILE_DATA} from '../../Actions/Types';
import {CHANGE_ADMIN_PROFILE_PIC_LOADING , CHANGE_ADMIN_PROFILE_PIC_SUCCESS , CHANGE_ADMIN_PROFILE_PIC_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const AdminProfile = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            passData:[{}],
            passError:{},
            picData:[{}],
            picError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                loading:false,
                passData:[{}],
                passError:{},
                picData:[{}],
                picError:{},
            }
 
        case CLEAN_PROFILE_DATA:
            return {
                ...state,
                loading:false,
                passData:[{}],
                passError:{},
                picData:[{}],
                picError:{},
            }
 
        case CHANGE_ADMIN_PASS_LOADING:
            return {
                ...state,
                loading:true,
                passError: {},
                passData : [{}],
                
            }
        case CHANGE_ADMIN_PASS_SUCCESS:
            return {
                ...state,
                loading:false,
                passError:{},
                passData:action.data,
            }
        case CHANGE_ADMIN_PASS_FAILURE:
            return {
                ...state,
                loading:false,
                passError:action.error,
                passData:{},
            }   
        
        case CHANGE_ADMIN_PROFILE_PIC_LOADING:
            return {
                ...state,
                loading:true,
                picError: {},
                picData : [{}],
                
            }
        case CHANGE_ADMIN_PROFILE_PIC_SUCCESS:
            return {
                ...state,
                loading:false,
                picError:{},
                picData:action.data,
            }
        case CHANGE_ADMIN_PROFILE_PIC_FAILURE:
            return {
                ...state,
                loading:false,
                picError:action.error,
                picData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default AdminProfile;
    