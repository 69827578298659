import React ,  { useState , useEffect} from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, 
         Card, 
         Link, 
         Container, 
         Typography, 
         Stack, 
         Button, 
         Grid,         
         Avatar,
         CardActions,
         CardContent,
         Divider,         
         CardHeader,
         TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useSelector , useDispatch , shallowEqual } from 'react-redux';
         
import {tostS  , tostW , tostE } from '../../../config/Toast'
// components
import Page from '../../../components/Page';


import {ToastContainer} from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// API 

import {getCountryData, getStateData, getCityData} from '../../../Actions/AdminActions/LocationState'

import {addExamCenter} from '../../../Actions/AdminActions/Center'

import {cleanExamCenterData} from '../../../Actions/ActionCreator'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  
  
  display: 'flex',
  
  flexDirection: 'column',
  padding: theme.spacing(0, 5, 0, 5)
  
}));


const useStyles = makeStyles((theme) => ({
   root: {
     backgroundColor: theme.palette.background.dark,
     minHeight: '100%',
     paddingBottom: theme.spacing(3),
   //   paddingTop: theme.spacing(3)
   },
   root2: {},
   avatar: {
     height: 250,
     width: 250
   },
   formControl: {
     margin: theme.spacing(0),
     minWidth: '100%',
   },
   selectEmpty: {
     marginTop: theme.spacing(2),
   },
 
 }));



// ----------------------------------------------------------------------

const AddCenter = ({className, ...rest}) => {

   const classes = useStyles();
   const dispatch = useDispatch()
 
  const a_id = useSelector(state=> state.login.data.admin_id);

   const [values, setValues] = useState({          
     centre_name:"",      
      spoc_name : "",
      spoc_mobile : "",
      spoc_email : "",
      spoc_address:"",
      country_id:"",      
      state_id : "",
      city_id : "",
      pincode : "",  
      remark:"",    
      admin_id:a_id
   });
 
 
   const handleChange = (event) => {
     setValues({
       ...values,
       [event.target.name]: event.target.value,
     });
   };
 
  
 
   const handleChangeCountry = (event) => {
     const country_id = event.target.value;
     setValues({
       ...values,
       ['country_id']: event.target.value,
       ['state_id']:"",
       ['city_id']:""
     });

     dispatch(getStateData(country_id))
   };
 
  
 
   const handleChangeState = (event) => {
     const state_id = event.target.value;
     setValues({
       ...values,
       ['state_id']: event.target.value,
       ['city_id']:""
     });

     dispatch(getCityData(state_id))
   };
 
  
 
   const handleChangeCity = (event) => {
     setValues({
       ...values,
       ['city_id']: event.target.value
     });
   };
 
  
 
   const handleSubmit = () =>{
      if(values.centre_name==="" ||values.centre_name===null || values.centre_name===undefined){
        tostW("Please Enter Center Name!!")
      }else if(values.spoc_name==="" ||values.spoc_name===null || values.spoc_name===undefined){
        tostW("Please Enter Spoc Person Name!!")
      }else if(values.spoc_mobile==="" ||values.spoc_mobile===null || values.spoc_mobile===undefined){
        tostW("Please Enter Spoc Person Mobile!!")
      }else if(values.spoc_email==="" ||values.spoc_email===null || values.spoc_email===undefined){
        tostW("Please Enter Spoc Person Email!!")
      }else if(values.spoc_address==="" ||values.spoc_address===null || values.spoc_address===undefined){
        tostW("Please Enter Address!!")
      }else if(values.country_id==="" ||values.country_id===null || values.country_id===undefined){
        tostW("Please Select Country!!")
      }else if(values.state_id==="" ||values.state_id===null || values.state_id===undefined){
        tostW("Please Select State!!")
      }else if(values.city_id==="" ||values.city_id===null || values.city_id===undefined){
        tostW("Please Select City!!")
      }else if(values.pincode==="" ||values.pincode===null || values.pincode===undefined){
        tostW("Please Select Pincode!!")
      }else{
        dispatch(addExamCenter(values))
        setValues({
           ...values,
           spoc_name : "",
           spoc_mobile : "",
           spoc_email : "",
           spoc_address:"",
           centre_name:"",      
           country_id:"",      
           state_id : "",
           city_id : "",
           pincode : "",
           remark:""
        });

      }

 
   }
 
  const countryData = useSelector((state) => state.LocationState  && state.LocationState.countryData && Array.isArray(state.LocationState.countryData) && state.LocationState.countryData.length ? state.LocationState.countryData : null  )
  const countryErr = useSelector((state) => state.LocationState  && state.LocationState.countryError && state.LocationState.countryError.message ? state.LocationState.countryError.message : null  )
   
   
  const stateData = useSelector((state) => state.LocationState  && state.LocationState.stateData && Array.isArray(state.LocationState.stateData) && state.LocationState.stateData.length ? state.LocationState.stateData : null  )
  const stateErr = useSelector((state) => state.LocationState  && state.LocationState.stateError && state.LocationState.stateError.message ? state.LocationState.stateError.message : null  )
   
   
  const cityData = useSelector((state) => state.LocationState  && state.LocationState.cityData && Array.isArray(state.LocationState.cityData) && state.LocationState.cityData.length ? state.LocationState.cityData : null  )
  const cityErr = useSelector((state) => state.LocationState  && state.LocationState.cityError && state.LocationState.cityError.message ? state.LocationState.cityError.message : null  )
   
   
  const addmsg = useSelector((state) => state.ExamCenter  && state.ExamCenter.data && state.ExamCenter.data.status && state.ExamCenter.data.status.message ? state.ExamCenter.data.status.message : null  )
  const adderror = useSelector((state) => state.ExamCenter  && state.ExamCenter.error && state.ExamCenter.error.message ? state.ExamCenter.error.message : null  )
   
   
useEffect( () => {

  dispatch(getCountryData())

  let x  = new Promise((resolve ,reject)=>{
    if(addmsg !==null && typeof(addmsg) === 'string')
    {
      tostS(addmsg)
    }
    if(adderror !==null && typeof(adderror) === 'string')
    {
      tostE(adderror)
    }

    resolve()

  })
  x.then(()=>{

 dispatch(cleanExamCenterData())


  })


  
  }, [addmsg , adderror])
  
  
  return (
    <RootStyle title="Add Center">    
      <ToastContainer    />      
      <Container maxWidth = "lg">
         
        <ContentStyle>
        <Grid container spacing={3}>
        
        <Grid item>
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
      <Card className='cardBorder'>
        <CardHeader subheader="" title="Add Center Form" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Centre Name"
                name="centre_name"
                onChange={handleChange}
                required
                value={values.centre_name}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Spoc Name"
                name="spoc_name"
                onChange={handleChange}
                required
                value={values.spoc_name}
                variant="outlined"
              />
            </Grid>
            
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Spoc Mobile"
                name="spoc_mobile"
                onChange={handleChange}
                required
                value={values.spoc_mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Spoc Email"
                name="spoc_email"
                onChange={handleChange}
                required
                value={values.spoc_email}
                variant="outlined"
              />
            </Grid>  
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="spoc_address"
                onChange={handleChange}
                required
                value={values.spoc_address}
                variant="outlined"
              />
            </Grid>     
            <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Country</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.country_id}
                label="Country"
                name="country_id"
                onChange={handleChangeCountry}
              >
                {countryData.map((data , i)=>
                  <MenuItem key={i} value={data.c_id}>{data.name}</MenuItem>
                )}        

              </Select>
            </FormControl>
            </Grid>                                                     
            <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.state_id}
                label="State"
                name="state_id"
                onChange={handleChangeState}
              >
                {stateData.map((data , i)=>
                  <MenuItem key={i} value={data.s_id}>{data.name}</MenuItem>
                )}   
              </Select>
            </FormControl>
            </Grid>                                                     
            <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.city_id}
                label="City"
                name="city_id"
                onChange={handleChangeCity}
              >
                {cityData.map((data , i)=>
                  <MenuItem key={i} value={data.cit_id}>{data.name}</MenuItem>
                )}
              </Select>
            </FormControl>
            </Grid>                                                     
                       
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Pin Code"
                name="pincode"
                onChange={handleChange}
                required
                value={values.pincode}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField 
                rows={4} 
                fullWidth 
                multiline 
                label="Remark" 
                name="remark"
                value={values.remark}
                onChange={handleChange}
                />

            </Grid>            
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}


export default AddCenter;