import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {verifyDataBegin , verifyDataSuccess , verifyDataFailure} from '../ActionCreator';
import {getVerifiedDataBegin , getVerifiedDataSuccess , getVerifiedDataFailure} from '../ActionCreator';

export const verifyData = (data) => {

    return (dispatch) => {
        dispatch(verifyDataBegin());

        axios.post(Base_URL + '/admin/updateVerifyStatus', data)
            .then((res) => {
                dispatch(verifyDataSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(verifyDataFailure(err.response.data));

                }
            })
    }
}


export const getVerifiedData = (x,y) => {

    return (dispatch) => {
        dispatch(getVerifiedDataBegin());

        axios.get(Base_URL + '/admin/getBackupStatus', {params:{exam_id:x, slot_id:y}})
            .then((res) => {
                dispatch(getVerifiedDataSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getVerifiedDataFailure(err.response.data));

                }
            })
    }
}

