import React, {useEffect, useState} from 'react';

import {exportToExcel} from 'src/config/exportData'

// material
import { 
  Box, 
  Grid, 
  Stack,
  TextField,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  MenuItem,
  Select,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  CardContent
} from '@material-ui/core';
// components
import Page from 'src/components/Page';
import DataTable from './DataTable';

import { useSelector , useDispatch  } from 'react-redux';
import { Icon } from '@iconify/react';

// material
import {useTheme } from '@material-ui/core/styles';



// // API
import {getMasterBackup} from 'src/Actions/AdminActions/PostExam';
import {getExam} from 'src/Actions/AdminActions/Exam';
import { getExamCenter} from 'src/Actions/AdminActions/Center'


export default function DashboardApp() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const primary_id = useSelector(state=>state.login.data.exam_id);
  
  const ExamData = useSelector(state => state.Exam.getData && 
    Array.isArray(state.Exam.getData) && 
    state.Exam.getData.length ? (state.Exam.getData) : []);

  const centerData = useSelector(state => state.ExamCenter.getData && 
      Array.isArray(state.ExamCenter.getData) && 
      state.ExamCenter.getData.length ? state.ExamCenter.getData : [])
  
  const [values, setValues] = useState({
    exam_id:primary_id,  
    center_code :""      
   })

  const [finalData, setFinalData] = useState([])

   const handleChangeExam = (event) => {
    const e_id = event.target.value;

    setValues({
        ...values,
        ['exam_id']:e_id,
        ['center_code']:""
    })
    dispatch(getMasterBackup(e_id))
    dispatch(getExamCenter(e_id))
}
  


  useEffect(() =>{
    dispatch(getExam())    
    dispatch(getMasterBackup(values.exam_id))
    dispatch(getExamCenter(values.exam_id))
    
    

  }, [])


    
  var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
    };


  const data = useSelector(state =>  state.PostExam && state.PostExam.getData && 
    Array.isArray(state.PostExam.getData) && state.PostExam.getData.length ?
    groupBy(state.PostExam.getData, 'centre_code') : []);


  const handleChangeCenter = (event) =>{
    const c_code = event.target.value;
    setValues({
      ...values,
      ['center_code']:c_code
    })

    setFinalData(Array.isArray(data[c_code]) && data[c_code].length ? data[c_code] : [])

  }

// const exportField = [
//   {
//     field : "slot_name" , 
//     val : "Slot"
//   },    
//   {
//     field : "biometric" , 
//     val : "Biometric"
//   },    
//   {
//     field : "att_sheet" , 
//     val : "Attendance Sheet"
//   }, 
//   {
//     field : "primary_bkp" , 
//     val : "Primary Backup"
//   }, 
//   {
//     field : "secondary_bkp" , 
//     val : "Secondary Backup"
//   },
//   {
//     field : "pxe" , 
//     val : "Pxe Backup"
//   },
//   {
//     field : "rsp_sheet" , 
//     val : "Response Sheet"
//   },
//   {
//     field : "cat_pri" , 
//     val : "Catalina Primary"
//   },
//   {
//     field : "cat_sec" , 
//     val : "Catalina Secondary"
//   },
//   {
//     field : "rej_summary" , 
//     val : "Rejection summary"
//   }
// ]
  




  // const handleExports = () =>{
  //   console.log("aaa")
  //   var exportData = []

  //   if(Array.isArray(finalData) && finalData.length){
  //     finalData.map((element,i) => {
  //       exportData.push(
  //         {
  //           "slot_name":element.slot_name,
  //           "biometric":element.biometric[0] ? 'Yes':'No',
  //           "att_sheet":element.att_sheet[0] ? 'Yes':'No',
  //           "primary_bkp":element.primary_bkp[0] ? 'Yes':'No',
  //           "secondary_bkp":element.secondary_bkp[0] ? 'Yes':'No',
  //           "pxe":element.pxe[0] ? 'Yes':'No',
  //           "rsp_sheet":element.rsp_sheet[0] ? 'Yes':'No',
  //           "cat_pri":element.cat_pri[0] ? 'Yes':'No',
  //           "cat_sec":element.cat_sec[0] ? 'Yes':'No',
  //           "rej_summary":element.rej_summary[0] ? 'Yes':'No'
  //         }
  //       )
  //     })
  //   }

  //   console.log(exportData)
  //   exportToExcel(exportField,exportData)
  // }

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl"> 
        <Stack sx={{ pb: 3}}>
          <Typography variant="h4" gutterBottom>
            Center Data Status
          </Typography>
          
        </Stack>
        <Stack flexDirection='row' alignItems='center' justifyContent='space-between' sx={{pb:4}}>        
          <Grid item md={6} sm={12}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Select Exam</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={values.exam_id}
                    label="Country"
                    name="exam_id"
                    onChange={handleChangeExam}
                >
                    {ExamData.map((data , i)=>
                    <MenuItem key={i} value={data.exam_id}>{data.exam_name}</MenuItem>
                    )}        

                </Select>
              </FormControl>
          </Grid> 
        </Stack>         
        <Grid container spacing={3}>
                    
          <Grid item xs={12} md={12} lg={12}>
            <Card className='dashboardAdminUploadedDataCard'>
              <CardHeader title="Data Status" subheader="Select Center To View Data" />  
              <CardContent>
                <Grid container spacing={3}>                                          
                  <Grid item md={6} sm={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Select Center</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={values.center_id}
                            label="Select Center"
                            name="center_id"
                            onChange={handleChangeCenter}
                        >
                            {centerData.map((data , i)=>
                            <MenuItem key={i} value={data.centre_code}>{data.centre_code} {data.centre_name}</MenuItem>
                            )}        

                        </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <DataTable data={finalData}/>

              </CardContent>
            </Card>
          </Grid>            
        </Grid>

      </Container>
    </Page>
  );
}
