import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
{/*
<svg> 
  <defs>

    <style>
      .cls-1{fill:url(#linear-gradient);}
      .cls-2{fill:url(#linear-gradient-2);}
      .cls-3{
        font-size:17px;fill:#dcdbdb;
        font-family:Poppins-Light, Poppins;font-weight:300;
      }
      .cls-4{font-size:29px;fill:#7ac252;font-family:Poppins-Bold, Poppins;font-weight:700;}

    </style> 

    <linearGradient id="linear-gradient" x1="27.9" y1="63.7" x2="34.43" y2="68.7" gradientUnits="userSpaceOnUse">
      <stop offset="0.31" stop-color="#f1f2f2"/><stop offset="1" stop-color="#bcbec0"/>
    </linearGradient>

    <linearGradient id="linear-gradient-2" x1="42.23" y1="83.81" x2="69.94" y2="83.81" xlink:href="#linear-gradient"/>
  </defs>

  <path class="cls-1" d="M61.71,68c-5.4-1.81-9.2.39-12.65,3.84-2.51,2.52-5,5-7.73,7.34-6,5.08-12,10.13-18.16,15-6,4.74-12.95,3.3-15.4-3.33A10.6,10.6,0,0,1,8,83.56c7.07-16.06,14.44-32,21.73-47.95,1.7-3.73,4.62-5.95,8.73-5.93s7.11,2.31,8.84,6.17C51.68,45.59,56.12,55.31,60.53,65,60.91,65.88,61.22,66.74,61.71,68Zm-12.63-8.2a9.91,9.91,0,0,0-.33-1.53c-2.39-5.3-4.65-10.66-7.25-15.85-1.39-2.78-5-2.5-6.38.44C29.46,55.1,23.91,67.43,18.32,79.75a3.43,3.43,0,0,0,1,4.44c1.63,1.31,3.1.55,4.42-.63,7.89-7.06,15.78-14.11,23.62-21.23A11.85,11.85,0,0,0,49.08,59.75Z" transform="translate(-7.24 -29.68)"/>
  <path class="cls-2" d="M55.93,73.19l6.71-2.61C65,75.73,67.68,80.7,69.55,86c1.17,3.29-.38,6.56-3.09,8.84a8.5,8.5,0,0,1-9.24,1.5,18.46,18.46,0,0,1-4.78-3.06c-3.22-2.88-6.27-6-9.37-9-2.23-2.18.58-3.41,1.23-4.84.56-1.23,2.28-1.56,3.71-.14,1.66,1.64,3.52,3.09,5.32,4.59,1.31,1.09,2.77,1.59,4.25.39a3.59,3.59,0,0,0,1.16-4.44C57.77,77.44,56.73,75.07,55.93,73.19Z" transform="translate(-7.24 -29.68)"/>


  <text class="cls-3" transform="translate(79.48 36.26)">Netcom</text><text class="cls-4" transform="translate(76.02 62.21)">Analytics</text>

<svg>
*/}
  return (
    <Box sx={{ width: 150, height: 40, ...sx }}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.57 79.78">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M61.71,68c-5.4-1.81-9.2.39-12.65,3.84-2.51,2.52-5,5-7.73,7.34-6,5.08-12,10.13-18.16,15-6,4.74-12.95,3.3-15.4-3.33A10.6,10.6,0,0,1,8,83.56c7.07-16.06,14.44-32,21.73-47.95,1.7-3.73,4.62-5.95,8.73-5.93s7.11,2.31,8.84,6.17C51.68,45.59,56.12,55.31,60.53,65,60.91,65.88,61.22,66.74,61.71,68Zm-12.63-8.2a9.91,9.91,0,0,0-.33-1.53c-2.39-5.3-4.65-10.66-7.25-15.85-1.39-2.78-5-2.5-6.38.44C29.46,55.1,23.91,67.43,18.32,79.75a3.43,3.43,0,0,0,1,4.44c1.63,1.31,3.1.55,4.42-.63,7.89-7.06,15.78-14.11,23.62-21.23A11.85,11.85,0,0,0,49.08,59.75Z" transform="translate(-7.24 -29.68)"
          />
          <path
            fill="url(#BG2)"
            d="M55.93,73.19l6.71-2.61C65,75.73,67.68,80.7,69.55,86c1.17,3.29-.38,6.56-3.09,8.84a8.5,8.5,0,0,1-9.24,1.5,18.46,18.46,0,0,1-4.78-3.06c-3.22-2.88-6.27-6-9.37-9-2.23-2.18.58-3.41,1.23-4.84.56-1.23,2.28-1.56,3.71-.14,1.66,1.64,3.52,3.09,5.32,4.59,1.31,1.09,2.77,1.59,4.25.39a3.59,3.59,0,0,0,1.16-4.44C57.77,77.44,56.73,75.07,55.93,73.19Z" transform="translate(-7.24 -29.68)"
          />
          <text fill="url(#BG1)" transform="translate(79.48 36.26)">Netcom</text>
          <text fill="url(#BG3)" transform="translate(76.02 62.21)">Analytics</text>
        </g>
      </svg>
    </Box>
  );
}
