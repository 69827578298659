import React from 'react';

import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Container, Typography} from '@material-ui/core';

// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';

// material
import { LoadingButton } from '@material-ui/lab';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  padding:25,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(0, 0, 12, 2)
}));

const ImageSectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  height:"50%",
  maxWidth: 464,
  padding:25,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(12,0)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 512,
  margin: 'auto',
  display: 'flex',
  minHeight: '80vh',
  flexDirection: 'column',
  // justifyContent: 'center',
  padding: theme.spacing(4, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const navigate = useNavigate();

  const navigateToLogin = (nav) =>{
     navigate('/login/'+ nav)
  }

  return (
    <RootStyle title="Netcom Analytics">
      <MHidden width="mdDown">
        <ImageSectionStyle>          
          <Typography variant="h3" sx={{ p: 2}}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_register.png" style={{marginBottom:"25px"}} alt="login" />
        </ImageSectionStyle>
      </MHidden>
      <Container maxWidth="sm">
         <ContentStyle>
          <Stack flexDirection='row' justifyContent='flex-end'>
            <SectionStyle style={{height:100, elevation:25}}>
              <img src="/static/Cdac.svg" style={{marginBottom:"25px"}} alt="login" />
            </SectionStyle>
            <SectionStyle style={{height:100, elevation:25}}>
              <img src="/static/netparam.svg" style={{marginBottom:"25px"}} alt="login" />
            </SectionStyle>

          </Stack>
            <Stack sx={{ mb: 3 }}>
            <Typography variant="h3" gutterBottom>
               Are You ?
            </Typography>
            </Stack>
            <Stack flexDirection='row' >
              <SectionStyle style={{height:100, elevation:25}}>
               <LoadingButton sx={{elevation:12}} onClick={() => navigateToLogin('ci')} variant = 'contained'>Chief Invigilator</LoadingButton>
                
              </SectionStyle>
              <SectionStyle>
               <LoadingButton sx={{elevation:12}} onClick={() => navigateToLogin('admin')} variant = 'contained'>Admin</LoadingButton>

              </SectionStyle>

            </Stack>
         </ContentStyle>
      </Container>
    </RootStyle>
  );
}
