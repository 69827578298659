import React ,  {useRef , useState , useEffect} from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, 
         Card, 
         Container, 
         Button, 
         Grid,         
         CardContent,
         Divider,         
         CardHeader,
         TextField,
         InputAdornment,
         IconButton,

      } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useSelector , useDispatch , shallowEqual } from 'react-redux';
         
import {tostS  , tostW , tostE } from '../../../config/Toast'
// components
import Page from '../../../components/Page';

// Icons
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';


import {ToastContainer} from 'react-toastify';

// API

import { addCi } from 'src/Actions/AdminActions/Ci';



// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  
  
  display: 'flex',
  
  flexDirection: 'column',
  padding: theme.spacing(0, 5, 0, 5)
  
}));


const useStyles = makeStyles((theme) => ({
   root: {
     backgroundColor: theme.palette.background.dark,
     minHeight: '100%',
     paddingBottom: theme.spacing(3),
   //   paddingTop: theme.spacing(3)
   },
   root2: {},
   avatar: {
     height: 250,
     width: 250
   },
   formControl: {
     margin: theme.spacing(0),
     minWidth: '100%',
   },
   selectEmpty: {
     marginTop: theme.spacing(2),
   },
 
 }));



// ----------------------------------------------------------------------

const AddCi = ({className, ...rest}) => {

   const {state} = useLocation();
   const classes = useStyles();
   const dispatch = useDispatch()
 
   const [values, setValues] = useState({    
      name:"",
      mobile:"",
      password:"",
      confirm_password:"",
      email:"",
      role:"CI",
      address:""
   });
 
   const [showPass, setShowPass] = useState(false)
   const [showCPass, setCShowPass] = useState(false)
 
   const handleShowPass = () =>{
      setShowPass(!showPass)
   }
   const handleCShowPass = () =>{
      setCShowPass(!showCPass)
   }

   const handleChange = (event) => {
     setValues({
       ...values,
       [event.target.name]: event.target.value,
     });
   };
 
  
 
   const handleSubmit = () =>{

       if(values.name==="" || values.name===null || values.name===undefined){
          tostE("Please Enter Name");
       }else if(values.mobile==="" || values.mobile===null || values.mobile===undefined){
         tostE("Please Enter Mobile Number");
      }else if(values.email==="" || values.email===null || values.email===undefined){
         tostE("Please Enter Email");
      }else if(values.address==="" || values.address===null || values.address===undefined){
         tostE("Please Enter Address");
      }else if(values.password==="" || values.password===null || values.password===undefined){
         tostE("Please Enter Password");
      }else if(values.confirm_password==="" || values.confirm_password===null || values.confirm_password===undefined){
         tostE("Please Enter Confirm Password");
      }else if(values.password!== values.confirm_password){
         tostE("Please Enter Same Password");
      }else{
       console.log(values)

           dispatch(addCi(values))
         setValues({
             ...values,
             name:"",
             mobile:"",
             password:"",
             confirm_password:"",
             email:"",
             address:""
          
         });

      }
 
   }
 
  const addmsg = useSelector((state) => state.CiDetail  && state.CiDetail.data && state.CiDetail.data.status && state.CiDetail.data.status.message ? state.CiDetail.data.status.message : null  )
  const adderror = useSelector((state) => state.CiDetail  && state.CiDetail.error && state.CiDetail.error.message ? state.CiDetail.error.message : null  )
   
   
     useEffect( () => {
   
       let x  = new Promise((resolve ,reject)=>{
         if(addmsg !==null && typeof(addmsg) === 'string')
         {
           tostS(addmsg)
         }
         if(adderror !==null && typeof(adderror) === 'string')
         {
           tostE(adderror)
         }
   
         resolve()
   
       })
       x.then(()=>{
   
      //  dispatch(cleanBusinessData())
      
   
       })
   
     
        
       }, [addmsg , adderror])
  
  
  return (
    <RootStyle title="Add CI">    
      <ToastContainer    />      
      <Container maxWidth = "lg">
         
        <ContentStyle>
        <Grid container spacing={3}>
        
        <Grid item>
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
      <Card className='cardBorder'>
        <CardHeader subheader="" title="Add CI Form" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Name"
                required
                name="name"
                onChange={handleChange}
                required
                value={values.name}
                variant="outlined"
              />
            </Grid>
            
            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Mobile"
                required                
                name="mobile"
                type="number"
                onChange={handleChange}
                required
                value={values.mobile}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                required
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>                                                              
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                required
                onChange={handleChange}
                required
                value={values.address}
                variant="outlined"
              />
            </Grid>   
            <Grid item md={6} xs={12}>
               <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPass ? 'text' : 'password'}
                  label="Password"
                  required
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  
                  InputProps={{
                  endAdornment: (
                     <InputAdornment position="end">
                        <IconButton onClick={handleShowPass} edge="end">
                        <Icon icon={showPass ? eyeFill : eyeOffFill} />
                        </IconButton>
                     </InputAdornment>
                  )
                  }}
               />

            </Grid> 
            <Grid item md={6} xs={12}>
               <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showCPass ? 'text' : 'password'}
                  label="Confirm Password"
                  name="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  required
                  InputProps={{
                  endAdornment: (
                     <InputAdornment position="end">
                        <IconButton onClick={handleCShowPass} edge="end">
                        <Icon icon={showCPass ? eyeFill : eyeOffFill} />
                        </IconButton>
                     </InputAdornment>
                  )
                  }}
               />

            </Grid>         
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            
          </Grid>
        </Grid>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}


export default AddCi;