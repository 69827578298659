import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import { getUploadedDataBegin, getUploadedDataSuccess, getUploadedDataFailure } from "../ActionCreator";
import { reqUploadDataBegin, reqUploadDataSuccess, reqUploadDataFailure } from "../ActionCreator";



export const reqUploadData = (data) => {

   return (dispatch) => {
       dispatch(reqUploadDataBegin());

       axios.post(Base_URL + '/admin/reqToReupload' , data)
           .then((res) => {
               dispatch(reqUploadDataSuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   console.log("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(reqUploadDataFailure(err.response.data));

               }
           })
   }
}

export const getUploadedData = (x,y) => {

   return (dispatch) => {
       dispatch(getUploadedDataBegin());

       axios.get(Base_URL + '/admin/getUploadedData' , {params:{ec_id:x, content_type:y}})
           .then((res) => {
               dispatch(getUploadedDataSuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   console.log("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(getUploadedDataFailure(err.response.data));

               }
           })
   }
}
