import React, {useEffect, useState} from 'react';
import { filter, reverse } from 'lodash';
import {useNavigate} from 'react-router-dom'
import moment from 'moment';

// material
import { 
  Box, 
  Grid, 
  Stack,
  TextField,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Checkbox,
  Tooltip,
  Breadcrumbs,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Select,
  Button,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DatePicker from '@material-ui/lab/DatePicker';

// Icons
import { Icon } from '@iconify/react';

import moreVerticalFill from '@iconify/icons-eva/layers-fill';
import branchIcon from '@iconify/icons-ant-design/deployment-unit-outline';

import plusFill from '@iconify/icons-eva/plus-fill';
// components
import Page from '../../../components/Page';
import { UserListHead, UserListToolbar } from '../../../components/_dashboard/user';
import {tostS   , tostE, tostW } from '../../../config/Toast'
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { useSelector , useDispatch  } from 'react-redux';


// API
import {getExam} from 'src/Actions/AdminActions/Exam'

import {approveUploadRequest, getUploadRequest} from 'src/Actions/AdminActions/ReuploadRequest'

import {cleanUploadRequestData} from 'src/Actions/ActionCreator'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'centre_code', label: 'Center Code', alignRight: false },
  { id: 'slot_name', label: 'Slot Name', alignRight: false },
  { id: 'ci_name', label: 'Requested By', alignRight: false },
  { id: 'file_name', label: 'File Name', alignRight: false },
  { id: 'upload_count', label:"File Upload Count"  },
  { id: 'action', label:"Approve Request"  }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.ci_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function ExamScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('exam_name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const primary_id = useSelector(state=>state.login.data.exam_id);
  

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const listData = useSelector(state => state.UploadRequest && state.UploadRequest.getData &&
    Array.isArray(state.UploadRequest.getData) && state.UploadRequest.getData.length ? 
    state.UploadRequest.getData : []);

  const ExamData = useSelector(state => state.Exam.getData && 
    Array.isArray(state.Exam.getData) && 
    state.Exam.getData.length ? (state.Exam.getData) : []);


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const filteredUsers = applySortFilter(listData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const [values, setValues] = useState({
    exam_id:primary_id,
    show:false,
    up_id:""
})

  const handleChangeExam = (event) => {
    const e_id = event.target.value;

    setValues({
        ...values,
        ['exam_id']:e_id
    })
    dispatch(getUploadRequest(e_id))
  }

  

    const uploadMsg = useSelector(state => state.UploadRequest && state.UploadRequest.data && state.UploadRequest.data.status && state.UploadRequest.data.status.message ? state.UploadRequest.data.status.message : null )
    const uploadErr = useSelector(state => state.UploadRequest && state.UploadRequest.error && state.UploadRequest.error.message ? state.UploadRequest.error.message : null )
 


    useEffect(() =>{
        dispatch(getExam())
        dispatch(getUploadRequest(primary_id))        
        let x  = new Promise((resolve ,reject)=>{
            if(uploadMsg !==null && typeof(uploadMsg) === 'string')
            {
              tostS(uploadMsg)
            }
            if(uploadErr !==null && typeof(uploadErr) === 'string')
            {
              tostE(uploadErr)
            }
            
      
            resolve()
      
          })
          x.then(()=>{
          dispatch(cleanUploadRequestData())
         
      
          })
      }, [uploadMsg, uploadErr, primary_id])
    


      
      const handleOpen = (data) => {
        setValues({
          ...values,
          ['show']:true,
          ['up_id']:data.up_id
        });
      } 
    
      const handleClickSubmit = () => {
          console.log(values)
        dispatch(approveUploadRequest({"up_id" : values.up_id}))
        setValues({
          ...values,
          ['show']:false,
          ['up_id']:""
        });
      };
    
      const handleClose = () => {
        setValues({
          ...values,
          ['show']:false,
          ['up_id']:""
        });
      };


  return (
    <Page title="Re-Upload Requests">
        <Dialog open={values.show} onClose={handleClose}>
            <DialogTitle>Approve Re-Upload Request</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Are you sure You want to Approve This Re-Upload Request?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClickSubmit} autoFocus>
                Agree
            </Button>
            </DialogActions>
        </Dialog>
        <Container maxWidth="xl">
        <Stack sx={{ pb: 3, pt:3 }}>
          <Typography variant="h4" gutterBottom>
            Re-upload Request List
          </Typography>
          
        </Stack>
          <Grid container spacing={3}>
            <Grid item md={6} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Exam</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.exam_id}
                        label="Country"
                        name="exam_id"
                        onChange={handleChangeExam}
                    >
                        {ExamData.map((data , i)=>
                        <MenuItem key={i} value={data.exam_id}>{data.exam_name}</MenuItem>
                        )}        

                    </Select>
                </FormControl>
            </Grid>
          </Grid>
          <Card className='cardBorder'>
            <CardHeader title="Pending Re-Upload Requests" subheader = "Select Exam to View Pending Requests" />
            <UserListToolbar              
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listData.length}                    
                    onRequestSort={handleRequestSort}                                        
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const {up_id, centre_code, slot_name , ci_name , upload_count , file_name } = row;
                        const isItemSelected = selected.indexOf(up_id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={up_id}
                            tabIndex={-1}
                            className="tableRowBottomBorder"
                            
                          >                                                        
                            <TableCell align="left">{centre_code}</TableCell>
                            <TableCell align="left">{slot_name}</TableCell>
                            <TableCell align="left">{ci_name}</TableCell>                          
                            <TableCell align="left">{file_name}</TableCell>
                            <TableCell align="left">{upload_count}</TableCell>
                            <TableCell>
                               <Button variant="outlined" onClick={() => handleOpen(row)}>Approve Request</Button>
                                
                            </TableCell>
                            
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      
        
        
    </Page>
  );
}
