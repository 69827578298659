import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {approveUploadRequestBegin , approveUploadRequestSuccess , approveUploadRequestFailure} from '../ActionCreator';
import {getUploadRequestBegin , getUploadRequestSuccess , getUploadRequestFailure} from '../ActionCreator';

export const approveUploadRequest = (data) => {

    return (dispatch) => {
        dispatch(approveUploadRequestBegin());

        axios.post(Base_URL + '/admin/approveReupload', data)
            .then((res) => {
                dispatch(approveUploadRequestSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(approveUploadRequestFailure(err.response.data));

                }
            })
    }
}


export const getUploadRequest = (x) => {

    return (dispatch) => {
        dispatch(getUploadRequestBegin());

        axios.get(Base_URL + '/admin/getAllPendingRequest', {params:{exam_id:x}})
            .then((res) => {
                dispatch(getUploadRequestSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getUploadRequestFailure(err.response.data));

                }
            })
    }
}

