import {ADD_EXAM_CENTER_LOADING , ADD_EXAM_CENTER_SUCCESS , ADD_EXAM_CENTER_FAILURE , CLEAN_EXAM_CENTER_DATA} from '../../Actions/Types';
import {ALLOCATE_EXAM_CENTER_LOADING , ALLOCATE_EXAM_CENTER_SUCCESS , ALLOCATE_EXAM_CENTER_FAILURE} from '../../Actions/Types';
import {GET_EXAM_CENTER_LOADING , GET_EXAM_CENTER_SUCCESS , GET_EXAM_CENTER_FAILURE} from '../../Actions/Types';
import {GET_ALL_CENTER_LOADING , GET_ALL_CENTER_SUCCESS , GET_ALL_CENTER_FAILURE} from '../../Actions/Types';
import {GET_AVL_CENTER_LOADING , GET_AVL_CENTER_SUCCESS , GET_AVL_CENTER_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const ExamCenter = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            allocateData: [{}],
            allocateError:{},
            getData:[{}],
            getError:{},
            allData:[{}],
            allError:{},
            avlData:[{}],
            avlError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                loading:false,
                data: [{}],
                error:{},
                allocateData: [{}],
                allocateError:{},
                getData:[{}],
                getError:{},
                allData:[{}],
                allError:{},
                avlData:[{}],
                avlError:{},
            }
    
    
        case CLEAN_EXAM_CENTER_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                allocateData: [{}],
                allocateError:{},
            }
    
        
        case ADD_EXAM_CENTER_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                
            }
        case ADD_EXAM_CENTER_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,                
            }
        case ADD_EXAM_CENTER_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],                
            }

    
        
        case ALLOCATE_EXAM_CENTER_LOADING:
            return {
                ...state,
                loading:true,
                allocateError: {},
                allocateData : [{}],               
                
            }
        case ALLOCATE_EXAM_CENTER_SUCCESS:
            return {
                ...state,
                loading:false,
                allocateError:{},
                allocateData:action.data,                
            }
        case ALLOCATE_EXAM_CENTER_FAILURE:
            return {
                ...state,
                loading:false,
                allocateError:action.error,
                allocateData:[{}],                
            }

        case GET_EXAM_CENTER_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_EXAM_CENTER_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GET_EXAM_CENTER_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        
        case GET_ALL_CENTER_LOADING:
            return {
                ...state,
                loading:true,
                allError: {},
                allData : [{}],
                
            }
        case GET_ALL_CENTER_SUCCESS:
            return {
                ...state,
                loading:false,
                allError:{},
                allData:action.data,
            }
        case GET_ALL_CENTER_FAILURE:
            return {
                ...state,
                loading:false,
                allError:action.error,
                allData:{},
            }   
        
        case GET_AVL_CENTER_LOADING:
            return {
                ...state,
                loading:true,
                avlError: {},
                avlData : [{}],
                
            }
        case GET_AVL_CENTER_SUCCESS:
            return {
                ...state,
                loading:false,
                avlError:{},
                avlData:action.data,
            }
        case GET_AVL_CENTER_FAILURE:
            return {
                ...state,
                loading:false,
                avlError:action.error,
                avlData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default ExamCenter;
    