import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {addExamBegin , addExamSuccess , addExamFailure} from '../ActionCreator';
import {getExamBegin , getExamSuccess , getExamFailure} from '../ActionCreator';
import {setExamPrimaryBegin , setExamPrimarySuccess , setExamPrimaryFailure} from '../ActionCreator';

export const addExam = (data) => {

    return (dispatch) => {
        dispatch(addExamBegin());

        axios.post(Base_URL + '/admin/addexamprofile', data)
            .then((res) => {
                dispatch(addExamSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addExamFailure(err.response.data));

                }
            })
    }
}


export const getExam = () => {

    return (dispatch) => {
        dispatch(getExamBegin());

        axios.get(Base_URL + '/admin/getexamprofile')
            .then((res) => {
                dispatch(getExamSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getExamFailure(err.response.data));

                }
            })
    }
}



export const setExamPrimary = (x) => {

    return (dispatch) => {
        dispatch(setExamPrimaryBegin());

        axios.get(Base_URL + '/admin/setPrimary', {params:{exam_id:x}})
            .then((res) => {
                dispatch(setExamPrimarySuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(setExamPrimaryFailure(err.response.data));

                }
            })
    }
}

