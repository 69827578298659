// ----------------------------------------------------------------------

const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRadiusLg: 16
};

export default shape;
