import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {addExamCenterBegin , addExamCenterSuccess , addExamCenterFailure} from '../ActionCreator';
import {allocateExamCenterBegin , allocateExamCenterSuccess , allocateExamCenterFailure} from '../ActionCreator';
import {getExamCenterBegin , getExamCenterSuccess , getExamCenterFailure} from '../ActionCreator';
import {getAllCenterBegin , getAllCenterSuccess , getAllCenterFailure} from '../ActionCreator';
import {getAvlCenterBegin , getAvlCenterSuccess , getAvlCenterFailure} from '../ActionCreator';


export const addExamCenter = (data) => {

    return (dispatch) => {
        dispatch(addExamCenterBegin());

        axios.post(Base_URL + '/admin/addcentredetails', data)
            .then((res) => {
                dispatch(addExamCenterSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addExamCenterFailure(err.response.data));

                }
            })
    }
}


export const allocateExamCenter = (data) => {

    return (dispatch) => {
        dispatch(allocateExamCenterBegin());

        axios.post(Base_URL + '/admin/allocateexamcentre', data)
            .then((res) => {
                dispatch(allocateExamCenterSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(allocateExamCenterFailure(err.response.data));

                }
            })
    }
}

export const getExamCenter = (x) => {

    return (dispatch) => {
        dispatch(getExamCenterBegin());

        axios.get(Base_URL + '/admin/getcentres', {params:{exam_id:x}})
            .then((res) => {
                dispatch(getExamCenterSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getExamCenterFailure(err.response.data));

                }
            })
    }
}


export const getAllCenter = () => {

    return (dispatch) => {
        dispatch(getAllCenterBegin());

        axios.get(Base_URL + '/admin/getallexamcentres')
            .then((res) => {
                dispatch(getAllCenterSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAllCenterFailure(err.response.data));

                }
            })
    }
}


export const getAvlCenter = () => {

    return (dispatch) => {
        dispatch(getAvlCenterBegin());

        axios.get(Base_URL + '/admin/getavlexamcentres')
            .then((res) => {
                dispatch(getAvlCenterSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAvlCenterFailure(err.response.data));

                }
            })
    }
}

