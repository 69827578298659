import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import { uploadRejSummaryBegin, uploadRejSummarySuccess, uploadRejSummaryFailure, uploadRejSummaryProgress } from "../ActionCreator";
import { addRejSummaryBegin, addRejSummarySuccess, addRejSummaryFailure } from "../ActionCreator";



export const addRejSummary = (session) => {

   return (dispatch) => {
       dispatch(addRejSummaryBegin());

       axios.post(Base_URL + '/admin/fillrejection' , session)
           .then((res) => {
               dispatch(addRejSummarySuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   console.log("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(addRejSummaryFailure(err.response.data));

               }
           })
   }
}


export const uploadRejSummary = (session) => {

   
   var formData = new FormData()

   formData.append('slot_id',session.slot_id);
   formData.append('content_type',session.content_type);
   formData.append('ec_id',session.ec_id);
   formData.append('exam_id',session.exam_id);
   formData.append('ci_id',session.ci_id);    
   formData.append('img',session.image1 );
     

   return (dispatch) => {
       dispatch(uploadRejSummaryBegin());

       axios.post(Base_URL + '/admin/uploadRejectionSummary' , formData,{         
         onUploadProgress: data => {
           //Set the progress value to show the progress bar
           dispatch(uploadRejSummaryProgress(Math.round(((100 * data.loaded) / data.total)-1)));
         },
       })
           .then((res) => {
               dispatch(uploadRejSummarySuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   console.log("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(uploadRejSummaryFailure(err.response.data));

               }
           })
   }
}
