import {GET_ALL_CI_HISTORY_LOADING , GET_ALL_CI_HISTORY_SUCCESS , GET_ALL_CI_HISTORY_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const History = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            getData:[{}],
            getError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{}
                
            }
 
        case GET_ALL_CI_HISTORY_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_ALL_CI_HISTORY_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GET_ALL_CI_HISTORY_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default History;
    