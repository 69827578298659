import axios from "axios";
import {Base_URL} from '../config/BaseUrlConfig';

import {getDashboardBegin , getDashboardSuccess , getDashboardFailure} from './ActionCreator';

export const getDashboard = (x,y) => {

    return (dispatch) => {
        dispatch(getDashboardBegin());

        axios.get(Base_URL + '/admin/getDashboard', {params:{exam_id:x, slot_id:y}})
            .then((res) => {
                dispatch(getDashboardSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getDashboardFailure(err.response.data));

                }
            })
    }
}

