import {ADD_EXAM_LOADING , ADD_EXAM_SUCCESS , ADD_EXAM_FAILURE , CLEAN_EXAM_DATA} from '../../Actions/Types';
import {GET_EXAM_LOADING , GET_EXAM_SUCCESS , GET_EXAM_FAILURE} from '../../Actions/Types';
import {SET_EXAM_PRIMARY_LOADING , SET_EXAM_PRIMARY_SUCCESS , SET_EXAM_PRIMARY_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const Exam = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            setData:[{}],
            setError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{},
                setData:[{}],
                setError:{},
            }
    
    
        case CLEAN_EXAM_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                setData:[{}],
                setError:{},
            }
    
        
        case ADD_EXAM_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                
            }
        case ADD_EXAM_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,                
            }
        case ADD_EXAM_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],                
            }

        case GET_EXAM_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_EXAM_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GET_EXAM_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        
        case SET_EXAM_PRIMARY_LOADING:
            return {
                ...state,
                loading:true,
                setError: {},
                setData : [{}],
                
            }
        case SET_EXAM_PRIMARY_SUCCESS:
            return {
                ...state,
                loading:false,
                setError:{},
                setData:action.data,
            }
        case SET_EXAM_PRIMARY_FAILURE:
            return {
                ...state,
                loading:false,
                setError:action.error,
                setData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default Exam;
    