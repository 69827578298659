import {GET_COUNTRY_DATA_LOADING , GET_COUNTRY_DATA_SUCCESS , GET_COUNTRY_DATA_FAILURE} from '../Actions/Types';
import {GET_STATE_DATA_LOADING , GET_STATE_DATA_SUCCESS , GET_STATE_DATA_FAILURE} from '../Actions/Types';
import {GET_CITY_DATA_LOADING , GET_CITY_DATA_SUCCESS , GET_CITY_DATA_FAILURE} from '../Actions/Types';

const LocationState = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            countryData:[{}],
            countryError:{},
            stateData:[{}],
            stateError:{},
            cityData:[{}],
            cityError:{},
        }
    }

    switch(action.type) {
        
        case GET_COUNTRY_DATA_LOADING:
            return {
                ...state,
                loading:true,
                countryError: {},
                countryData : [{}],
                
            }
        case GET_COUNTRY_DATA_SUCCESS:
            return {
                ...state,
                loading:false,                
                countryData:action.data,
                countryError:{}
            }
        case GET_COUNTRY_DATA_FAILURE:
            return {
                ...state,
                loading:false,                
                countryData:{},
                countryError:action.error,
            }   
        
        case GET_STATE_DATA_LOADING:
            return {
                ...state,
                loading:true,
                stateError: {},
                stateData : [{}],
                
            }
        case GET_STATE_DATA_SUCCESS:
            return {
                ...state,
                loading:false,                
                stateData:action.data,
                stateError:{}
            }
        case GET_STATE_DATA_FAILURE:
            return {
                ...state,
                loading:false,                
                stateData:{},
                stateError:action.error,
            }   
        
        case GET_CITY_DATA_LOADING:
            return {
                ...state,
                loading:true,
                cityError: {},
                cityData : [{}],
                
            }
        case GET_CITY_DATA_SUCCESS:
            return {
                ...state,
                loading:false,                
                cityData:action.data,
                cityError:{}
            }
        case GET_CITY_DATA_FAILURE:
            return {
                ...state,
                loading:false,                
                cityData:{},
                cityError:action.error,
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default LocationState;
    