import {CI_BACKUP_STATUS_LOADING , CI_BACKUP_STATUS_SUCCESS , CI_BACKUP_STATUS_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const BackupStatus = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                
            }
    
        case CI_BACKUP_STATUS_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                
            }
        case CI_BACKUP_STATUS_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,                
            }
        case CI_BACKUP_STATUS_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],                
            }

        default:
        return state
    
    }
    }
    
    
    
export default BackupStatus;
    