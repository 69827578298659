import {VERIFY_DATA_LOADING , VERIFY_DATA_SUCCESS , VERIFY_DATA_FAILURE , CLEAN_VERIFIED_DATA} from '../../Actions/Types';
import {GET_VERIFIED_DATA_LOADING , GET_VERIFIED_DATA_SUCCESS , GET_VERIFIED_DATA_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const VerifyData = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[],
                getError:{}
                
            }
    
    
        case CLEAN_VERIFIED_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                
            }
    
        
        case VERIFY_DATA_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                
            }
        case VERIFY_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,                
            }
        case VERIFY_DATA_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],                
            }

        case GET_VERIFIED_DATA_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [],
                
            }
        case GET_VERIFIED_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GET_VERIFIED_DATA_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default VerifyData;
    