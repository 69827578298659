import {UPLOAD_RES_SHEET_LOADING , UPLOAD_RES_SHEET_SUCCESS , UPLOAD_RES_SHEET_FAILURE ,UPLOAD_RES_SHEET_PROGRESS, CLEAN_UPLOAD_RES_SHEET_DATA} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const ResponseSheet = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            progress:null
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                progress:null
                
            }
    
    
        case CLEAN_UPLOAD_RES_SHEET_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                progress:null
                
            }
    
        
        case UPLOAD_RES_SHEET_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                progress:null
                
            }
    
        
        case UPLOAD_RES_SHEET_PROGRESS:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                progress:action.progress
                
            }

        case UPLOAD_RES_SHEET_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,  
                progress:null

            }
        case UPLOAD_RES_SHEET_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],            
                progress:null

            }

        default:
        return state
    
    }
    }
    
    
    
export default ResponseSheet;
    