import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/Layout';

import DashboardApp from '../pages/AdminScreen/Dashboard/DashboardApp';
import ProfileScreen from 'src/pages/AdminScreen/UserProfile';

import ExamScreen from '../pages/AdminScreen/Exams/exam';

import VerifyDataScreen from '../pages/AdminScreen/VerifyData/verify';

import AddCenterScreen from '../pages/AdminScreen/Centers/Add';
import AllCenterScreen from '../pages/AdminScreen/Centers/allCenter';
import AllocateCenterScreen from '../pages/AdminScreen/Centers/allocateCenter';
import ExamCenterScreen from '../pages/AdminScreen/Centers/examCenter';

import ReUploadRequestList from '../pages/AdminScreen/ReuploadRequest/List';
import AllCiHistoryScreen from 'src/pages/AdminScreen/History/AllCiHistory';


import CenterCiDetailScreen from '../pages/AdminScreen/CiDetails/centerCi';

import AddCiScreen from '../pages/AdminScreen/CiDetails/AddCi';
import CiDetailScreen from '../pages/AdminScreen/CiDetails/AllCi';


import PostExamCenterDataScreen from '../pages/AdminScreen/PostExam/CenterData'; 
import PostExamExportScreen from '../pages/AdminScreen/PostExam/ExportReport'; 

import AddDownloadScreen from '../pages/AdminScreen/Download/AddLinks';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'profile', element: <ProfileScreen /> },
        { path: 'exam', element: <ExamScreen /> },
        
        { path: 'verify-data', element: <VerifyDataScreen /> },
        { path: 'add-link', element: <AddDownloadScreen /> },
        
        { path: 'center/add', element: <AddCenterScreen/> },
        { path: 'center/all-list', element: <AllCenterScreen/> },

        { path: 'center-allocate', element: <AllocateCenterScreen/> },
        { path: 'exam-center/:id', element: <ExamCenterScreen/> },
       
       
        { path: 'ci/add', element: <AddCiScreen/> },
        { path: 'ci/details', element: <CiDetailScreen/> },
        { path: 'center/ci/detail/:id', element: <CenterCiDetailScreen/> },
        
        { path: 'rejection-request/list', element: <ReUploadRequestList/> },
        { path: 'history/all-ci-history', element: <AllCiHistoryScreen/> },
        
        
        { path: 'post-exam/center-data', element: <PostExamCenterDataScreen/> },
        { path: 'post-exam/export-data', element: <PostExamExportScreen/> },
        
        { path: '*', element: <Navigate to="/dashboard" replace /> },
      ]
    },
    

    { path: '*', element: <Navigate to="/dashboard" replace /> }
  ]);
}
