import {UPLOAD_REJ_SUMMARY_LOADING , UPLOAD_REJ_SUMMARY_SUCCESS , UPLOAD_REJ_SUMMARY_FAILURE ,UPLOAD_REJ_SUMMARY_PROGRESS, CLEAN_UPLOAD_REJ_SUMMARY_DATA} from '../../Actions/Types';
import {ADD_REJ_SUMMARY_LOADING , ADD_REJ_SUMMARY_SUCCESS , ADD_REJ_SUMMARY_FAILURE,CLEAN_ADD_REJ_SUMMARY_DATA} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const RejectionSummary = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            progress:null,
            fillData:[{}],
            fillError:{}
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                progress:null,
                fillData:[{}],
                fillError:{}
            }
    
    
        case CLEAN_UPLOAD_REJ_SUMMARY_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                progress:null
                
            }
    
        case CLEAN_ADD_REJ_SUMMARY_DATA:
            return {
                ...state,
                fillData: [{}],
                loading:false,
                fillError:{},
                
            }
    
        
        case UPLOAD_REJ_SUMMARY_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                progress:null
                
            }
    
        
        case UPLOAD_REJ_SUMMARY_PROGRESS:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                progress:action.progress
                
            }

        case UPLOAD_REJ_SUMMARY_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,  
                progress:null

            }
        case UPLOAD_REJ_SUMMARY_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],            
                progress:null

            }
    
        
        case ADD_REJ_SUMMARY_LOADING:
            return {
                ...state,
                loading:true,
                fillData:[{}],
                fillError:{}
                
            }
                    
        case ADD_REJ_SUMMARY_SUCCESS:
            return {
                ...state,
                loading:false,
                fillData:action.data,
                fillError:{}

            }
        case ADD_REJ_SUMMARY_FAILURE:
            return {
                ...state,
                loading:false,
                fillData:[{}],
                fillError:action.error

            }

        default:
        return state
    
    }
    }
    
    
    
export default RejectionSummary;
    