import moment from 'moment';
// material
import { 
    Card,     
    CardHeader,
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableContainer
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';


// Component
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';

import {useSelector} from 'react-redux';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------


export default function LastActivity() {
    const theme = useTheme();

    const CenterData = useSelector(state=> state.Dashboard.getData && state.Dashboard.getData.last_history &&
        Array.isArray(state.Dashboard.getData.last_history) && state.Dashboard.getData.last_history.length ?
        state.Dashboard.getData.last_history : [])

  return (
    <Card className = 'dashBoardCardBorder'>
      <CardHeader title="Last Activity" sx={{ mb: 3 }} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 720 }}>
          <Table>
            <TableHead>
              <TableRow className='tableRowBottomBorder'>
                <TableCell>Activity</TableCell>
                <TableCell>Point in Time</TableCell>                
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {CenterData.map((row, i) => (
                <TableRow key={i} className='tableRowBottomBorder'>
                  <TableCell>{row.message}</TableCell>
                  <TableCell>
                  <Label
                      variant='ghost'
                      color="info"
                    >
                    {moment(moment(row.created_on).format("MMMM Do YYYY, h:mm:ss a"), "MMMM Do YYYY, h:mm:ss a").fromNow()}

                    </Label>
                  </TableCell>
                                    
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>      
    </Card>
  );
}
