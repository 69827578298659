import React, {useEffect, useState} from 'react';
import { filter, reverse } from 'lodash';
import {useNavigate} from 'react-router-dom'
import moment from 'moment';

// material
import { 
  Box, 
  Grid, 
  Stack,
  TextField,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Checkbox,
  Tooltip,
  Breadcrumbs,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Select,
  Button,
  FormControl,
  InputLabel,
  TableHead
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DatePicker from '@material-ui/lab/DatePicker';

// Icons
import { Icon } from '@iconify/react';

import moreVerticalFill from '@iconify/icons-eva/layers-fill';
import branchIcon from '@iconify/icons-ant-design/deployment-unit-outline';

import plusFill from '@iconify/icons-eva/plus-fill';
// components
import Page from '../../../components/Page';
import { UserListHead, UserListToolbar } from '../../../components/_dashboard/user';
import {tostS   , tostE, tostW } from '../../../config/Toast'
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { useSelector , useDispatch  } from 'react-redux';


// API
import {getExam} from 'src/Actions/AdminActions/Exam'

import {getAllCiHistory} from 'src/Actions/AdminActions/History'

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'message', label: 'Description', alignRight: false },
  { id: 'created_on', label: 'Point in Time', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.message[0].toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function ExamScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('his_id');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const primary_id = useSelector(state=>state.login.data.exam_id)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const listData = useSelector(state => state.History && state.History.getData &&
    Array.isArray(state.History.getData) && state.History.getData.length ? 
    state.History.getData : []);

  const ExamData = useSelector(state => state.Exam.getData && 
      Array.isArray(state.Exam.getData) && 
      state.Exam.getData.length ? (state.Exam.getData) : []);
  

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const filteredUsers = applySortFilter(listData, getComparator(order, 'message'), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const [values, setValues] = useState({
    exam_id:primary_id,    
 })

    useEffect(() =>{
        dispatch(getExam())
        dispatch(getAllCiHistory(primary_id))
        
      }, [])
    
    const handleChangeExam = (event) => {
        const e_id = event.target.value;

        setValues({
            ...values,
            ['exam_id']:e_id
        })
        dispatch(getAllCiHistory(primary_id))
    }

      

  return (
    <Page title="History">        
        <Container maxWidth="xl">     
        <Stack sx={{ pb: 3 }}>
          <Typography variant="h4" gutterBottom>
            All CI History
          </Typography>
          
        </Stack>
        <Grid container spacing={3}>
            <Grid item md={6} sm={12}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Exam</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.exam_id}
                        label="Select Exam"
                        name="exam_id"
                        onChange={handleChangeExam}
                    >
                        {ExamData.map((data , i)=>
                        <MenuItem key={i} value={data.exam_id}>{data.exam_name}</MenuItem>
                        )}        

                    </Select>
                </FormControl>
            </Grid>
          </Grid>       
          <Card className='cardBorder'>
            <UserListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                <TableHead className="tableRowBottomBorder">
                    <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Point in Time</TableCell>
                    </TableRow>
                </TableHead>
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row,i) => {
                        const {his_id, message, created_on } = row;

                        return (
                          <TableRow
                            hover
                            key={his_id}
                            tabIndex={-1}
                            className="tableRowBottomBorder"
                          >                                                        
                            <TableCell align="left">{message}</TableCell>
                            <TableCell align="left">{moment(created_on).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                            
                            
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      
        
        
    </Page>
  );
}
