import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import qrCode from '@iconify/icons-jam/qr-code';
import cubeIcon from '@iconify/icons-eva/cube-fill';
import monitorIcon from '@iconify/icons-eva/monitor-fill';
import folderIcon from '@iconify/icons-eva/folder-fill';
import codeIcon from '@iconify/icons-eva/code-fill';
import bookMarkIcon from '@iconify/icons-eva/bookmark-fill';
import sheetIcon from '@iconify/icons-ant-design/file-filled';
import downloadIcon from '@iconify/icons-eva/download-fill';


// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    subheader:'',
    items : [

      {
        title: 'dashboard',
        path: '/dashboard',
        icon: getIcon(pieChart2Fill)
      },
      {
        title: 'annexure',
        path: '/annexure',
        icon: getIcon(fileTextFill)
      },
      {
        title: 'attendance sheet',
        path: '/attendance-sheet',
        icon: getIcon(folderIcon)
      },
      {
        title: 'biometric',
        path: '/biometric',
        icon: getIcon(cubeIcon)
      },  
      {
        title: 'server backup',
        path: '/server-data',
        icon: getIcon(monitorIcon)
      },  
      {
        title: 'Response Sheet',
        path: '/response-sheet',
        icon: getIcon(sheetIcon)
      },
      {
        title: 'Catelina Backup',
        path: '/catelina-backup',
        icon: getIcon(codeIcon)
      },
      {
        title: 'rejection summary',
        path: '/rejection-summary',
        icon: getIcon(bookMarkIcon)
      },
      {
        title: 'Upload Appendix',
        path: '/upload-appendex',
        icon: getIcon(bookMarkIcon)
      },
      {
        title: 'downloadable data',
        path: '/downloads',
        icon: getIcon(downloadIcon)
      }
    ]
  }
    
  ];



export default sidebarConfig;
