import React, {useEffect, useState} from 'react';
import { filter } from 'lodash';
import {useNavigate} from 'react-router-dom'

// material
import { 
  Badge, 
  Grid, 
  Stack,
  TextField,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  CardContent,
  CardActionArea,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Tooltip,
  Breadcrumbs,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  MenuItem,
  Select,
  Button,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import DatePicker from '@material-ui/lab/DatePicker';
import { useTheme } from '@material-ui/styles';
// Icons
import { Icon } from '@iconify/react';

import branchIcon from '@iconify/icons-ant-design/deployment-unit-outline';
import downloadIcon from '@iconify/icons-eva/download-fill';

import plusFill from '@iconify/icons-eva/plus-fill';
// components
import Page from '../../../components/Page';
import { UserListHead, UserListToolbar } from '../../../components/_dashboard/user';
import {tostS   , tostE, tostW } from '../../../config/Toast'
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { useSelector , useDispatch  } from 'react-redux';

// API

import {addLinks, getLinks} from '../../../Actions/DownloadLinks'
import {getExam} from '../../../Actions/AdminActions/Exam'
import { cleanLinksData } from '../../../Actions/ActionCreator';

import moment from 'moment';





// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'file_name', label: 'File Name' },
  { id: 'file_link', label: 'File Link' },
  { id: 'created_on', label: 'Uploaded ON' },
  { id: 'Download_File', label: 'Download File' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.file_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



export default function ExamScreen() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('file_name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const primary_id = useSelector(state=>state.login.data.exam_id);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const ExamData = useSelector(state => state.Exam.getData && 
    Array.isArray(state.Exam.getData) && 
    state.Exam.getData.length ? (state.Exam.getData) : []);

  const listData = useSelector(state => state.DownloadLinks.getData && 
    Array.isArray(state.DownloadLinks.getData) && 
    state.DownloadLinks.getData.length ? state.DownloadLinks.getData : [])


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const filteredUsers = applySortFilter(listData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;


    

  const [values, setValues] = useState({
    file_name : "",
    file_link :"",
    exam_id : primary_id, 
  })

  const handleChange = (event) => {
    setValues({
        ...values,
        [event.target.name]:event.target.value
    })
  }

  const onDownload = (data) =>{

    window.open(data.file_link[0], '_blank');
  
  }

  const handleChangeExam = (event) => {

    setValues({
        ...values,
        ['exam_id']:event.target.value
    })
    
}
  const handleSubmit = () => {
    if(values.file_name==="" || values.file_name===null || values.file_name===undefined){
      tostW("Please Enter File Name");
    }else if(values.file_link==="" || values.file_link===null || values.file_link===undefined){
      tostW("Please Enter File Link!!");
    }else if(values.exam_id==="" || values.exam_id===null || values.exam_id===undefined){
      tostW("Please Select Exam!!");
    }else{
      new Promise((resolve, reject) => {
        dispatch(addLinks(values))
        resolve()
      }).then(() => {
        setValues({
          ...values,
          ['file_name'] : "",
          ['file_link'] :"",
        })
      })

    }
  }





  const AddMsg = useSelector(state => state.DownloadLinks.data && state.DownloadLinks.data.status && state.DownloadLinks.data.status.message ? state.DownloadLinks.data.status.message : null);
  const AddErr = useSelector(state => state.DownloadLinks.error && state.DownloadLinks.error.message ? state.DownloadLinks.error.message : null);
  
  useEffect(() =>{
    dispatch(getExam())
    dispatch(getLinks(values.exam_id))
    let x  = new Promise((resolve ,reject)=>{
    
      if(AddMsg !== null)
      {
        tostS(AddMsg)
      }
      if(AddErr !== null)
      {
        tostE(AddErr)
      }
      
      resolve()
  
    })
    x.then( () => {
      dispatch(cleanLinksData())
    dispatch(getLinks(values.exam_id))

    })
    

  }, [AddErr, AddMsg])



  return (
    <Page title="Downloadable Data">      
      <Container maxWidth="xl" style={{backgroundColor:theme.palette.background.paper, elevation:15, marginBottom:'15px'}}>
        <Card className='cardBorder'>
            <CardHeader title="Add Download Links" />
            <CardContent>
                <Stack sx={{ pb: 3, pt:3 }}>            
                    <Grid item md={6} sm={12}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">Select Exam</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={values.exam_id}
                                label="Country"
                                name="exam_id"
                                onChange={handleChangeExam}
                            >
                                {ExamData.map((data , i)=>
                                <MenuItem key={i} value={data.exam_id}>{data.exam_name}</MenuItem>
                                )}        

                            </Select>
                        </FormControl>
                    </Grid>
                
                </Stack>

                <Grid container spacing={3}>
                    <Grid item md={6} sm={12}>
                        <TextField
                        fullWidth            
                        name="file_name"
                        label="File Name"
                        value={values.file_name}
                        onChange = {handleChange}
                    />
                    </Grid>
                    <Grid item md={6} sm={12}>
                        <TextField
                        fullWidth            
                        name="file_link"
                        label="File Link"
                        value={values.file_link}
                        onChange = {handleChange}
                        />
                    </Grid>          
                </Grid>
                <LoadingButton                
                size="large"
                fullWidth
                type="submit"
                variant="contained"          
                onClick={() => {handleSubmit()}}
                sx={{ my: 2 }}
                >
                Add Link 
                </LoadingButton>
            </CardContent>
            
        </Card>
        
        
        
        
          <Card className='cardBorder'>
            <CardHeader title="Added File Links" />
            <UserListToolbar              
              filterName={filterName}
              onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={listData.length}                    
                    onRequestSort={handleRequestSort}                    
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { dd_id, file_name , created_on , file_link , is_active } = row;
                        const isItemSelected = selected.indexOf(dd_id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={dd_id}
                            tabIndex={-1}
                            className="tableRowBottomBorder"
                            
                          >                                                        
                            <TableCell>{file_name}</TableCell>
                            <TableCell>{file_link}</TableCell>
                            <TableCell>{moment(created_on).format("MMMM Do YYYY")}</TableCell>                                                        
                            <TableCell>                                  
                                <Tooltip title="Download File">
                                    <Icon icon={downloadIcon} onClick={()=>{onDownload(row)}}  width={24} height={24} style={{marginRight:20 , cursor : "pointer"}} /> 
                                </Tooltip>
                            </TableCell>
                            
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={listData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          
      </Container>
      
        
        
    </Page>
  );
}
