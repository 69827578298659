import React ,  {useRef , useState , useEffect} from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, 
         Card, 
         Checkbox, 
         Container, 
         Typography, 
         Stack, 
         Button, 
         Grid,         
         Avatar,
         CardActions,
         CardContent,
         Divider,         
         CardHeader,
         OutlinedInput,         
         Chip,
         TextField} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useSelector , useDispatch , shallowEqual } from 'react-redux';
         
import {tostS  , tostW , tostE } from '../../../config/Toast'
// components
import Page from '../../../components/Page';


import {ToastContainer} from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// API 

import {allocateExamCenter, getAvlCenter} from '../../../Actions/AdminActions/Center'
import {getAvlExamSlot} from '../../../Actions/AdminActions/Slots'
import {getAllCi} from '../../../Actions/AdminActions/Ci'
import {cleanExamCenterData} from '../../../Actions/ActionCreator'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  
  
  display: 'flex',
  
  flexDirection: 'column',
  padding: theme.spacing(0, 5, 0, 5)
  
}));


const useStyles = makeStyles((theme) => ({
   root: {
     backgroundColor: theme.palette.background.dark,
     minHeight: '100%',
     paddingBottom: theme.spacing(3),
   //   paddingTop: theme.spacing(3)
   },
   root2: {},
   avatar: {
     height: 250,
     width: 250
   },
   formControl: {
     margin: theme.spacing(0),
     minWidth: '100%',
   },
   selectEmpty: {
     marginTop: theme.spacing(2),
   },
 
 }));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


 function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


// ----------------------------------------------------------------------

const AddCenter = ({className, ...rest}) => {
   const theme = useTheme();
   const classes = useStyles();
   const dispatch = useDispatch();
   const {state} = useLocation();
 
  const a_id = useSelector(state=> state.login.data.admin_id);

   const [values, setValues] = useState({          
    "exam_id" : state.exam_id,
    "centre_code":"",
    "centre_id" : "",
    "admin_id" : a_id,
    "ci_details" : [],
    "avl_slots" : [],
   });
 
 
   const handleChange = (event) => {
     setValues({
       ...values,
       [event.target.name]: event.target.value,
     });
   };
 
   const handleChangeCenter = (event) => {
     setValues({
       ...values,
       ['centre_id']: event.target.value,
     });
   };
 
  const handleChangeCI = (event) => {
    const {
      target: { value },
    } = event;

    setValues({
      ...values,
      ['ci_details']:(typeof value === 'string' ? value.split(',') : value)
    });
  }
 

  

  const handleChangeSlot = (event) => {
    const {
      target: { value },
    } = event;

    setValues({
      ...values,
      ['avl_slots']:(typeof value === 'string' ? value.split(',') : value)
    });
  }
 
   const handleSubmit = () =>{
    if(values.centre_code==="" || values.centre_code===null || values.centre_code===undefined){
      tostW("Please Enter Center Code!!");
    }else if(values.centre_id==="" || values.centre_id===null || values.centre_id===undefined){
      tostW("Please Select Center!!");
    }else if(Array.isArray(values.avl_slots) && values.avl_slots.length===0){
      tostW("Please Select At-least One Slot !!");
    }else if(Array.isArray(values.ci_details) && values.ci_details.length===0){
      tostW("Please Select At-least One CI !!");
    }else{
      dispatch(allocateExamCenter(values))
      setValues({
         ...values,
         "centre_code":"",
         "centre_id" : "",
         "ci_details" : [],
         "avl_slots" : [],
      });

    }


 
   }
 
  
  const avlCenterData = useSelector(state => state.ExamCenter && state.ExamCenter.avlData 
      && Array.isArray(state.ExamCenter.avlData) && state.ExamCenter.avlData.length ?
      state.ExamCenter.avlData : []);

  const avlSlotData = useSelector(state => state.Slots && state.Slots.avlData 
      && Array.isArray(state.Slots.avlData) && state.Slots.avlData.length ?
      state.Slots.avlData : []);
  
  const CiData = useSelector(state => state.CiDetail && state.CiDetail.allGetData 
      && Array.isArray(state.CiDetail.allGetData) && state.CiDetail.allGetData.length ?
      state.CiDetail.allGetData : []);


   
  const addmsg = useSelector((state) => state.ExamCenter  && state.ExamCenter.allocateData && state.ExamCenter.allocateData.status && state.ExamCenter.allocateData.status.status===200 ? state.ExamCenter.allocateData.status.status : null  )
  const adderror = useSelector((state) => state.ExamCenter  && state.ExamCenter.allocateError && state.ExamCenter.allocateError.message ? state.ExamCenter.allocateError.message : null  )
   
   
useEffect( () => {

  dispatch(getAvlCenter())
  dispatch(getAllCi())
  dispatch(getAvlExamSlot(state.exam_id))

  let x  = new Promise((resolve ,reject)=>{
    if(addmsg !==null && addmsg===200)
    {
      tostS("Center Alloted SuccessFully!!")
    }
    if(adderror !==null && typeof(adderror) === 'string')
    {
      tostE(adderror)
    }

    resolve()

  })
  x.then(()=>{

 dispatch(cleanExamCenterData())


  })


  
  }, [addmsg , adderror])
  
  
  return (
    <RootStyle title="Allocate Center">    
      <ToastContainer    />      
      <Container maxWidth = "lg">
        
        <ContentStyle>
        
        
        
            <form autoComplete="off" noValidate className={clsx(classes.root, className)} {...rest}>
        <Card className='cardBorder'>
        <CardHeader subheader="" title="Allocate Center Form" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>            
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Center Code"
                name="centre_code"
                onChange={handleChange}
                required
                value={values.centre_code}
                variant="outlined"
              />
            </Grid>
            
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Center</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values.centre_id}
                  label="Select Center"
                  name="centre_id"
                  onChange={handleChangeCenter}
                >
                  {avlCenterData.map((data , i)=>
                    <MenuItem key={i} value={data.centre_id}>{data.centre_name}</MenuItem>
                  )}        

                </Select>
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Select Slot</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={values.avl_slots}
                  onChange={handleChangeSlot}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={avlSlotData.filter(d => d.slot_id==value)[0].slot_name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {avlSlotData.map((data) => (
                    <MenuItem
                      key={data.slot_id}
                      value={data.slot_id}
                      style={getStyles(data.slot_id, values.avl_slots, theme)}
                    >
                    <Checkbox checked={values.avl_slots.indexOf(data.slot_id)>-1} />
                      {data.slot_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>  
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Select CI</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={values.ci_details}
                  onChange={handleChangeCI}
                  input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={CiData.filter(d => d.ci_id==value)[0].name} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {CiData.map((data) => (
                    <MenuItem
                      key={data.ci_id}
                      value={data.ci_id}
                      style={getStyles(data.ci_id, values.ci_details, theme)}
                    >
                    <Checkbox checked={values.ci_details.indexOf(data.ci_id)>-1} />
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>  
             
            
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Save details
          </Button>
        </Box>
      </Card>
    </form>
            
          
       
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}


export default AddCenter;