import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {getCountryDataBegin , getCountryDataSuccess , getCountryDataFailure} from '../ActionCreator';
import {getStateDataBegin , getStateDataSuccess , getStateDataFailure} from '../ActionCreator';
import {getCityDataBegin , getCityDataSuccess , getCityDataFailure} from '../ActionCreator';

export const getCountryData = () => {

    return (dispatch) => {
        dispatch(getCountryDataBegin());

        axios.get(Base_URL + '/admin/getcountry')
            .then((res) => {
                dispatch(getCountryDataSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getCountryDataFailure(err.response.data));

                }
            })
    }
}


export const getStateData = (x) => {

    return (dispatch) => {
        dispatch(getStateDataBegin());

        axios.get(Base_URL + '/admin/getstate', {params:{c_id:x}})
            .then((res) => {
                dispatch(getStateDataSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getStateDataFailure(err.response.data));

                }
            })
    }
}


export const getCityData = (x) => {

    return (dispatch) => {
        dispatch(getCityDataBegin());

        axios.get(Base_URL + '/admin/getcity', {params:{s_id:x}})
            .then((res) => {
                dispatch(getCityDataSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getCityDataFailure(err.response.data));

                }
            })
    }
}

