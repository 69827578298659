import React, { useState, useEffect } from 'react';

import * as Yup from 'yup';

// material
import { styled } from '@material-ui/core/styles';
import { Card, Stack, Container, Typography,TextField, IconButton, InputAdornment } from '@material-ui/core';

// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';

// material
import { LoadingButton } from '@material-ui/lab';

import { useFormik} from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';

import {tostE } from '../../config/Toast'
import {ToastContainer} from 'react-toastify';
import { useSelector, useDispatch  } from 'react-redux';


import {login} from '../../Actions/AdminActions/Login'

import { cleanAllData } from 'src/Actions/ActionCreator';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  padding:25,
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(0, 0, 12, 2)
}));

const ImageSectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  height:"50%",
  maxWidth: 464,
  padding:25,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(12,0)
}));


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 512,
  margin: 'auto',
  display: 'flex',
  minHeight: '80vh',
  flexDirection: 'column',
  // justifyContent: 'center',
  padding: theme.spacing(4, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  
  const [state, setState] = useState([{mobile:"", password:""}])

  const handleChange = (event) => {
    setState({
        ...state,
        [event.target.name]:event.target.value
    })
  }
  const LoginErr = useSelector(state => state.login.error && state.login.error.message ? state.login.error.message : null);

  useEffect(() =>{
   
    let x  = new Promise((resolve ,reject)=>{
    
      
      if(LoginErr !== null)
      {
        tostE(LoginErr)
      }

      resolve()
  
    })
    x.then( () => {
      dispatch(cleanAllData())
      
    })
    

  }, [LoginErr])


  const handleSubmitLogin = (event) => {



      if (state.mobile==='' || state.mobile===undefined || state.mobile===null){
        tostE("Please Enter Mobile Number!!")
      }else if(state.password==='' || state.password===undefined || state.password===null){
        tostE("Please Enter Password !!")
      }else{
        dispatch(login(state))
      }

   

  }


  
  const LoginSchema = Yup.object().shape({
    mobile: Yup.string().required('Mobile Number is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      mobile: '',
      password: '',
      
    },
    validationSchema: LoginSchema,

  });
  const { errors, touched, isSubmitting } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };


  return (
    <RootStyle title="Login | Netcom Analytics">
      
      <ToastContainer />
      <MHidden width="mdDown">
        <ImageSectionStyle>
          <Typography variant="h3" sx={{ px: 5, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_components.png" alt="login" />
        </ImageSectionStyle>
      </MHidden>

      <Container maxWidth="sm">        
        <ContentStyle>
          <Stack flexDirection='row'>
            <SectionStyle style={{height:100, elevation:25}}>
              <img src="/static/Cdac.svg" style={{marginBottom:"25px"}} alt="login" />
            </SectionStyle>
            <SectionStyle style={{height:100, elevation:25}}>
              <img src="/static/netparam.svg" style={{marginBottom:"25px"}} alt="login" />
            </SectionStyle>

          </Stack>
          <Stack sx={{ mb: 3 }}>
            <Typography variant="h4" gutterBottom>
            Admin Sign in to Netcom Analytics 
            </Typography>
            
          </Stack>
          <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            variant='outlined'
            type="mobile"
            name="mobile"
            label="Mobile Number"
            value={state.mobile}
            onChange = {handleChange}
            error={Boolean(touched.mobile && errors.mobile)}
            helperText={touched.mobile && errors.mobile}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            name="password"
            value={state.password}
            onChange={handleChange}
            
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          onClick={handleSubmitLogin}
          sx={{ my: 2 }}
        >
          Login
        </LoadingButton>
          
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
