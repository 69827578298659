import {LOGIN_LOADING , LOGIN_SUCCESS , LOGIN_FAILURE } from './Types' ;
import {LOGOUT_LOADING , LOGOUT_SUCCESS , LOGOUT_FAILURE } from './Types' ;

import {SET_SLOT, CLEAN_ALL_DATA} from './Types'


import {GET_COUNTRY_DATA_LOADING , GET_COUNTRY_DATA_SUCCESS , GET_COUNTRY_DATA_FAILURE} from './Types'
import {GET_STATE_DATA_LOADING , GET_STATE_DATA_SUCCESS , GET_STATE_DATA_FAILURE} from './Types'
import {GET_CITY_DATA_LOADING , GET_CITY_DATA_SUCCESS , GET_CITY_DATA_FAILURE} from './Types'


// Data Links
import {ADD_LINKS_LOADING , ADD_LINKS_SUCCESS , ADD_LINKS_FAILURE, CLEAN_LINKS_DATA} from './Types'
import {GET_LINKS_LOADING , GET_LINKS_SUCCESS , GET_LINKS_FAILURE} from './Types'



// Dashboard
import {GET_DASHBOARD_LOADING , GET_DASHBOARD_SUCCESS , GET_DASHBOARD_FAILURE} from './Types'

// Admin Exam Profile
import {ADD_EXAM_LOADING , ADD_EXAM_SUCCESS , ADD_EXAM_FAILURE, CLEAN_EXAM_DATA} from './Types'
import {GET_EXAM_LOADING , GET_EXAM_SUCCESS , GET_EXAM_FAILURE} from './Types'
import {SET_EXAM_PRIMARY_LOADING , SET_EXAM_PRIMARY_SUCCESS , SET_EXAM_PRIMARY_FAILURE} from './Types'

// Admin Exam sLOTS
import {ADD_EXAM_SLOT_LOADING , ADD_EXAM_SLOT_SUCCESS , ADD_EXAM_SLOT_FAILURE, CLEAN_EXAM_SLOT_DATA} from './Types'
import {GET_CENTER_SLOT_LOADING , GET_CENTER_SLOT_SUCCESS , GET_CENTER_SLOT_FAILURE} from './Types'
import {GET_AVL_SLOT_LOADING , GET_AVL_SLOT_SUCCESS , GET_AVL_SLOT_FAILURE} from './Types'

// Admin Exam CI
import {ADD_CI_LOADING , ADD_CI_SUCCESS , ADD_CI_FAILURE, CLEAN_CI_DATA} from './Types'
import {GET_CENTER_CI_LOADING , GET_CENTER_CI_SUCCESS , GET_CENTER_CI_FAILURE} from './Types'
import {GET_ALL_CI_LOADING , GET_ALL_CI_SUCCESS , GET_ALL_CI_FAILURE} from './Types'

// Admin Exam Center
import {ADD_EXAM_CENTER_LOADING , ADD_EXAM_CENTER_SUCCESS , ADD_EXAM_CENTER_FAILURE, CLEAN_EXAM_CENTER_DATA} from './Types'
import {ALLOCATE_EXAM_CENTER_LOADING , ALLOCATE_EXAM_CENTER_SUCCESS , ALLOCATE_EXAM_CENTER_FAILURE} from './Types'
import {GET_EXAM_CENTER_LOADING , GET_EXAM_CENTER_SUCCESS , GET_EXAM_CENTER_FAILURE} from './Types'
import {GET_ALL_CENTER_LOADING , GET_ALL_CENTER_SUCCESS , GET_ALL_CENTER_FAILURE} from './Types'
import {GET_AVL_CENTER_LOADING , GET_AVL_CENTER_SUCCESS , GET_AVL_CENTER_FAILURE} from './Types'


// Admin Upload Request
import {APPROVE_UPLOAD_REQUEST_LOADING , APPROVE_UPLOAD_REQUEST_SUCCESS , APPROVE_UPLOAD_REQUEST_FAILURE, CLEAN_UPLOAD_REQUEST_DATA} from './Types'
import {GET_UPLOAD_REQUEST_LOADING , GET_UPLOAD_REQUEST_SUCCESS , GET_UPLOAD_REQUEST_FAILURE} from './Types'

// Admin vERIFY dATA
import {VERIFY_DATA_LOADING , VERIFY_DATA_SUCCESS , VERIFY_DATA_FAILURE, CLEAN_VERIFIED_DATA} from './Types'
import {GET_VERIFIED_DATA_LOADING , GET_VERIFIED_DATA_SUCCESS , GET_VERIFIED_DATA_FAILURE} from './Types'

import {GET_ALL_CI_HISTORY_LOADING , GET_ALL_CI_HISTORY_SUCCESS , GET_ALL_CI_HISTORY_FAILURE} from './Types'

import {CHANGE_ADMIN_PASS_LOADING , CHANGE_ADMIN_PASS_SUCCESS , CHANGE_ADMIN_PASS_FAILURE, CLEAN_PROFILE_DATA} from './Types'
import {CHANGE_ADMIN_PROFILE_PIC_LOADING , CHANGE_ADMIN_PROFILE_PIC_SUCCESS , CHANGE_ADMIN_PROFILE_PIC_FAILURE} from './Types'


// Post Exam
import {GET_MASTER_BACKUP_LOADING , GET_MASTER_BACKUP_SUCCESS , GET_MASTER_BACKUP_FAILURE} from './Types'


/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

CI Type

++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */


import {CI_BACKUP_STATUS_LOADING,  CI_BACKUP_STATUS_SUCCESS, CI_BACKUP_STATUS_FAILURE} from './Types'
import {GET_UPLOADED_DATA_LOADING,  GET_UPLOADED_DATA_SUCCESS, GET_UPLOADED_DATA_FAILURE, CLEAN_UPLOADED_DATA} from './Types'
import {REQ_UPLOAD_DATA_LOADING,  REQ_UPLOAD_DATA_SUCCESS, REQ_UPLOAD_DATA_FAILURE} from './Types'
import {UPLOAD_BIOMETRIC_LOADING,  UPLOAD_BIOMETRIC_SUCCESS, UPLOAD_BIOMETRIC_FAILURE,UPLOAD_BIOMETRIC_PROGRESS, CLEAN_UPLOAD_BIOMETRIC_DATA} from './Types'
import {UPLOAD_ANNEXURE_LOADING,  UPLOAD_ANNEXURE_SUCCESS, UPLOAD_ANNEXURE_FAILURE, UPLOAD_ANNEXURE_PROGRESS, CLEAN_UPLOAD_ANNEXURE_DATA} from './Types'
import {UPLOAD_SERVER_LOADING,  UPLOAD_SERVER_SUCCESS, UPLOAD_SERVER_FAILURE, UPLOAD_SERVER_PROGRESS, CLEAN_UPLOAD_SERVER_DATA} from './Types'
import {UPLOAD_ATT_SHEET_LOADING,  UPLOAD_ATT_SHEET_SUCCESS, UPLOAD_ATT_SHEET_FAILURE,UPLOAD_ATT_SHEET_PROGRESS, CLEAN_UPLOAD_ATT_SHEET_DATA} from './Types'
import {ADD_REJ_SUMMARY_LOADING,  ADD_REJ_SUMMARY_SUCCESS, ADD_REJ_SUMMARY_FAILURE, CLEAN_ADD_REJ_SUMMARY_DATA} from './Types'
import {UPLOAD_REJ_SUMMARY_LOADING,  UPLOAD_REJ_SUMMARY_SUCCESS, UPLOAD_REJ_SUMMARY_FAILURE, UPLOAD_REJ_SUMMARY_PROGRESS, CLEAN_UPLOAD_REJ_SUMMARY_DATA} from './Types'
import {UPLOAD_RES_SHEET_LOADING,  UPLOAD_RES_SHEET_SUCCESS, UPLOAD_RES_SHEET_FAILURE, UPLOAD_RES_SHEET_PROGRESS, CLEAN_UPLOAD_RES_SHEET_DATA} from './Types'
import {UPLOAD_CATELINA_LOADING,  UPLOAD_CATELINA_SUCCESS, UPLOAD_CATELINA_FAILURE, UPLOAD_CATELINA_PROGRESS, CLEAN_UPLOAD_CATELINA_DATA} from './Types'

import {CHANGE_CI_PASS_LOADING , CHANGE_CI_PASS_SUCCESS , CHANGE_CI_PASS_FAILURE, CLEAN_CI_PROFILE_DATA} from './Types'
import {CHANGE_CI_PROFILE_PIC_LOADING , CHANGE_CI_PROFILE_PIC_SUCCESS , CHANGE_CI_PROFILE_PIC_FAILURE} from './Types'


export const loginBegin = () => ({
   type : LOGIN_LOADING
})

export const loginSuccess = (logindata) => ({
   type : LOGIN_SUCCESS,
   logindata ,
})

export const loginFailure = (loginerror) => ({
   type : LOGIN_FAILURE,
   loginerror
   
})




export const logoutBegin = () => ({
   type : LOGOUT_LOADING
})

export const logoutSuccess = () => ({
   type : LOGOUT_SUCCESS
})

export const logoutFailure = () => ({
   type : LOGOUT_FAILURE
   
})


export const cleanAllData = () => ({
   type : CLEAN_ALL_DATA,
 })
 

export const setSlot = (data) => ({
   type : SET_SLOT,
   data,
 })
 


export const getDashboardBegin = () => ({
   type : GET_DASHBOARD_LOADING
})

export const getDashboardSuccess = (data) => ({
   type : GET_DASHBOARD_SUCCESS,
   data ,
})

export const getDashboardFailure = (error) => ({
   type : GET_DASHBOARD_FAILURE,
   error, 
})
 


export const getCountryDataBegin = () => ({
   type : GET_COUNTRY_DATA_LOADING
})

export const getCountryDataSuccess = (data) => ({
   type : GET_COUNTRY_DATA_SUCCESS,
   data ,
})

export const getCountryDataFailure = (error) => ({
   type : GET_COUNTRY_DATA_FAILURE,
   error, 
})


export const getStateDataBegin = () => ({
   type : GET_STATE_DATA_LOADING
})

export const getStateDataSuccess = (data) => ({
   type : GET_STATE_DATA_SUCCESS,
   data ,
})

export const getStateDataFailure = (error) => ({
   type : GET_STATE_DATA_FAILURE,
   error, 
})


export const getCityDataBegin = () => ({
   type : GET_CITY_DATA_LOADING
})

export const getCityDataSuccess = (data) => ({
   type : GET_CITY_DATA_SUCCESS,
   data ,
})

export const getCityDataFailure = (error) => ({
   type : GET_CITY_DATA_FAILURE,
   error, 
})

// Admin Exam Profile

export const addExamBegin = () => ({
   type : ADD_EXAM_LOADING
})

export const addExamSuccess = (data) => ({
   type : ADD_EXAM_SUCCESS,
   data ,
})

export const addExamFailure = (error) => ({
   type : ADD_EXAM_FAILURE,
   error, 
})


export const getExamBegin = () => ({
   type : GET_EXAM_LOADING
})

export const getExamSuccess = (data) => ({
   type : GET_EXAM_SUCCESS,
   data ,
})

export const getExamFailure = (error) => ({
   type : GET_EXAM_FAILURE,
   error, 
})



export const setExamPrimaryBegin = () => ({
   type : SET_EXAM_PRIMARY_LOADING
})

export const setExamPrimarySuccess = (data) => ({
   type : SET_EXAM_PRIMARY_SUCCESS,
   data ,
})

export const setExamPrimaryFailure = (error) => ({
   type : SET_EXAM_PRIMARY_FAILURE,
   error, 
})



export const cleanExamData = () => ({
   type : CLEAN_EXAM_DATA,
 })
 


// DAta Links




// Admin vERIFY DATA

export const addLinksBegin = () => ({
   type : ADD_LINKS_LOADING
})

export const addLinksSuccess = (data) => ({
   type : ADD_LINKS_SUCCESS,
   data ,
})

export const addLinksFailure = (error) => ({
   type : ADD_LINKS_FAILURE,
   error, 
})


export const getLinksBegin = () => ({
   type : GET_LINKS_LOADING
})

export const getLinksSuccess = (data) => ({
   type : GET_LINKS_SUCCESS,
   data ,
})

export const getLinksFailure = (error) => ({
   type : GET_LINKS_FAILURE,
   error, 
})



export const cleanLinksData = () => ({
   type : CLEAN_LINKS_DATA,
 })
 





// Admin Exam Profile

export const addExamSlotBegin = () => ({
   type : ADD_EXAM_SLOT_LOADING
})

export const addExamSlotSuccess = (data) => ({
   type : ADD_EXAM_SLOT_SUCCESS,
   data ,
})

export const addExamSlotFailure = (error) => ({
   type : ADD_EXAM_SLOT_FAILURE,
   error, 
})


export const getCenterSlotBegin = () => ({
   type : GET_CENTER_SLOT_LOADING
})

export const getCenterSlotSuccess = (data) => ({
   type : GET_CENTER_SLOT_SUCCESS,
   data ,
})

export const getCenterSlotFailure = (error) => ({
   type : GET_CENTER_SLOT_FAILURE,
   error, 
})



export const getAvlSlotBegin = () => ({
   type : GET_AVL_SLOT_LOADING
})

export const getAvlSlotSuccess = (data) => ({
   type : GET_AVL_SLOT_SUCCESS,
   data ,
})

export const getAvlSlotFailure = (error) => ({
   type : GET_AVL_SLOT_FAILURE,
   error, 
})



export const cleanExamSlotData = () => ({
   type : CLEAN_EXAM_SLOT_DATA,
 })
 

// Admin Exam center

export const addExamCenterBegin = () => ({
   type : ADD_EXAM_CENTER_LOADING
})

export const addExamCenterSuccess = (data) => ({
   type : ADD_EXAM_CENTER_SUCCESS,
   data ,
})

export const addExamCenterFailure = (error) => ({
   type : ADD_EXAM_CENTER_FAILURE,
   error, 
})

export const allocateExamCenterBegin = () => ({
   type : ALLOCATE_EXAM_CENTER_LOADING
})

export const allocateExamCenterSuccess = (data) => ({
   type : ALLOCATE_EXAM_CENTER_SUCCESS,
   data ,
})

export const allocateExamCenterFailure = (error) => ({
   type : ALLOCATE_EXAM_CENTER_FAILURE,
   error, 
})


export const getExamCenterBegin = () => ({
   type : GET_EXAM_CENTER_LOADING
})

export const getExamCenterSuccess = (data) => ({
   type : GET_EXAM_CENTER_SUCCESS,
   data ,
})

export const getExamCenterFailure = (error) => ({
   type : GET_EXAM_CENTER_FAILURE,
   error, 
})



export const getAvlCenterBegin = () => ({
   type : GET_AVL_CENTER_LOADING
})

export const getAvlCenterSuccess = (data) => ({
   type : GET_AVL_CENTER_SUCCESS,
   data ,
})

export const getAvlCenterFailure = (error) => ({
   type : GET_AVL_CENTER_FAILURE,
   error, 
})



export const getAllCenterBegin = () => ({
   type : GET_ALL_CENTER_LOADING
})

export const getAllCenterSuccess = (data) => ({
   type : GET_ALL_CENTER_SUCCESS,
   data ,
})

export const getAllCenterFailure = (error) => ({
   type : GET_ALL_CENTER_FAILURE,
   error, 
})



export const cleanExamCenterData = () => ({
   type : CLEAN_EXAM_CENTER_DATA,
 })
 

// Admin Ci Details

export const addCiBegin = () => ({
   type : ADD_CI_LOADING
})

export const addCiSuccess = (data) => ({
   type : ADD_CI_SUCCESS,
   data ,
})

export const addCiFailure = (error) => ({
   type : ADD_CI_FAILURE,
   error, 
})


export const getCenterCiBegin = () => ({
   type : GET_CENTER_CI_LOADING
})

export const getCenterCiSuccess = (data) => ({
   type : GET_CENTER_CI_SUCCESS,
   data ,
})

export const getCenterCiFailure = (error) => ({
   type : GET_CENTER_CI_FAILURE,
   error, 
})



export const getAllCiBegin = () => ({
   type : GET_ALL_CI_LOADING
})

export const getAllCiSuccess = (data) => ({
   type : GET_ALL_CI_SUCCESS,
   data ,
})

export const getAllCiFailure = (error) => ({
   type : GET_ALL_CI_FAILURE,
   error, 
})



export const cleanCiData = () => ({
   type : CLEAN_CI_DATA,
 })
 



// Admin Re Upload Request

export const approveUploadRequestBegin = () => ({
   type : APPROVE_UPLOAD_REQUEST_LOADING
})

export const approveUploadRequestSuccess = (data) => ({
   type : APPROVE_UPLOAD_REQUEST_SUCCESS,
   data ,
})

export const approveUploadRequestFailure = (error) => ({
   type : APPROVE_UPLOAD_REQUEST_FAILURE,
   error, 
})


export const getUploadRequestBegin = () => ({
   type : GET_UPLOAD_REQUEST_LOADING
})

export const getUploadRequestSuccess = (data) => ({
   type : GET_UPLOAD_REQUEST_SUCCESS,
   data ,
})

export const getUploadRequestFailure = (error) => ({
   type : GET_UPLOAD_REQUEST_FAILURE,
   error, 
})



export const cleanUploadRequestData = () => ({
   type : CLEAN_UPLOAD_REQUEST_DATA,
 })
 

// All CI History

 export const getAllCiHistoryBegin = () => ({
   type : GET_ALL_CI_HISTORY_LOADING
})

export const getAllCiHistorySuccess = (data) => ({
   type : GET_ALL_CI_HISTORY_SUCCESS,
   data ,
})

export const getAllCiHistoryFailure = (error) => ({
   type : GET_ALL_CI_HISTORY_FAILURE,
   error, 
})


// Admin Profile Management

export const changeAdminPassBegin = () => ({
   type : CHANGE_ADMIN_PASS_LOADING
})

export const changeAdminPassSuccess = (data) => ({
   type : CHANGE_ADMIN_PASS_SUCCESS,
   data ,
})

export const changeAdminPassFailure = (error) => ({
   type : CHANGE_ADMIN_PASS_FAILURE,
   error, 
})



export const changeAdminProfilePicBegin = () => ({
   type : CHANGE_ADMIN_PROFILE_PIC_LOADING
})

export const changeAdminProfilePicSuccess = (data) => ({
   type : CHANGE_ADMIN_PROFILE_PIC_SUCCESS,
   data ,
})

export const changeAdminProfilePicFailure = (error) => ({
   type : CHANGE_ADMIN_PROFILE_PIC_FAILURE,
   error, 
})



export const cleanProfileData = () => ({
   type : CLEAN_PROFILE_DATA,
 })
 



// Admin vERIFY DATA

export const verifyDataBegin = () => ({
   type : VERIFY_DATA_LOADING
})

export const verifyDataSuccess = (data) => ({
   type : VERIFY_DATA_SUCCESS,
   data ,
})

export const verifyDataFailure = (error) => ({
   type : VERIFY_DATA_FAILURE,
   error, 
})


export const getVerifiedDataBegin = () => ({
   type : GET_VERIFIED_DATA_LOADING
})

export const getVerifiedDataSuccess = (data) => ({
   type : GET_VERIFIED_DATA_SUCCESS,
   data ,
})

export const getVerifiedDataFailure = (error) => ({
   type : GET_VERIFIED_DATA_FAILURE,
   error, 
})



export const cleanVerifiedData = () => ({
   type : CLEAN_VERIFIED_DATA,
 })
 
 


 export const getMasterBackupBegin = () => ({
   type : GET_MASTER_BACKUP_LOADING
})

export const getMasterBackupSuccess = (data) => ({
   type : GET_MASTER_BACKUP_SUCCESS,
   data ,
})

export const getMasterBackupFailure = (error) => ({
   type : GET_MASTER_BACKUP_FAILURE,
   error, 
})
 








/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

CI Action Creators

++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */



// Get bACKUP sTATUS 

export const CiBackupStatusBegin = () => ({
   type :CI_BACKUP_STATUS_LOADING
})

export const CiBackupStatusSuccess = (data) => ({
   type :CI_BACKUP_STATUS_SUCCESS,
   data ,
})

export const CiBackupStatusFailure = (error) => ({
   type :CI_BACKUP_STATUS_FAILURE,
   error, 
})

// Get Uploaded 

export const getUploadedDataBegin = () => ({
   type : GET_UPLOADED_DATA_LOADING
})

export const getUploadedDataSuccess = (data) => ({
   type : GET_UPLOADED_DATA_SUCCESS,
   data ,
})

export const getUploadedDataFailure = (error) => ({
   type : GET_UPLOADED_DATA_FAILURE,
   error, 
})

// Request Upload data 

export const reqUploadDataBegin = () => ({
   type : REQ_UPLOAD_DATA_LOADING
})

export const reqUploadDataSuccess = (data) => ({
   type : REQ_UPLOAD_DATA_SUCCESS,
   data ,
})

export const reqUploadDataFailure = (error) => ({
   type : REQ_UPLOAD_DATA_FAILURE,
   error, 
})


export const cleanUploadedData = () => ({
   type : CLEAN_UPLOADED_DATA,
 })
 

 


// Upload Annexure

export const uploadAnnexureBegin = () => ({
   type : UPLOAD_ANNEXURE_LOADING
})

export const uploadAnnexureSuccess = (data) => ({
   type : UPLOAD_ANNEXURE_SUCCESS,
   data ,
})

export const uploadAnnexureFailure = (error) => ({
   type : UPLOAD_ANNEXURE_FAILURE,
   error, 
})

export const uploadAnnexureProgress = (progress) => ({
   type : UPLOAD_ANNEXURE_PROGRESS,
   progress, 
})

export const cleanAnnexureData = () => ({
   type : CLEAN_UPLOAD_ANNEXURE_DATA,
 })
 

 
// Upload Biometric data

export const uploadBiometricBegin = () => ({
   type : UPLOAD_BIOMETRIC_LOADING
})

export const uploadBiometricSuccess = (data) => ({
   type : UPLOAD_BIOMETRIC_SUCCESS,
   data ,
})

export const uploadBiometricFailure = (error) => ({
   type : UPLOAD_BIOMETRIC_FAILURE,
   error, 
})


export const uploadBiometricProgress = (progress) => ({
   type : UPLOAD_BIOMETRIC_PROGRESS,
   progress, 
})


export const cleanBiometricData = () => ({
   type : CLEAN_UPLOAD_BIOMETRIC_DATA,
 })
 

//  Upload Server Backup

export const uploadServerBegin = () => ({
   type : UPLOAD_SERVER_LOADING
})

export const uploadServerSuccess = (data) => ({
   type : UPLOAD_SERVER_SUCCESS,
   data ,
})

export const uploadServerFailure = (error) => ({
   type : UPLOAD_SERVER_FAILURE,
   error, 
})

export const uploadServerProgress = (progress) => ({
   type : UPLOAD_SERVER_PROGRESS,
   progress, 
})



export const cleanServerData = () => ({
   type : CLEAN_UPLOAD_SERVER_DATA,
 })
 

//  Upload Attendance Sheet


export const uploadAttSheetBegin = () => ({
   type : UPLOAD_ATT_SHEET_LOADING
})

export const uploadAttSheetSuccess = (data) => ({
   type : UPLOAD_ATT_SHEET_SUCCESS,
   data ,
})

export const uploadAttSheetFailure = (error) => ({
   type : UPLOAD_ATT_SHEET_FAILURE,
   error, 
})

export const uploadAttSheetProgress = (progress) => ({
   type : UPLOAD_ATT_SHEET_PROGRESS,
   progress, 
})



export const cleanAttSheetData = () => ({
   type : CLEAN_UPLOAD_ATT_SHEET_DATA,
 })
 

//  Upload Rejection Summary


export const addRejSummaryBegin = () => ({
   type : ADD_REJ_SUMMARY_LOADING
})

export const addRejSummarySuccess = (data) => ({
   type : ADD_REJ_SUMMARY_SUCCESS,
   data ,
})

export const addRejSummaryFailure = (error) => ({
   type : ADD_REJ_SUMMARY_FAILURE,
   error, 
})


export const cleanAddRejSummaryData = () => ({
   type : CLEAN_ADD_REJ_SUMMARY_DATA,
 })
 


export const uploadRejSummaryBegin = () => ({
   type : UPLOAD_REJ_SUMMARY_LOADING
})

export const uploadRejSummarySuccess = (data) => ({
   type : UPLOAD_REJ_SUMMARY_SUCCESS,
   data ,
})

export const uploadRejSummaryFailure = (error) => ({
   type : UPLOAD_REJ_SUMMARY_FAILURE,
   error, 
})

export const uploadRejSummaryProgress = (progress) => ({
   type : UPLOAD_REJ_SUMMARY_PROGRESS,
   progress, 
})



export const cleanRejSummaryData = () => ({
   type : CLEAN_UPLOAD_REJ_SUMMARY_DATA,
 })
 

 

//  Upload Response Sheet


export const uploadResSheetBegin = () => ({
   type : UPLOAD_RES_SHEET_LOADING
})

export const uploadResSheetSuccess = (data) => ({
   type : UPLOAD_RES_SHEET_SUCCESS,
   data ,
})

export const uploadResSheetFailure = (error) => ({
   type : UPLOAD_RES_SHEET_FAILURE,
   error, 
})

export const uploadResSheetProgress = (progress) => ({
   type : UPLOAD_RES_SHEET_PROGRESS,
   progress, 
})



export const cleanResSheetData = () => ({
   type : CLEAN_UPLOAD_RES_SHEET_DATA,
 })
 

 

//  Upload Rejection Summary


export const uploadCatelinaBegin = () => ({
   type : UPLOAD_CATELINA_LOADING
})

export const uploadCatelinaSuccess = (data) => ({
   type : UPLOAD_CATELINA_SUCCESS,
   data ,
})

export const uploadCatelinaFailure = (error) => ({
   type : UPLOAD_CATELINA_FAILURE,
   error, 
})

export const uploadCatelinaProgress = (progress) => ({
   type : UPLOAD_CATELINA_PROGRESS,
   progress, 
})



export const cleanCatelinaData = () => ({
   type : CLEAN_UPLOAD_CATELINA_DATA,
 })
 



// CI Profile Management

export const changeCiPassBegin = () => ({
   type : CHANGE_CI_PASS_LOADING
})

export const changeCiPassSuccess = (data) => ({
   type : CHANGE_CI_PASS_SUCCESS,
   data ,
})

export const changeCiPassFailure = (error) => ({
   type : CHANGE_CI_PASS_FAILURE,
   error, 
})



export const changeCiProfilePicBegin = () => ({
   type : CHANGE_CI_PROFILE_PIC_LOADING
})

export const changeCiProfilePicSuccess = (data) => ({
   type : CHANGE_CI_PROFILE_PIC_SUCCESS,
   data ,
})

export const changeCiProfilePicFailure = (error) => ({
   type : CHANGE_CI_PROFILE_PIC_FAILURE,
   error, 
})



export const cleanCiProfileData = () => ({
   type : CLEAN_CI_PROFILE_DATA,
 })
 