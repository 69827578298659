import * as XLSX from 'xlsx';
import { tostE } from './Toast';


  export const exportToExcel = (column, listData) => {

    if(Object.keys(listData).length){
      // var column  = [{field :"name", val :"myname"} , {field :"mobile",val :"abc"} , {field : "email", val :"pqe"} , {field:"permit_role" , val :"pd"}]
    
      const sheetList = Object.keys(listData);
      var wb = XLSX.utils.book_new();
      // var temp  = Object.keys(listData[0])
    
      // var column = []
    
    
      // for(let i = 0 ; i < temp.length ; i++)
      // {
      //   console.log(temp[i])
      //       column.push({field : temp[i]})    
      // }
    
      // console.log(column)
    
      const columnInfo = column.reduce(
        (acc, column) => {
            console.log(acc)
              const headerLabel = column.val;
              acc.header.push(headerLabel);
              acc.map[column.field] = headerLabel;
              return acc;
          },
          { map: {}, header: [] }
      );

      sheetList.map((item, i)=>{
        const mappedData = listData[item].map(row =>
          Object.entries(row).reduce((acc, [key, value]) => {
              if (columnInfo.map[key]) {
                  acc[columnInfo.map[key]] = value;
              }
              return acc;
          }, {})          
        );
        var ws = XLSX.utils.json_to_sheet(mappedData, {
          header: columnInfo.header, 
        });
        XLSX.utils.book_append_sheet(wb, ws, `${item}`);

      })
    
      
    
      
    
      
    
      
    
      // /* generate an XLSX file and triggers the browser to generate the download*/
      XLSX.writeFile(wb, `Data.xlsx`);
    }else{
      tostE("There is no data to export!!")
    }
  };
  
