import React, {useEffect, useState} from 'react';
import { filter } from 'lodash';
import {Link as RouterLink,useNavigate, useLocation} from 'react-router-dom'
import moment from 'moment';
import plusFill from '@iconify/icons-eva/plus-fill';

// material
import { 
  Stack,
  Button,
  Box, 
  Grid, 
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

// Icons
import { Icon } from '@iconify/react';


// components
import Page from '../../../components/Page';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';



// API

import { getAllCenter} from '../../../Actions/AdminActions/Center'



// ----------------------------------------------------------------------

const TABLE_HEAD = [

  { id: 'centre_name', label: 'Center Name', alignRight: false },
  { id: 'spoc_name', label: 'Spoc Name', alignRight: false },
  { id: 'spoc_mobile', label: 'Spoc Mobile', alignRight: false },
  { id: 'spoc_email', label: 'Spoc Email', alignRight: false },
  { id: 'spoc_address', label: 'Address', alignRight: false },  
  { id: 'is_busy', label: 'Status', alignRight: false },  
  
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.centre_name[0].toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



const CenterScreen = ()=> {
  const theme = useTheme();
  const {state} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('centre_code');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const listData = useSelector(state => state.ExamCenter.allData && 
    Array.isArray(state.ExamCenter.allData) && 
    state.ExamCenter.allData.length ? state.ExamCenter.allData : [])


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const filteredUsers = applySortFilter(listData, getComparator(order, "center_name"), filterName);

  const isUserNotFound = filteredUsers.length === 0;


   

  useEffect(() =>{
    dispatch(getAllCenter())
    
    

  }, [])

const handleNavigateCenterCi = (data) =>{
  navigate('/admin/center/ci/detail/'+data.centre_code, {state:data})
}

const addCenter = () =>{
  navigate('/center/add')
}

  return (
    <Page title="All Center">
      <Container maxWidth="xl">
        {/* <Grid item xs={12}>
          <Breadcrumbs>   
            <Link color="inherit" href="/admin/exam">
              Exam Profile
            </Link>         
            <Typography sx={{ color: 'text.primary' }}>Exam Center</Typography>
          </Breadcrumbs>
        </Grid>
        <Divider /> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
              Center Details
          </Typography>
          <Button
          variant="contained"
          onClick = {() => addCenter()}
          startIcon={<Icon icon={plusFill} />}
          >
              Add Centers
          </Button>
        </Stack>
        <Card className='cardBorder'>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={listData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => {
                      const {admin_id, centre_id, centre_name, spoc_name, spoc_mobile, spoc_email,pincode, spoc_address, is_busy} = row;
                      const isItemSelected = selected.indexOf(centre_id) !== -1;

                      return (
                        <TableRow
                          hover
                          key={centre_id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          className="tableRowBottomBorder"
                        >
                          
                          
                          <TableCell align="left">{centre_name}</TableCell>
                          <TableCell align="left">{spoc_name}</TableCell>
                          <TableCell align="left">{spoc_mobile}</TableCell>
                          <TableCell align="left">{spoc_email}</TableCell>
                          <TableCell align="left">{spoc_address}</TableCell>
                          <TableCell align="left" style={{color:is_busy ? 'red' : 'green'}}>{is_busy ? "Busy":"Free" }</TableCell>
                          
                          
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={listData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}


export default CenterScreen;