import React, {useEffect, useState} from 'react';


// material
import { 
  Box, 
  Grid, 
  Stack,
  TextField,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  MenuItem,
  Select,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  CardContent
} from '@material-ui/core';
// components
import Page from '../../../components/Page';
import TotalCenterDataCard from './components/TotalCenterData';
import ActiveCenterDataCard from './components/ActiveCenterData';
import SlotList from './components/slotList';
import CiDataCard from './components/CiData';
import LastActivity from './components/LastActivity';
import UploadedData from './components/UploadData';

import { useSelector , useDispatch  } from 'react-redux';
import { Icon } from '@iconify/react';

// material
import {useTheme } from '@material-ui/core/styles';


// API
import {getDashboard} from '../../../Actions/Dashboard';
import {getExam} from 'src/Actions/AdminActions/Exam';
import {getAvlExamSlot} from 'src/Actions/AdminActions/Slots';

import { setSlot } from 'src/Actions/ActionCreator';

export default function DashboardApp() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const UserData = useSelector(state => state.login.data); 
  const primary_id = useSelector(state=>state.login.data.exam_id);
  const selected_slot = useSelector(state => state.Dashboard.slot && state.Dashboard.slot!=="" 
  && state.Dashboard.slot!==null && state.Dashboard.slot!==undefined ? state.Dashboard.slot : null)

  const ExamData = useSelector(state => state.Exam.getData && 
    Array.isArray(state.Exam.getData) && 
    state.Exam.getData.length ? (state.Exam.getData) : []);


  
  const SlotData = useSelector(state => state.Slots.avlData && 
      Array.isArray(state.Slots.avlData) && 
      state.Slots.avlData.length ? (state.Slots.avlData) : []);
  
    const [refreshTime, setRefreshTime] = useState(300)

  const [values, setValues] = useState({
    exam_id:primary_id,        
    slot_id:selected_slot,        
   })



   const handleChangeExam = (event) => {
    const e_id = event.target.value;

    setValues({
        ...values,
        ['exam_id']:e_id
    })
    dispatch(getDashboard(e_id))
    dispatch(getAvlExamSlot(e_id))
}
   
  const handleChangeSlot = (event) => {
    const e_id = values.exam_id;
    const s_id = event.target.value;

    setValues({
        ...values,
        ['slot_id']:s_id
    })
    dispatch(setSlot(s_id))
    dispatch(getDashboard(e_id, s_id))
}


const getData = async (ex_id, sl_id) => {
  try {
    dispatch(getDashboard(ex_id , sl_id))
    
  } catch (e) {
    console.log(e);
  }
};





  useEffect(() =>{
    dispatch(getExam())
    dispatch(getAvlExamSlot(values.exam_id))
    dispatch(getDashboard(values.exam_id, selected_slot))
    
    const intervalCall = setInterval(() => {
      getData(values.exam_id, values.slot_id);      
    }, refreshTime*1000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
    

  }, [values.exam_id, refreshTime, selected_slot])



  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi {UserData.name}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item md={6} sm={12}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">Select Exam</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={values.exam_id}
                    label="Country"
                    name="exam_id"
                    onChange={handleChangeExam}
                >
                    {ExamData.map((data , i)=>
                    <MenuItem key={i} value={data.exam_id}>{data.exam_name}</MenuItem>
                    )}        

                </Select>
              </FormControl>
          </Grid>
          <Grid item md={3} sm={12}></Grid>     
          <Grid item md={3} sm={12}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Refresh Rate</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={refreshTime}
                    label="Refresh Rate"
                    name="exam_id"
                    onChange={(e) => {setRefreshTime(e.target.value)}}
                >
                    
                  <MenuItem value={10}>10 sec</MenuItem>
                  <MenuItem value={60}>1 min</MenuItem>
                  <MenuItem value={300}>5 min</MenuItem>
                  <MenuItem value={600}>10 min</MenuItem>
                    

                </Select>
              </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} sm={4} md={4}>
            <TotalCenterDataCard />
          </Grid>                      
          <Grid item xs={12} sm={4} md={4}>
            <ActiveCenterDataCard />
          </Grid>                      
          <Grid item xs={12} sm={4} md={4}>
            <CiDataCard />
          </Grid>                      
          <Grid item xs={12} md={6} lg={8}>
            <LastActivity />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <SlotList />
          </Grid>            
          <Grid item xs={12} md={12} lg={12}>
            <Card className='dashboardAdminUploadedDataCard'>
              <CardHeader title="Uploaded Data Status" subheader="Select Slot To View Data" sx={{ mb: 3 }} />  
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} sm={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Select Slot</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={values.slot_id}
                            label="Select Slot"
                            name="exam_id"
                            onChange={handleChangeSlot}
                        >
                            {SlotData.map((data , i)=>
                            <MenuItem key={i} value={data.slot_id}>{data.slot_name}</MenuItem>
                            )}        

                        </Select>
                      </FormControl>
                  </Grid>         
                  
                </Grid>
                <UploadedData />

              </CardContent>
            </Card>
          </Grid>            
        </Grid>

      </Container>
    </Page>
  );
}
