// material
import { Card, Typography, CardHeader, CardContent } from '@material-ui/core';
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator
} from '@material-ui/lab';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

function SlotListItem({ item, isLast }) {
    const { slot_id, slot_name } = item;
    return (
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            color={
              (slot_id%4 === 0 && 'primary') ||
              (slot_id%4 === 1 && 'success') ||
              (slot_id%4 === 2 && 'info') ||
              (slot_id%4 === 3 && 'warning') ||
              'error'
            }
          />
          {isLast ? null : <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Typography variant="subtitle2">{slot_name}</Typography>        
        </TimelineContent>
      </TimelineItem>
    );
  }

export default function SlotList() {
  const slotData = useSelector(state => state.login.data.slot_details);

  return (
    <Card
      sx={{
        '& .MuiTimelineItem-missingOppositeContent:before': {
          display: 'none'
        },        
      }}
      className='dashBoardCardBorder'
    >
      <CardHeader title="Available Slots" />
      <CardContent>
        <Timeline>
          {slotData.map((item, index) => (
            <SlotListItem key={item.slot_id} item={item} isLast={index === slotData.length - 1} />
          ))}
        </Timeline>
      </CardContent>
    
    </Card>
  );
}
