import {ADD_EXAM_SLOT_LOADING , ADD_EXAM_SLOT_SUCCESS , ADD_EXAM_SLOT_FAILURE , CLEAN_EXAM_SLOT_DATA} from '../../Actions/Types';
import {GET_CENTER_SLOT_LOADING , GET_CENTER_SLOT_SUCCESS , GET_CENTER_SLOT_FAILURE} from '../../Actions/Types';
import {GET_AVL_SLOT_LOADING , GET_AVL_SLOT_SUCCESS , GET_AVL_SLOT_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const Slots = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            getData:[{}],
            getError:{},
            avlData:[{}],
            avlError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                getData:[{}],
                getError:{},
                avlData:[{}],
                avlError:{},
            }
    
    
        case CLEAN_EXAM_SLOT_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                
            }
    
        
        case ADD_EXAM_SLOT_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                
            }
        case ADD_EXAM_SLOT_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,                
            }
        case ADD_EXAM_SLOT_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],                
            }

        case GET_CENTER_SLOT_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_CENTER_SLOT_SUCCESS:
            return {
                ...state,
                loading:false,
                getError:{},
                getData:action.data,
            }
        case GET_CENTER_SLOT_FAILURE:
            return {
                ...state,
                loading:false,
                getError:action.error,
                getData:{},
            }   
        
        case GET_AVL_SLOT_LOADING:
            return {
                ...state,
                loading:true,
                avlError: {},
                avlData : [{}],
                
            }
        case GET_AVL_SLOT_SUCCESS:
            return {
                ...state,
                loading:false,
                avlError:{},
                avlData:action.data,
            }
        case GET_AVL_SLOT_FAILURE:
            return {
                ...state,
                loading:false,
                avlError:action.error,
                avlData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default Slots;
    