import moment from 'moment';
// material
import {     
    TableRow,
    TableBody,
    TableCell,
    TableHead,
    Table,
    TableContainer,
    Typography
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CheckIcon from '@iconify/icons-ant-design/check-outline';
import { Icon } from '@iconify/react';


// Component
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';

import {useSelector} from 'react-redux';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;


export default function UploadedData() {
    const theme = useTheme();
   
    const UploadData = useSelector(state=> state.BackupStatus.data &&
        Array.isArray(state.BackupStatus.data) && state.BackupStatus.data.length ?
        state.BackupStatus.data : [])

  return (
        <TableContainer className='dashboardAdminUploadedDataTable'>
         <Scrollbar>
            <Table size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell className='dashboardAdminUploadedDataCell'>Slot</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Biometric</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Attendance Sheet</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Primary Server</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Secondary Server</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>PXE Server</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Response Sheet</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Catelina Primary</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Catelina Secondary</TableCell>                
                        <TableCell className='dashboardAdminUploadedDataCell'>Rejection Summary</TableCell>                
                        
                    </TableRow>
                </TableHead>
                {UploadData.length ?
                <TableBody>
                {UploadData.map((row, i) => (
                    Object.keys(row) && Object.keys(row).length ? 
                    <TableRow key={i}>
                        <TableCell className='dashboardAdminUploadedDataCell'>{row.slot_name}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.biometric[0] ? "#54D62C" : "#FF4842"}}>{row.biometric[1]? getIcon(CheckIcon) : ""}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.att_sheet[0] ? "#54D62C" : "#FF4842"}}>{row.att_sheet[1]? getIcon(CheckIcon) : ""}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.primary_bkp[0] ? "#54D62C" : "#FF4842"}}>{row.primary_bkp[1]? getIcon(CheckIcon) : ""}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.secondary_bkp[0] ? "#54D62C" : "#FF4842"}}>{row.secondary_bkp[1]? getIcon(CheckIcon) : ""}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.pxe[0] ? "#54D62C" : "#FF4842"}}>{row.pxe[1]? getIcon(CheckIcon) : ""}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.rsp_sheet[0] ? "#54D62C" : "#FF4842"}}>{row.rsp_sheet[1]? getIcon(CheckIcon) : ""}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.cat_pri[0] ? "#54D62C" : "#FF4842"}}>{row.cat_pri[1]? getIcon(CheckIcon) : ""}</TableCell>
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.cat_sec[0] ? "#54D62C" : "#FF4842"}}>{row.cat_sec[1]? getIcon(CheckIcon) : ""}</TableCell>                                                      
                        <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.rej_summary[0] ? "#54D62C" : "#FF4842"}}>{row.rej_summary[1]? getIcon(CheckIcon) : ""}</TableCell>                                                      
                    </TableRow>
                    :<></>
                ))}
                </TableBody>
                :
                <TableBody>
                    <TableRow>
                    <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                        <Typography gutterBottom align="center" variant="subtitle1">
                            Not found
                        </Typography>
                        <Typography variant="body2" align="center">
                            No results found. 
                        </Typography>
                        
                    </TableCell>
                    </TableRow>
                </TableBody>
                }
            </Table>
         </Scrollbar> 
        </TableContainer>
      
  );
}
