import React, {useEffect, useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';

// material
import { 
  Box, 
  Stack,
  Alert,
  AlertTitle,
  Container, 
  Typography, 
  Card,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Dialog, 
  DialogTitle, 
  DialogActions, 
  DialogContent, 
  DialogContentText,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import {useTheme} from '@material-ui/styles';
// components
import Page from '../../../components/Page';
import {tostS  , tostW , tostE } from '../../../config/Toast'
import Label from 'src/components/Label';


import { useSelector, useDispatch } from 'react-redux';

// API

import { uploadServerPrimary, uploadServerSecondary, uploadServerPxe } from 'src/Actions/CiActions/Server';
import {cleanServerData, cleanUploadedData} from 'src/Actions/ActionCreator'
import {getUploadedData, reqUploadData} from 'src/Actions/CiActions/uploadedData'

export default function DashboardApp() { 
   const navigate = useNavigate();    
   const dispatch = useDispatch();
   const theme = useTheme();
   const UserData = useSelector(state => state.login.data); 
   const AllowedFileExt = ['zip', 'rar'];
   const [values, setValues] = useState({
      slot_id:"",
      content_type:"",
      ec_id:UserData.ec_id,
      exam_id:UserData.exam_id,
      ci_id:UserData.ci_id,      
      img:""
   })

   const [upload, setUpload] = useState({
     ec_id:UserData.ec_id,
     content_type:"Primary_Server"
   })

   const SlotData = useSelector(state => state.login.data && state.login.data.slot_details && Array.isArray(state.login.data.slot_details) && state.login.data.slot_details.length ? state.login.data.slot_details : [])
   const uploadLoading = useSelector(state => state.ServerBackup && state.ServerBackup.loading ? state.ServerBackup.loading : false);
   const uploadMsg = useSelector(state => state.ServerBackup && state.ServerBackup.data && state.ServerBackup.data.message ? state.ServerBackup.data.message : null )
   const uploadErr = useSelector(state => state.ServerBackup && state.ServerBackup.error && state.ServerBackup.error.message ? state.ServerBackup.error.message : null )
   const uploadProgress = useSelector(state => state.ServerBackup && state.ServerBackup.progress ? state.ServerBackup.progress : null )

   const reqUploadMsg = useSelector(state => state.UploadedData.reqData && state.UploadedData.reqData.status && state.UploadedData.reqData.status.message ? state.UploadedData.reqData.status.message : null )
   const reqUploadErr = useSelector(state => state.UploadedData && state.UploadedData.reqError && state.UploadedData.reqError.message ? state.UploadedData.reqError.message : null )


   const uploadedData = useSelector(state => state.UploadedData.data && Array.isArray(state.UploadedData.data) && state.UploadedData.data.length ? state.UploadedData.data : [])


    const handleChangeSlots = (event) => {
      setValues({
        ...values,
        ['slot_id']:event.target.value
      })
    }

    const handleChangeServer = (event) => {
      setValues({
        ...values,
        ['content_type']:event.target.value
      })
    }
   
    const handleChangeServerUpload = (event) => {
      const c_type = event.target.value;
      setUpload({
        ...upload,
        ['content_type']:c_type
      })
    dispatch(getUploadedData(upload.ec_id, c_type))

    }

  useEffect(() =>{
    dispatch(getUploadedData(upload.ec_id, upload.content_type))

    let x  = new Promise((resolve ,reject)=>{
      if(uploadMsg !==null && typeof(uploadMsg) === 'string')
      {
        var u = new Promise((resolve , reject)=>{
          tostS(uploadMsg)
          resolve()
        })
        u.then(()=>{
          window.location.reload()
        })
        
      }
      if(uploadErr !==null && typeof(uploadErr) === 'string')
      {
        tostE(uploadErr)
        

      }
      if(reqUploadMsg !==null && typeof(reqUploadMsg) === 'string')
      {
        tostS(reqUploadMsg)

      }
      if(reqUploadErr !==null && typeof(reqUploadErr) === 'string')
      {
        tostE(reqUploadErr)
      }
      resolve()

    })
    x.then(()=>{
      dispatch(cleanServerData())
      dispatch(cleanUploadedData())

    })

  }, [uploadMsg, uploadErr, reqUploadMsg, reqUploadErr])

  const handleSubmit = () => {
     console.log(values);
     if(values.slot_id==="" || values.slot_id===null || values.slot_id===undefined) {
       tostW("Please select Slot!!");
     }else if(values.content_type==="" || values.content_type===null || values.content_type===undefined){
      tostW("Please Select Server Type!!")

     }else if(values.img==="" || values.img===null || values.img===undefined){
      tostW("Please Select File!!");
    }else if(values.image1==="" || values.image1===null || values.image1===undefined){
      tostW("Please Select File!!");
    }else{
       if(values.content_type==="Primary_Server"){
         dispatch(uploadServerPrimary(values))
       }
       if(values.content_type==="Secondary_Server"){
         dispatch(uploadServerSecondary(values))
       }
       if(values.content_type==="Pxe_Server"){
         dispatch(uploadServerPxe(values))
       }
       setValues({
         ...values,
         ["slot_id"]:"",
         ["content_type"]:"",
         ["img"]:"",    
         ["image1"]:""     
       })
       delete values.image1;
     }
  }


       /********* File Handling   ************** */
       const hiddenFileInput = useRef(null);
  
       const handleClick = event => {
         hiddenFileInput.current.click();
       };
       const handleChangeimg = event => {
        if(event.target.files.lenght!==0 && event.target.files[0]){
          const fileUploaded = event.target.files[0];
          const file_ext = fileUploaded.name.split(".")
          
          const is_allowed  = AllowedFileExt.includes(file_ext[file_ext.length -1]);

          if(is_allowed){
            setValues({...values , ['image1']:fileUploaded , ['img']:URL.createObjectURL(fileUploaded)})
            console.log(":::::::::::::::::::::::::")
          }else{
            tostE("Only zip or rar files are Allowed!!")
          }

        }
     
       };
       
       /********* File Handling   ************** */
       const [open, setOpen] = useState({
        show:false,
        id:""
      });
        
      const handleOpen = (data) => {
        setOpen({
          ...open,
          ['show']:true,
          ['id']:data.up_id
        });
      } 
    
      const handleClickSubmit = () => {
        dispatch(reqUploadData({"up_id" : open.id}))
        setOpen({
          ...open,
          ['show']:false,
          ['id']:""
        });
      };
    
      const handleClose = () => {
        setOpen({
          ...open,
          ['show']:false,
          ['id']:""
        });
      };
    

  return (
    <Page title="Server Backup">
      <Dialog open={open.show} onClose={handleClose}>
        <DialogTitle>Request to Re upload Attendance Sheet</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure You want to Re upload this BioMetric Data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClickSubmit} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    {uploadLoading && uploadProgress!==null
    ?
      <Stack spacing={2}>
        <Alert severity="error">
          <AlertTitle>Please Do Not Refresh The Page</AlertTitle>
          This may result to — <strong>Corruption In Your Data.</strong>
        </Alert>
      </Stack>
    
    :<></>}
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Upload Server Backup Data</Typography>
        </Box>
        
        <Grid container spacing={3}>
          <Grid item md={6} xs={12} style={{marginBottom:"25px"}}>
          <FormControl fullWidth disabled={uploadLoading}>
            <InputLabel id="demo-simple-select-label">Select Slot</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.slot_id}
              label="Select Slot"
              name="slot_id"
              onChange={handleChangeSlots}
            >
              {SlotData.map((data , i)=>
                <MenuItem key={i} value={data.slot_id}>{data.slot_name}</MenuItem>
              )}        

            </Select>
          </FormControl>
          </Grid>  
          <Grid item md={6} xs={12} style={{marginBottom:"25px"}}>
          <FormControl fullWidth disabled={uploadLoading}>
            <InputLabel id="demo-simple-select-label">Select Server</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.content_type}
              label="Select Server"
              name="content_type"
              onChange={handleChangeServer}
            >
              
                <MenuItem value="Primary_Server">Primary Server</MenuItem>
                <MenuItem value="Secondary_Server">Secondary Server</MenuItem>
                <MenuItem value="Pxe_Server">PXE Server</MenuItem>

            </Select>
          </FormControl>
          </Grid>  

        </Grid>
         <Card className='uploadFileCard'>
            <CardHeader title="Upload Server Data zip" />
            {uploadLoading ?
              <LinearProgress variant="buffer" value={uploadProgress} valueBuffer={uploadProgress+5} color="info" />
              :
            <Divider />
            }
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              m={6}
              textAlign='center'
            >
              
              <Typography
                color={theme.palette.primary.light}
                gutterBottom
                variant="h3"
              >
              {values.image1 ? values.image1.name : uploadProgress!==null ? `${uploadProgress} %`:  "No File Selected"}
                

                <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChangeimg}
              style={{display: 'none'}} 
            />
              <LoadingButton
                color="primary"
                loading={uploadLoading}
                fullWidth
                variant="contained"
                onClick={handleClick}
              >
                Select zip
              </LoadingButton>
              </Typography>              
            </Box>
            <Divider />
            <CardActions>
               <LoadingButton
                  color="primary"
                  fullWidth
                  loading={uploadLoading}
                  variant="outlined"
                  onClick={handleSubmit}
               >
                 Upload Data
               </LoadingButton>
            
            </CardActions>
            </Card>

            <Card className="uploadTableCard">
            <CardHeader title="Uploaded Data Details" subheader="Change server type to view different server uploaded data." />
            <Grid container spacing={3}>
              <Grid item md={6} xs={12} style={{margin:"25px"}}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select Server</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={upload.content_type}
                    label="Select Server"
                    name="content_type"
                    onChange={handleChangeServerUpload}
                  >
                    
                      <MenuItem value="Primary_Server">Primary Server</MenuItem>
                      <MenuItem value="Secondary_Server">Secondary Server</MenuItem>
                      <MenuItem value="Pxe_Server">PXE Server</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <TableContainer>

              <Table>
                <TableHead className='uploadTableHead'>
                  <TableRow>
                    <TableCell>Center Code</TableCell>
                    <TableCell>Slot</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Request To Re-Upload</TableCell>
                  </TableRow>
                </TableHead>
                {uploadedData.length ? 
                  <TableBody>
                    {uploadedData.map((row, i) => (
                      <TableRow key={i} style={{borderBottom: i<uploadedData.length-1 ? `1px solid ${theme.palette.primary.lighter}` : null}}>                    
                        <TableCell>{row.centre_code}</TableCell>
                        <TableCell>{row.slot_name}</TableCell>
                        <TableCell>{row.ci_name}</TableCell>
                        <TableCell>{row.file_name}</TableCell>
                        
                        <TableCell>
                        {row.reupload_req && row.can_reupload ? 
                          <Label
                            variant='filled'
                            color='info'
                          >
                          Request Accepted
                          </Label>
                          :null
                        }
                        {row.reupload_req && !row.can_reupload ? 
                          <Label
                            variant='filled'
                            color='warning'
                          >
                          Request In Progress
                          </Label>
                          :null
                        }
                        {!row.reupload_req && !row.can_reupload ? 
                          <Button variant="outlined" onClick={() => handleOpen(row)}>Reupload</Button>
                          :null
                        }
                          
                            
                          
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                :
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          Not found
                        </Typography>
                        <Typography variant="body2" align="center">
                          No results found. 
                        </Typography>
                      
                    </TableCell>
                  </TableRow>
                </TableBody>
                }
              </Table>
            </TableContainer>
          </Card>  
      </Container>
    </Page>
  );
}
