import React, {useEffect, useState} from 'react';

import {Provider} from 'react-redux';
import {ToastContainer} from 'react-toastify';

import history from './config/history';

import store from './Store/store';
// routes
import Routing from './Routes/routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import Load from './Load';
import NoInternet from 'src/components/NoInternet'
import Settings from 'src/components/settings';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import RtlLayout from './components/RtlLayout';

// ----------------------------------------------------------------------


const App = () => {
  
  store.subscribe(() => {
    localStorage.setItem('reduxStore', JSON.stringify(store.getState()));
   
  })
  
  const [isOnline, set_isOnline] = useState(true);
  let interval = null;
 
  const InternetErrMessagenger = () => set_isOnline(navigator.onLine===true); 
 

  useEffect(() =>{
    
    interval = setInterval(InternetErrMessagenger, 6000); 
    return ()=>{
       clearInterval(interval) 
    }
  }, [])



  return (
    <Provider store={store} history={history}>
      <ThemeConfig>  
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>

              {isOnline !== true ? 
                <NoInternet />
                :
                <>
                <Settings />
                <ScrollToTop />      
                <ToastContainer />        
                <Load />
                <Routing />
                </>
              }
            </NotistackProvider>   
            </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>   
      </ThemeConfig>
    </Provider>
    
  );
}


export default App;
