import {ADD_CI_LOADING , ADD_CI_SUCCESS , ADD_CI_FAILURE , CLEAN_EXAM_CENTER_DATA} from '../../Actions/Types';
import {GET_ALL_CI_LOADING , GET_ALL_CI_SUCCESS , GET_ALL_CI_FAILURE} from '../../Actions/Types';
import {GET_CENTER_CI_LOADING , GET_CENTER_CI_SUCCESS , GET_CENTER_CI_FAILURE} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const CiDetail = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            allGetData:[{}],
            allGetError:{},
            
            centerGetData:[{}],
            centerGetError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
               ...state,
               data: [{}],
               loading:false,
               error:{},
               allGetData:[{}],
               allGetError:{},
               centerGetData:[{}],
               centerGetError:{},
            }
    
    
        case CLEAN_EXAM_CENTER_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                
            }
    
        
        case ADD_CI_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                
            }
        case ADD_CI_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,                
            }
        case ADD_CI_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],                
            }

        case GET_CENTER_CI_LOADING:
            return {
                ...state,
                loading:true,
                centerGetError: {},
                centerGetData : [{}],
                
            }
        case GET_CENTER_CI_SUCCESS:
            return {
                ...state,
                loading:false,
                centerGetError:{},
                centerGetData:action.data,
            }
        case GET_CENTER_CI_FAILURE:
            return {
                ...state,
                loading:false,
                centerGetError:action.error,
                centerGetData:{},
            }   
        
        case GET_ALL_CI_LOADING:
            return {
                ...state,
                loading:true,
                allGetError: {},
                allGetData : [{}],
                
            }
        case GET_ALL_CI_SUCCESS:
            return {
                ...state,
                loading:false,
                allGetError:{},
                allGetData:action.data,
            }
        case GET_ALL_CI_FAILURE:
            return {
                ...state,
                loading:false,
                allGetError:action.error,
                allGetData:{},
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default CiDetail;
    