import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/Layout';
import DashboardApp from '../pages/CiScreen/Dashboard/DashboardApp';
import ProfileScreen from '../pages/CiScreen/UserProfile';


import BiometricScreen from '../pages/CiScreen/Backup/Biometric';
import AnnexureScreen from '../pages/CiScreen/Backup/Annexure';
import AttendanceSheetScreen from '../pages/CiScreen/Backup/AttendanceSheet';
import ServerScreen from '../pages/CiScreen/Backup/Server';
import CatelinaScreen from '../pages/CiScreen/Backup/Catelina';
import ResponseSheetScreen from '../pages/CiScreen/Backup/ResponseSheet';
import RejectionSummaryScreen from '../pages/CiScreen/RejectionSummary/RejectionSummary';
import RejectionSummaryAddScreen from '../pages/CiScreen/RejectionSummary/AddSummary';
import DownloadScreen from '../pages/CiScreen/Download/DownloadData';
import UploadAppendix from 'src/pages/CiScreen/UploadAppendix/AddAppendix';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        
        { path: '/', element: <Navigate to="/dashboard" replace /> },
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'profile', element: <ProfileScreen /> },
        { path: 'biometric', element: <BiometricScreen /> },
        { path: 'attendance-sheet', element: <AttendanceSheetScreen /> },
        { path: 'annexure', element: <AnnexureScreen /> },
        { path: 'server-data', element: <ServerScreen /> },
        { path: 'catelina-backup', element: <CatelinaScreen /> },
        { path: 'response-sheet', element: <ResponseSheetScreen /> },
        { path: 'rejection-summary', element: <RejectionSummaryScreen /> },
        { path: 'rejection-summary/add', element: <RejectionSummaryAddScreen /> },
        { path: 'upload-appendex', element: <UploadAppendix /> },
        { path: 'downloads', element: <DownloadScreen /> },
        
        { path: '*', element: <Navigate to="/dashboard" replace /> },
      ]
    },
    

    { path: '*', element: <Navigate to="/dashboard" replace /> }
  ]);
}
