import {GET_MASTER_BACKUP_LOADING , GET_MASTER_BACKUP_SUCCESS , GET_MASTER_BACKUP_FAILURE} from 'src/Actions/Types';
import {CLEAN_ALL_DATA} from 'src/Actions/Types'

const PostExam = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,            
            getData:[{}],
            getError:{},
        }
    }

    switch(action.type) {
        
        case CLEAN_ALL_DATA:
            return {
                ...state,
                loading:false,
                getError: {},
                getData : {},
            }
                
        case GET_MASTER_BACKUP_LOADING:
            return {
                ...state,
                loading:true,
                getError: {},
                getData : [{}],
                
            }
        case GET_MASTER_BACKUP_SUCCESS:
            return {
                ...state,
                loading:false,                
                getData:action.data,
                getError:{}
            }
        case GET_MASTER_BACKUP_FAILURE:
            return {
                ...state,
                loading:false,                
                getData:{},
                getError:action.error,
            }   
        

        default:
        return state
    
    }
    }
    
    
    
export default PostExam;
    