import React, {useEffect, useState, useRef} from 'react';

// material
import { 
  Box, 
  Stack,
  Alert,
  AlertTitle,
  Container, 
  Typography, 
  Card,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
  Dialog, 
  DialogTitle, 
  DialogActions, 
  DialogContent, 
  DialogContentText,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';
// components
import Page from '../../../components/Page';
import {tostS  , tostW , tostE } from '../../../config/Toast'
import Label from 'src/components/Label';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// API

import { uploadAnnexure } from 'src/Actions/CiActions/Annexure';
import {cleanAnnexureData, cleanUploadedData} from 'src/Actions/ActionCreator'

import {getUploadedData, reqUploadData} from 'src/Actions/CiActions/uploadedData'



export default function DashboardApp() {     
   const dispatch = useDispatch();
   const theme = useTheme();
   const navigate = useNavigate();
   const UserData = useSelector(state => state.login.data); 
   const AllowedFileExt = ['pdf'];

   const selected_slot = useSelector(state => state.login.data && state.login.data.slot_details && 
    Array.isArray(state.login.data.slot_details) && state.login.data.slot_details.length && state.login.data.slot_details[0]
    ? state.login.data.slot_details[0].slot_id : null);

   const [values, setValues] = useState({
      slot_id:selected_slot,
      content_type:"Annexures",
      doc_name:"",
      ec_id:UserData.ec_id,
      exam_id:UserData.exam_id,
      ci_id:UserData.ci_id,      
      img:""
   })

   const uploadLoading = useSelector(state => state.Annexure && state.Annexure.loading ? state.Annexure.loading : false);
   const uploadMsg = useSelector(state => state.Annexure && state.Annexure.data && state.Annexure.data.message ? state.Annexure.data.message : null )
   const uploadErr = useSelector(state => state.Annexure && state.Annexure.error && state.Annexure.error.message ? state.Annexure.error.message : null )
   const uploadProgress = useSelector(state => state.Annexure && state.Annexure.progress ? state.Annexure.progress : null )

   const reqUploadMsg = useSelector(state => state.UploadedData.reqData && state.UploadedData.reqData.status && state.UploadedData.reqData.status.message ? state.UploadedData.reqData.status.message : null )
   const reqUploadErr = useSelector(state => state.UploadedData && state.UploadedData.reqError && state.UploadedData.reqError.message ? state.UploadedData.reqError.message : null )


   const uploadedData = useSelector(state => state.UploadedData.data && Array.isArray(state.UploadedData.data) && state.UploadedData.data.length ? state.UploadedData.data : [])
    
    
    const handleChangeDoc = (event) => {
      setValues({
        ...values,
        ['doc_name']:event.target.value
      })
    }

  useEffect(() =>{
    dispatch(getUploadedData(UserData.ec_id, "Annexures"))

    let x  = new Promise((resolve ,reject)=>{
      if(uploadMsg !==null && typeof(uploadMsg) === 'string')
      {
        var u = new Promise((resolve , reject)=>{
          tostS(uploadMsg)
          resolve()
        })
        u.then(()=>{
          // window.location.reload()
        })
      }
      if(uploadErr !==null && typeof(uploadErr) === 'string')
      {
        var u = new Promise((resolve , reject)=>{

          tostE(uploadErr);

          resolve()
        })
        u.then(()=>{
          window.location.reload()
          console.log("err")
        })
      }
      if(reqUploadMsg !==null && typeof(reqUploadMsg) === 'string')
      {
        tostS(reqUploadMsg)

      }
      if(reqUploadErr !==null && typeof(reqUploadErr) === 'string')
      {
        tostE(reqUploadErr)
      }
      resolve()

    })
    x.then(()=>{
    dispatch(cleanAnnexureData())
    dispatch(cleanUploadedData())
      

    })

  }, [uploadMsg, uploadErr, reqUploadMsg, reqUploadErr])

  const handleSubmit = () => {
     
     if(values.slot_id==="" || values.slot_id===null || values.slot_id===undefined) {
       tostW("Please select Slot!!");
     }else if(values.doc_name==="" || values.doc_name===null || values.doc_name===undefined){
      tostW("Please Select Document Type!!");
    }else if(values.img==="" || values.img===null || values.img===undefined){
      tostW("Please Select File!!");
    }else{
      new Promise((resolve, reject) => {

        dispatch(uploadAnnexure(values))

        resolve()
      }).then(() => {
        setValues({
          ...values,
          ["doc_name"]:"",
          ['img']:"",
        })
      })

     }
  }


       /********* File Handling   ************** */
       const hiddenFileInput = useRef(null);
  
       const handleClick = event => {
         hiddenFileInput.current.click();
       };
       const handleChangeimg = event => {
         if(event.target.files.length!==0 && event.target.files[0]){
           const fileUploaded = event.target.files[0];
           const file_ext = fileUploaded.name.split(".")
          
          const is_allowed  = AllowedFileExt.includes(file_ext[file_ext.length -1]);

          if(is_allowed){
            setValues({...values , ['image1']:fileUploaded , ['img']:fileUploaded.name})
            console.log(":::::::::::::::::::::::::")

          }else{
            tostE("Only PDF files are Allowed!!")
          }


         }
     
     
       };
       
       /********* File Handling   ************** */
      
        
  const [open, setOpen] = useState({
    show:false,
    id:""
  });
    
  const handleOpen = (data) => {
    setOpen({
      ...open,
      ['show']:true,
      ['id']:data.up_id
    });
  } 

  const handleClickSubmit = () => {
    dispatch(reqUploadData({"up_id" : open.id}))
    setOpen({
      ...open,
      ['show']:false,
      ['id']:""
    });
  };

  const handleClose = () => {
    setOpen({
      ...open,
      ['show']:false,
      ['id']:""
    });
  };


  return (
    <Page title="Upload Annexure">
      
      <Dialog open={open.show} onClose={handleClose}>
        <DialogTitle>Request to Re upload Attendance Sheet</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure You want to Re upload this attendance sheet?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClickSubmit} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {uploadLoading && uploadProgress!==null
      ?
        <Stack spacing={2}>
          <Alert severity="error">
            <AlertTitle>Please Do Not Refresh The Page</AlertTitle>
            This may result to — <strong>Corruption In Your Data.</strong>
          </Alert>
        </Stack>
      
      :<></>}
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Upload Annexure Document</Typography>
        </Box>
        <Grid container spacing={3}>
            
          <Grid item md={6} xs={12} style={{marginBottom:"25px"}}>
          <FormControl disabled={uploadLoading} fullWidth>
            <InputLabel id="demo-simple-select-label">Select Document</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.doc_name}
              label="Select Document"
              name="doc_name"
              onChange={handleChangeDoc}
            >
                {/* <MenuItem value="Seller_Annexure_1">{"Seller Annexure 1 (Attendance Summary)"}</MenuItem>
                <MenuItem value="Seller_Annexure_2">{"Seller Annexure 2 (Manpower Form)"}</MenuItem>
                <MenuItem value="Seller_Annexure_3">{"Seller Annexure 3 (Feedback Form)"}</MenuItem>
                <MenuItem value="Seller_Annexure_I">{"Seller Annexure I (Manpower Requirement Form)"}</MenuItem>
                <MenuItem value="Seller_Staff_Attendance_Form">{"Seller Staff Attendance Form"}</MenuItem>
                <MenuItem value="Seller_Declaration_Form">{"Seller Manpower Declaration Form"}</MenuItem>                   
                <MenuItem value="Officer_Annexure_1">{"Officer Annexure 1 (Attendance Summary)"}</MenuItem>
                <MenuItem value="Officer_Annexure_2">{"Officer Annexure 2 (Manpower Form)"}</MenuItem>
                <MenuItem value="Officer_Annexure_3">{"Officer Annexure 3 (Feedback Form)"}</MenuItem>
                <MenuItem value="Officer_Annexure_I">{"Officer Annexure I (Manpower Requirement Form)"}</MenuItem>
                <MenuItem value="Officer_Staff_Attendance_Form">{"Officer Staff Attendance Form"}</MenuItem>
                <MenuItem value="Officer_Declaration_Form">{"Officer Manpower Declaration Form"}</MenuItem>     
                               */}
              <MenuItem value="Annexure_II">{"(Annexure – II)	Attendance Summary of exam Centre"}</MenuItem>     
              <MenuItem value="Annexure_IV">{"(Annexure – IV)	CPU and Monitor checked and Seal Status"}</MenuItem>     
              <MenuItem value="Annexure_V">{"(Annexure – V)	CCTV Report"}</MenuItem>     
              <MenuItem value="Annexure_VI">{"(Annexure – VI	)Mock days Report"}</MenuItem>     
              <MenuItem value="Annexure_VII">{"(Annexure – VII)	Biometric and Invigilation Record"}</MenuItem>     
              <MenuItem value="Annexure_VIII">{"(Annexure – VIII) Undertaking for change of allocated seat"}</MenuItem>     
              <MenuItem value="Annexure_IX">{"(Annexure -IX) Undertaking for forgot Password"}</MenuItem>     
              <MenuItem value="Annexure_X">{"(Annexure - X) Summary of IAF Appendix"}</MenuItem>     
              <MenuItem value="Annexure_XI">{"(Annexure- XI) Exam Centre Feedback (after exam)"}</MenuItem>     
              <MenuItem value="Feedback_CDAC_AWPO">{"Feedback of C-DAC/ AWPO Staff"}</MenuItem>     
              <MenuItem value="Manpower_Declaration">{"Manpower Declaration (Upload on Pre-Exam Portal)"}</MenuItem>     
              <MenuItem value="Manpower_Details_Annexure_III">{"(Annexure-III)	Manpower Details at Exam Centers"}</MenuItem>     

            </Select>
          </FormControl>
          </Grid>  
        </Grid>
         <Card className='uploadFileCard'>
            <CardHeader title="Upload Annexure PDF" />
            {uploadLoading ?
              <LinearProgress variant="buffer" value={uploadProgress} valueBuffer={uploadProgress+5} color="info" />
              :
            <Divider />
            }
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              m={6}
              textAlign='center'
            >
              
              <Typography
                color={theme.palette.primary.light}
                gutterBottom
                variant="h3"
              >
              {values.img ? values.img : uploadProgress!==null ? `${uploadProgress} %` : "No File Selected"}
                

                <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChangeimg}
              style={{display: 'none'}} 
            />
              <LoadingButton
                color="primary"
                fullWidth
                loading={uploadLoading}
                variant="contained"
                onClick={handleClick}
              >
                Select File
              </LoadingButton>
              </Typography>              
            </Box>
            <Divider />
            <CardActions>
               <LoadingButton
                  color="primary"
                  fullWidth
                  loading={uploadLoading}
                  variant="outlined"
                  onClick={handleSubmit}
               >
                 Upload Data
               </LoadingButton>
            
            </CardActions>
            </Card>
            <Card className="uploadTableCard">
            <TableContainer>

              <Table>
                <TableHead className='uploadTableHead'>
                  <TableRow>
                    <TableCell>Center Code</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>File Upload Count</TableCell>
                  </TableRow>
                </TableHead>
                {uploadedData.length ? 
                  <TableBody>
                    {uploadedData.map((row, i) => (
                      <TableRow key={i} style={{borderBottom: i<uploadedData.length-1 ? `1px solid ${theme.palette.primary.lighter}` : null}}>                    
                        <TableCell>{row.centre_code}</TableCell>
                        <TableCell>{row.ci_name}</TableCell>
                        <TableCell>{row.file_name}</TableCell>
                        
                        <TableCell>
                        {row.upload_count}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                :
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          Not found
                        </Typography>
                        <Typography variant="body2" align="center">
                          No results found. 
                        </Typography>
                      
                    </TableCell>
                  </TableRow>
                </TableBody>
                }
              </Table>
            </TableContainer>
          </Card>
      </Container>
    </Page>
  );
}
