import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {addCiBegin , addCiSuccess , addCiFailure} from '../ActionCreator';
import {getCenterCiBegin , getCenterCiSuccess , getCenterCiFailure} from '../ActionCreator';
import {getAllCiBegin , getAllCiSuccess , getAllCiFailure} from '../ActionCreator';

export const addCi = (data) => {

    return (dispatch) => {
        dispatch(addCiBegin());

        axios.post(Base_URL + '/admin/addci', data)
            .then((res) => {
                dispatch(addCiSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addCiFailure(err.response.data));

                }
            })
    }
}


export const getCenterCi = (x,y,z) => {

    return (dispatch) => {
        dispatch(getCenterCiBegin());

        axios.get(Base_URL + '/admin/getcidetailscentrewise', {params:{exam_id:x, centre_id:y, ec_id:z}})
            .then((res) => {
                dispatch(getCenterCiSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getCenterCiFailure(err.response.data));

                }
            })
    }
}


// All Available CI
export const getAllCi = () => {

    return (dispatch) => {
        dispatch(getAllCiBegin());

        axios.get(Base_URL + '/admin/getavlci')
            .then((res) => {
                dispatch(getAllCiSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAllCiFailure(err.response.data));

                }
            })
    }
}

// All Added CI

export const getAllCiList = () => {

    return (dispatch) => {
        dispatch(getAllCiBegin());

        axios.get(Base_URL + '/admin/getAllCiList')
            .then((res) => {
                dispatch(getAllCiSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAllCiFailure(err.response.data));

                }
            })
    }
}