// material
import { styled } from '@material-ui/core/styles';
import { Divider, Card,CardHeader,CardContent, Typography, Stack } from '@material-ui/core';
// utils
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

const RowStyle = styled('div')({
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'space-between'
});

// ----------------------------------------------------------------------

export default function CenterInfo() {
  
    const CenterData = useSelector(state => state.login.data.centre_details) 


  return (
    <Card sx={{ p: 3 }} className='dashBoardCardBorder'>
      <CardHeader title="Center Info"/>
      <CardContent >
        <Stack spacing={2}>
          <RowStyle>
            <Typography variant="subtitle1">
              Center Code
            </Typography>
            <Typography variant="subtitle2">{CenterData.centre_code}</Typography>
          </RowStyle>
          <Divider />
          <RowStyle>
            <Typography variant="subtitle1">
              Center Name
            </Typography>
            <Typography variant="subtitle2">{CenterData.centre_name}</Typography>
          </RowStyle>
          <Divider />
          <RowStyle>
            <Typography variant="subtitle1">
              Center Address
            </Typography>
            <Typography variant="subtitle2">{`${CenterData.spoc_address}, ${CenterData.city_name}, ${CenterData.state_name},${CenterData.country_name}, [${CenterData.pincode}]`}</Typography>
          </RowStyle>
          <Divider />

          <RowStyle>
            <Typography variant="subtitle1">
              Spoc Person Name
            </Typography>
            <Typography variant="subtitle2">{CenterData.spoc_name}</Typography>
          </RowStyle>
          <Divider />

          <RowStyle>
            <Typography variant="subtitle1">
            Spoc Person Mobile
            </Typography>
            <Typography variant="subtitle2">{CenterData.spoc_mobile}</Typography>
          </RowStyle>        
          <Divider />
          <RowStyle>
            <Typography variant="subtitle1">
            Spoc Person Email
            </Typography>
            <Typography variant="subtitle2">{CenterData.spoc_email}</Typography>
          </RowStyle>        
          <Divider />
          <RowStyle>
            <Typography variant="subtitle1">
            Remark
            </Typography>
            <Typography variant="subtitle2">{CenterData.remark}</Typography>
          </RowStyle>        
        </Stack>
      </CardContent>      
    </Card>
  );
}
