import {REQ_UPLOAD_DATA_LOADING , REQ_UPLOAD_DATA_SUCCESS , REQ_UPLOAD_DATA_FAILURE} from '../../Actions/Types';
import {GET_UPLOADED_DATA_LOADING , GET_UPLOADED_DATA_SUCCESS , GET_UPLOADED_DATA_FAILURE , CLEAN_UPLOADED_DATA} from '../../Actions/Types';

import {CLEAN_ALL_DATA} from '../../Actions/Types'

const UploadedData = (state , action) => {
    if(typeof state === 'undefined') {
        return {
            loading:false,
            data: [{}],
            error:{},
            reqData: [{}],
            reqError:{},
            
        }
    }

    switch(action.type) {
        case CLEAN_ALL_DATA:
            return {
                ...state,
                data: [{}],
                loading:false,
                error:{},
                reqData: [{}],
                reqError:{},
            }
    
    
        case CLEAN_UPLOADED_DATA:
            return {
                ...state,
                reqData: [{}],
                loading:false,
                reqError:{},                
            }
    
        
        case REQ_UPLOAD_DATA_LOADING:
            return {
                ...state,
                loading:true,
                reqError: {},
                reqData : [{}],               
                
            }
        case REQ_UPLOAD_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                reqError:{},
                reqData:action.data,                
            }
        case REQ_UPLOAD_DATA_FAILURE:
            return {
                ...state,
                loading:false,
                reqError:action.error,
                reqData:[{}],                
            }
        
        case GET_UPLOADED_DATA_LOADING:
            return {
                ...state,
                loading:true,
                error: {},
                data : [{}],               
                
            }
        case GET_UPLOADED_DATA_SUCCESS:
            return {
                ...state,
                loading:false,
                error:{},
                data:action.data,                
            }
        case GET_UPLOADED_DATA_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.error,
                data:[{}],                
            }

        default:
        return state
    
    }
    }
    
    
    
export default UploadedData;
    