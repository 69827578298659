import React, {useEffect, useState} from 'react';
import { filter } from 'lodash';
import {useNavigate, useLocation} from 'react-router-dom'
import moment from 'moment';

// material
import { 
  Stack, 
  Grid, 
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Checkbox,
  Tooltip,
  Breadcrumbs,
  Divider,
  Link
} from '@material-ui/core';
import { alpha, styled,useTheme } from '@material-ui/core/styles';

// Icons
import { Icon } from '@iconify/react';

import moreVerticalFill from '@iconify/icons-eva/layers-fill';

// components
import Page from '../../../components/Page';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user';
import { useFormik } from 'formik';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';



// API

import {getCenterCi} from '../../../Actions/AdminActions/Ci'
// --------------------------------------------------------------------------------------


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '50vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0)
}));


// --------------------------------------------------------------------------------------




// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'centre_code', label: 'Center Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },  
  { id: 'mobile', label: 'Mobile', alignRight: false },  
  { id: 'email', label: 'Email', alignRight: false },  
  { id: 'View' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



const CenterScreen = ()=> {
  const theme = useTheme();
  const {state} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const listData = useSelector(state => state.CiDetail.centerGetData && 
    Array.isArray(state.CiDetail.centerGetData) && 
    state.CiDetail.centerGetData.length ? state.CiDetail.centerGetData : [])


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const filteredUsers = applySortFilter(listData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;


   

  useEffect(() =>{
    dispatch(getCenterCi(state.exam_id, state.centre_id, state.ec_id))
    
    

  }, [])



  return (
    <Page title="Center">
      <Container maxWidth="xl">
         <Grid item xs={12}>
            <Breadcrumbs>   
               <Link color="inherit" href="/admin/exam">
               Exam Profile
               </Link>         
               {/* <Link color="inherit" href={"/admin/exam-center/"+state.exam_id}>
               Exam Center
               </Link>          */}
               <Typography sx={{ color: 'text.primary' }}>Center CI</Typography>
            </Breadcrumbs>
        </Grid> 
        <Divider />
        <Stack direction="row" alignItems="center" justifyContent="start" mb={5}>
          <Grid item md={6} lg={4} sm={12}>
          <Typography variant="h4">Center wise CI Details</Typography>

          </Grid>
          <Grid item md={4} sm={12}>
            <Typography variant="h5" gutterBottom style={{textDecoration:'underline', textAlign:"center"}}>
                {state.exam_name}
            </Typography>

          </Grid>
          
        </Stack>
        <Card className='cardBorder'>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={listData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { centre_code, name, mobile, email } = row;
                      const isItemSelected = selected.indexOf(mobile) !== -1;

                      return (
                        <TableRow
                          hover
                          key={mobile}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          className="tableRowBottomBorder"
                        >
                          
                          
                          <TableCell align="left">{centre_code}</TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{mobile}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          
                          {/* <TableCell align="left" spacing={3}>  
                              <Tooltip title="Center Details">
                                 <Icon icon={moreVerticalFill}  width={24} height={24} style={{marginRight:20 , cursor : "pointer"}} />                                                 

                              </Tooltip>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={listData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}


export default CenterScreen;