import React ,  { useState , useEffect, useRef} from 'react';

// material
import { styled } from '@material-ui/core/styles';
import { Box, 
         Card, 
         Link, 
         Container, 
         Typography, 
         Stack, 
         Button, 
         Grid,         
         Avatar,
         CardActions,
         CardContent,
         Divider,         
         CardHeader,
         TextField,
        InputAdornment,
        IconButton} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LoadingButton } from '@material-ui/lab';

import { useSelector , useDispatch  } from 'react-redux';
         
import {tostS  , tostW , tostE } from 'src/config/Toast'
// components
import Page from 'src/components/Page';

import { fData } from 'src/utils/formatNumber';

// Icons
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';

import {changeCiPass, changeCiProfilePic} from 'src/Actions/CiActions/Profile';
import {cleanCiProfileData, loginSuccess} from 'src/Actions/ActionCreator'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const useStyles = makeStyles((theme) => ({
   root: {
     backgroundColor: theme.palette.background.dark,
     minHeight: '100%',
     paddingBottom: theme.spacing(3),
   //   paddingTop: theme.spacing(3)
   },
   root2: {},
   avatar: {
     height: 250,
     width: 250
   },
   formControl: {
     margin: theme.spacing(0),
     minWidth: '100%',
   },
   selectEmpty: {
     marginTop: theme.spacing(2),
   },
 
 }));



// ----------------------------------------------------------------------

const UserProfile = ({className, ...rest}) => {
  const classes = useStyles();
   const dispatch = useDispatch();

  const currentUser = useSelector(state => state.login.data);

  const [values, setValues] = useState({
    ci_id:currentUser.ci_id,
    password:"",
    confirm_password:"",
    img:"",

  })


  const [showPass, setShowPass] = useState(false)
  const [showCPass, setCShowPass] = useState(false)

  const handleShowPass = () =>{
    setShowPass(!showPass)
  }
  const handleCShowPass = () =>{
    setCShowPass(!showCPass)
  }


  const handleChange = (event) => {
    setValues({
        ...values,
        [event.target.name]:event.target.value
    })
  }
 


  const handleSubmitPass = () =>{

    if(values.password==="" || values.password===null || values.password===undefined){
      tostW("Please Enter Password");
    }else if(values.confirm_password==="" || values.confirm_password===null || values.confirm_password===undefined){
        tostW("Please Enter Confirm Password");
    }else if(values.password!== values.confirm_password){
        tostW("Please Enter Same Password");
    }else{
      console.log(values)
      const sendData = {
        "ci_id" : values.ci_id,
        "password"  : values.password
      }
        dispatch(changeCiPass(sendData))
      setValues({
          ...values,         
          password:"",
          confirm_password:"",       
      });
    }
  }


  const addmsg = useSelector((state) => state.CiProfile  && state.CiProfile.passData && state.CiProfile.passData && state.CiProfile.passData.message ? state.CiProfile.passData.message : null  )
  const adderror = useSelector((state) => state.CiProfile  && state.CiProfile.passError && state.CiProfile.passError.message ? state.CiProfile.passError.message : null  )
   

  const picMsg = useSelector((state) => state.CiProfile  && state.CiProfile.picData && state.CiProfile.picData && state.CiProfile.picData.message ? state.CiProfile.picData.message : null  )
  const picObj = useSelector((state) => state.CiProfile  && state.CiProfile.picData && state.CiProfile.picData && state.CiProfile.picData.obj ? state.CiProfile.picData.obj : null  )
  const picError = useSelector((state) => state.CiProfile  && state.CiProfile.picError && state.CiProfile.picError.message ? state.CiProfile.picError.message : null  )
   

  const handleChangeProfileFun = (picObj) =>{
    var accountInfo = currentUser;
    console.log(accountInfo)
    accountInfo['images'] = picObj;

    console.log(accountInfo)
    dispatch(loginSuccess(accountInfo))
  }

useEffect( () => {
    let x  = new Promise((resolve ,reject)=>{
      if(addmsg !==null && typeof(addmsg) === 'string')
      {
        tostS(addmsg)
      }
      if(adderror !==null && typeof(adderror) === 'string')
      {
        tostE(adderror)
      }
      if(picMsg !==null && typeof(picMsg) === 'string')
      {
        tostS(picMsg)
        handleChangeProfileFun(picObj)
      }
      if(picError !==null && typeof(picError) === 'string')
      {
        tostE(picError)
      }

      resolve()

    })
    x.then(()=>{

   dispatch(cleanCiProfileData())
  

    })

  }, [addmsg , adderror, picMsg, picObj, picError])
  

 
  /********* Image Handling   ************** */
  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
    
  };
  const handleChangeimg = event => {
    const fileUploaded = event.target.files[0];
    if(event.target.files[0] && event.target.files.length !==0){
      const formData = new FormData();
      formData.append('ci_id',values.ci_id);
      formData.append('img',fileUploaded);
      dispatch(changeCiProfilePic(formData));          
    }   
  };
  
  /********* Image Handling   ************** */

  
  return (
    <RootStyle title="User Profile">    
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }} className="cardBorder">
          <CardHeader title = "Profile Pic" />

          <CardContent>
            <Box alignItems="center" display="flex" flexDirection="column">
              <Avatar 
                className={classes.avatar} 
                src={currentUser.images} 
                sx={{
                  mx: 'auto',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: 'common.white',
                  width: { xs: 80, md: 128 },
                  height: { xs: 80, md: 128 }
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  mt: 2,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.secondary'
                }}
              >
                Allowed *.jpeg, *.jpg, *.png
                
              </Typography>

              
            </Box>
          </CardContent>
          <CardActions>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChangeimg}
              style={{display: 'none'}} 
            />
              <Button
                color="primary"
                fullWidth
                variant="outlined"
                onClick={handleClick}
              >
              {/* { values.flag===false ? 'Upload Picture':'Update Picture'} */}
              Upload Picture
              </Button>
          </CardActions>
            
          </Card>
        </Grid>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }} className="cardBorder">
            <CardHeader title = "Basic Details" />
            <CardContent>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    value = {currentUser.name}
                    readOnly
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    value = {currentUser.mobile}
                    readOnly
                  />
                  
                </Stack>

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  
                  
                  <TextField
                    fullWidth
                    label="Email Address"
                    value = {currentUser.email}
                    readOnly
                  />
                  
                  
                  <TextField
                    fullWidth
                    label="Address"
                    value = {currentUser.address}
                    readOnly
                  />
                </Stack>
              </Stack>
            </CardContent>
            <CardActions>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton disabled type="submit" variant="contained">
                  Save Changes
                </LoadingButton>
              </Box>
            </CardActions>
          </Card>
          <Card sx={{ p: 3}} className="cardBorder">
              <CardHeader title = "Change Password" />
            <CardContent>
              <Stack spacing={3}>                
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Password"
                    autoComplete="current-password"
                    type={showPass ? 'text' : 'password'}
                    name="password"
                    required
                    value={values.password}
                    onChange={(e)=>{handleChange(e)}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleShowPass} edge="end">
                            <Icon icon={showPass ? eyeFill : eyeOffFill} />
                            </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />                                  
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    autoComplete="current-password"
                    type={showCPass ? 'text' : 'password'}
                    name="confirm_password"
                    required
                    value={values.confirm_password}
                    onChange={(e)=>{handleChange(e)}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleCShowPass} edge="end">
                            <Icon icon={showCPass ? eyeFill : eyeOffFill} />
                            </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>

              </Stack>
            </CardContent>
            <CardActions>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton type="submit" variant="contained" onClick={()=>{handleSubmitPass()}}>
                  Change Password
                </LoadingButton>
              </Box>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </RootStyle>
  );
}


export default UserProfile;