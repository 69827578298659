import React, {useEffect, useState} from 'react';

import { filter } from 'lodash';

// material
import { 
  Box, 
  Grid, 
  Checkbox,
  TextField,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TableHead,
  MenuItem,
  Select,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  CardContent
} from '@material-ui/core';
import {useTheme } from '@material-ui/core/styles';

// components
import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';

import CheckIcon from '@iconify/icons-ant-design/check-outline';
import {tostS  , tostW , tostE } from '../../../config/Toast'

import { useSelector , useDispatch  } from 'react-redux';
import { Icon } from '@iconify/react';




// API
import {verifyData, getVerifiedData} from 'src/Actions/AdminActions/verifyData';
import {getExam} from 'src/Actions/AdminActions/Exam';
import {getAvlExamSlot} from 'src/Actions/AdminActions/Slots';

import { setSlot, cleanVerifiedData } from 'src/Actions/ActionCreator';



const getIcon = (name) => <Icon icon={name} width={22} height={22} />;


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}



function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}


export default function DashboardApp() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [orderBy, setOrderBy] = useState('centre_code');
  const [order, setOrder] = useState('asc');
  const [filterName, setFilterName] = useState('');

  
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };




  const primary_id = useSelector(state=>state.login.data.exam_id);
  const selected_slot = useSelector(state => state.Dashboard.slot && state.Dashboard.slot!=="" 
  && state.Dashboard.slot!==null && state.Dashboard.slot!==undefined ? state.Dashboard.slot : null)

  
  const UploadData = useSelector(state=> state.VerifyData.getData &&
        Array.isArray(state.VerifyData.getData) && state.VerifyData.getData.length ?
        state.VerifyData.getData : [])
    
  const SlotData = useSelector(state => state.Slots.avlData && 
    Array.isArray(state.Slots.avlData) && 
    state.Slots.avlData.length ? (state.Slots.avlData) : []);
      

  const [values, setValues] = useState({
    exam_id:primary_id,        
    slot_id:selected_slot,        
   })



  const filteredData = applySortFilter(UploadData, getComparator(order, orderBy), filterName);
   
   console.log(filteredData)
  const handleChangeSlot = (event) => {
    const e_id = values.exam_id;
    const s_id = event.target.value;

    setValues({
        ...values,
        ['slot_id']:s_id
    })
    dispatch(setSlot(s_id))
    dispatch(getVerifiedData(e_id, s_id))
}

  const CheckMsg = useSelector((state) => state.VerifyData  && state.VerifyData.data  && state.VerifyData.data.message ? state.VerifyData.data.message : null  )
  const CheckError = useSelector((state) => state.VerifyData  && state.VerifyData.error && state.VerifyData.error.message ? state.VerifyData.error.message : null  )
   

  useEffect(() =>{
    dispatch(getExam())
    dispatch(getAvlExamSlot(values.exam_id))
    dispatch(getVerifiedData(values.exam_id, values.slot_id))

    
    let x  = new Promise((resolve ,reject)=>{
      if(CheckMsg !==null && typeof(CheckMsg) === 'string')
      {
        tostS(CheckMsg)
      }
      if(CheckError !==null && typeof(CheckError) === 'string')
      {
        tostE(CheckError)
      }
  
      resolve()
  
    })
    x.then(()=>{
  
   dispatch(cleanVerifiedData())
   dispatch(getVerifiedData(values.exam_id, values.slot_id))
  
  
    })

  }, [selected_slot, CheckMsg, CheckError])

  const handleVerifyCheck =(data)=>{
    console.log(data)
    dispatch(verifyData(data))
  }

  return (
    <Page title="Verify Data">
      <Container maxWidth="xl">
        <Box>
          <Typography variant="h4">Verify Uploaded Data</Typography>
        </Box>
        
        <Grid container spacing={3} mt={2}>                     
          <Grid item xs={12} md={12} lg={12}>
            <Card className='dashboardAdminUploadedDataCard'>
              <CardHeader title="Uploaded Data Status" subheader="Select Slot To View Data" sx={{ mb: 3 }} />  
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} sm={12}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-helper-label">Select Slot</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={values.slot_id}
                            label="Select Slot"
                            name="exam_id"
                            onChange={handleChangeSlot}
                        >
                            {SlotData.map((data , i)=>
                            <MenuItem key={i} value={data.slot_id}>{data.slot_name}</MenuItem>
                            )}        

                        </Select>
                      </FormControl>
                  </Grid>         
                  
                </Grid>
                <TableContainer className='dashboardAdminUploadedDataTable'>
                    <Scrollbar>
                        <Table size='small'>
                            <TableHead>
                            <TableRow>
                                <TableCell className='dashboardAdminUploadedDataCell'>Center Code</TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell'>Slot</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Biometric</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Attendance Sheet</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Primary Server</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Secondary Server</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>PXE Server</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Response Sheet</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Catelina Primary</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Catelina Secondary</TableCell>                
                                <TableCell className='dashboardAdminUploadedDataCell'>Rejection Summary</TableCell>                
                                
                            </TableRow>
                            </TableHead>
                            {filteredData.length ?
                            <TableBody>
                            {filteredData.map((row, i) => (
                                <TableRow key={i}>
                                <TableCell className='dashboardAdminUploadedDataCell'>{row.centre_code}</TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell'>{row.slot_name}</TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.biometric[0] ? "#54D62C" : "#FF4842"}}>
                                {row.biometric[0]?
                                <Checkbox style={{color:'black'}} checked={row.biometric[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'biometric', 'status':!row.biometric[1]})}} />
                                :<></>
                                }

                                </TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.att_sheet[0] ? "#54D62C" : "#FF4842"}}>

                                {row.att_sheet[0]?
                                <Checkbox style={{color:'black'}} checked={row.att_sheet[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'att_sheet', 'status':!row.att_sheet[1]})}} />
                                :<></>
                                }

                                </TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.primary_bkp[0] ? "#54D62C" : "#FF4842"}}>

                                {row.primary_bkp[0]?
                                <Checkbox style={{color:'black'}} checked={row.primary_bkp[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'primary_bkp', 'status':!row.primary_bkp[1]})}} />
                                :<></>
                                }

                                </TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.secondary_bkp[0] ? "#54D62C" : "#FF4842"}}>

                                {row.secondary_bkp[0]?
                                <Checkbox style={{color:'black'}} checked={row.secondary_bkp[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'secondary_bkp', 'status':!row.secondary_bkp[1]})}} />
                                :<></>
                                }

                                </TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.pxe[0] ? "#54D62C" : "#FF4842"}}>
                                {row.pxe[0]?
                                <Checkbox style={{color:'black'}} checked={row.pxe[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'pxe', 'status':!row.pxe[1]})}} />
                                :<></>
                                }


                                </TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.rsp_sheet[0] ? "#54D62C" : "#FF4842"}}>

                                {row.rsp_sheet[0]?
                                <Checkbox style={{color:'black'}} checked={row.rsp_sheet[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'rsp_sheet', 'status':!row.rsp_sheet[1]})}} />
                                :<></>
                                }

                                </TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.cat_pri[0] ? "#54D62C" : "#FF4842"}}>

                                {row.cat_pri[0]?
                                <Checkbox style={{color:'black'}} checked={row.cat_pri[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'cat_pri', 'status':!row.cat_pri[1]})}} />
                                :<></>
                                }

                                </TableCell>
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.cat_sec[0] ? "#54D62C" : "#FF4842"}}>

                                {row.cat_sec[0]?
                                <Checkbox style={{color:'black'}} checked={row.cat_sec[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'cat_sec', 'status':!row.cat_sec[1]})}} />
                                :<></>
                                }

                                </TableCell>                                                      
                                <TableCell className='dashboardAdminUploadedDataCell' style={{backgroundColor:row.rej_summary[0] ? "#54D62C" : "#FF4842"}}>

                                {row.rej_summary[0]?
                                <Checkbox style={{color:'black'}} checked={row.rej_summary[1]} onClick={()=>{handleVerifyCheck({'us_id':row.us_id, 'content_type':'rej_summary', 'status':!row.rej_summary[1]})}} />
                                :<></>
                                }

                                </TableCell>                                                      
                                </TableRow>
                            ))}
                            </TableBody>
                            :
                            <TableBody>
                                <TableRow>
                                <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                                    <Typography gutterBottom align="center" variant="subtitle1">
                                        Not found
                                    </Typography>
                                    <Typography variant="body2" align="center">
                                        No results found. 
                                    </Typography>
                                    
                                </TableCell>
                                </TableRow>
                            </TableBody>
                            }
                        </Table>
                    </Scrollbar> 
                </TableContainer>

              </CardContent>
            </Card>
          </Grid>            
        </Grid>

      </Container>
    </Page>
  );
}
