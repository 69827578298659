// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function NetparamIllustration({ ...other }) {
  const theme = useTheme();
  
  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1118.16 248.29">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-478 -425)">
            <g transform="translate(478 425)">              
                <path fill={"#005571"} d="M259.32,273c-4.7-.23-9.39-.42-14.08-.68a409.21,409.21,0,0,1-90-15.45c-31-8.9-60.57-21.38-89.28-36-8.26-4.21-16.58-8.31-24.87-12.46a3.87,3.87,0,0,1-1.6-1.24c.54.21,1.09.41,1.62.65a604.12,604.12,0,0,0,93.49,33.38,498.19,498.19,0,0,0,70.29,13.29q22.15,2.52,44.43,3a491.52,491.52,0,0,0,64.31-2.78c48.93-5.39,96.06-17.85,141.64-36.31,5.21-2.11,10.38-4.3,15.68-6.5l.95,1.77c-4.17,2.27-8.23,4.63-12.41,6.72a604,604,0,0,1-64.28,27.83c-23.35,8.46-47.15,15.28-71.66,19.44a354.14,354.14,0,0,1-49.81,5.05c-1.85.05-3.7.2-5.55.3Z" transform="translate(-39.53 -41)"/>
                <path fill={"#04506a"} d="M302.49,148.89V117.37H219.76c-3.59,0-5.68-1.34-6.41-4.86a15.68,15.68,0,0,1,.12-6.54c.75-3.21,2.81-4.46,6.15-4.46H377c3.83,0,6,1.71,6.76,5.47a5.21,5.21,0,0,1,.21,2.39c-1.08,3.68.06,7.32.05,11q0,14.68,0,29.36c0,2.4.09,4.81,0,7.22a12.67,12.67,0,0,1-.78,4,5.75,5.75,0,0,1-5.85,4c-18.45-.06-36.91,0-55.36,0h-2.45V200a14.66,14.66,0,0,1-2.43,8.61c-3.32,4.8-8.82,4.78-12.11,0a14.27,14.27,0,0,1-2.37-8.25q0-16.53,0-33.07v-2.42H208.67c-3.72,0-5.69-1.49-6.66-5.13a10.17,10.17,0,0,1,.63-7.41,5.65,5.65,0,0,1,5.46-3.41q46,.07,92,0Zm61.71-31.38H319.63v31.3H364.2Z" transform="translate(-39.53 -41)"/>
                <path fill={"#5caf45"} d="M156.82,126v2q0,35.85,0,71.7a15.91,15.91,0,0,1-2,8.2c-3.31,5.71-9.83,5.78-12.94,0a15.44,15.44,0,0,1-1.89-6.86q-.17-52,0-104c0-3.09.94-6.2,1.54-9.28a8.13,8.13,0,0,1,1-2.36c3.25-5.67,9.49-5.81,12.9-.23,2.46,4,4.73,8.13,7.08,12.2q26.25,45.46,52.49,90.94c.74,1.28,1.35,2.63,2.12,4.14h59.62c5.1,0,7.62,1.94,8.67,7a12.47,12.47,0,0,1-1.45,9.48,6.3,6.3,0,0,1-5.61,3.33c-22.23,0-44.45,0-66.68-.1a6.71,6.71,0,0,1-4.39-1.86,42,42,0,0,1-5.78-7.62q-21.85-37.6-43.55-75.32c-.27-.47-.57-.92-.86-1.38Z" transform="translate(-39.53 -41)"/>
                <path fill={"#0a4c6f"} d="M568,186.79c-4.66,0-9.17.05-13.68-.06-.58,0-1.32-.76-1.67-1.35Q540.78,165.24,529,145l-5.42-9.23v50.85H505.9V92.78c2.67,0,5.37,0,8.07,0,2.16,0,4.72-.64,6.37.3s2.39,3.46,3.48,5.32q12.58,21.54,25.15,43.1l1.25,2.1V92.9H568Z" transform="translate(-39.53 -41)"/>
                <path fill={"#0a4c6f"} d="M659.75,166.89v19.76H606.9V92.87h51.56v19.7H624.69v17h29.84V148.8h-29.8v18.09Z" transform="translate(-39.53 -41)"/>
                <path fill={"#5caf45"} d="M1115.35,168.08q15.06-37,30.18-74.13h12.16v91.24h-9.6c.38-26-.49-51.91.5-76.45-2.89,7.35-6.25,16-9.67,24.54s-7,17.31-10.46,26L1118,185.14a1,1,0,0,1-.28.09c-4.56.38-4.56.38-6.25-3.78q-13.24-32.64-26.45-65.29c-1-2.56-1.68-5.28-2.52-7.94v76.95h-9.24V93.9c4.09,0,8.1-.05,12.11.07.45,0,1.07.87,1.3,1.46q7.55,18.73,15,37.51,6.06,15.24,12.08,30.51C1114.41,165,1114.88,166.68,1115.35,168.08Z" transform="translate(-39.53 -41)"/>
                <path fill={"#005571"} d="M457.7,88C327,40.88,197.27,40.67,68.35,94.72c3.36-1.77,6.71-3.58,10.09-5.31,31.4-16.14,64.09-28.9,98.43-37.3A380.75,380.75,0,0,1,329,45.88c39.77,6.38,77.72,18.61,114.29,35.3C448.13,83.38,452.91,85.69,457.7,88Z" transform="translate(-39.53 -41)"/>
                <path fill={"#0a4c6f"} d="M708.22,112.9H687.48V92.82h59.18v20H726.12v73.8h-17.9Z" transform="translate(-39.53 -41)"/>
                <path fill={"#5caf45"} d="M935.42,146.38V185.2h-9.67V94c.63,0,1.17-.08,1.71-.08,8.18,0,16.35-.14,24.52,0,7.61.16,14.39,2.52,19.16,8.87,4.18,5.55,5.19,12,4.39,18.68-1.29,10.91-6.71,17.24-17.83,21.24.06.16.07.4.18.45,3.81,2,6.4,5.25,8.48,8.88,6.15,10.73,12.22,21.5,18.32,32.26.14.25.22.53.41,1-3.71,0-7.22.07-10.73-.06-.56,0-1.27-.82-1.6-1.43-4.48-8.19-8.81-16.46-13.38-24.59a67.17,67.17,0,0,0-6.12-8.8,11,11,0,0,0-8.29-4C941.89,146.25,938.79,146.38,935.42,146.38Zm.09-9.83c5,0,9.86.21,14.67-.05a15.45,15.45,0,0,0,14.32-11.06A20.43,20.43,0,0,0,965,115c-1.09-5.4-4.3-9-9.6-10.45a29.45,29.45,0,0,0-6.76-.93c-4.36-.14-8.72,0-13.15,0Z" transform="translate(-39.53 -41)"/>
                <path fill={"#5caf45"} d="M913.77,185.3c-3.42,0-6.58,0-9.73,0-1.09,0-1.3-.58-1.57-1.39-2.58-7.54-5.21-15.06-7.74-22.62a2,2,0,0,0-2.32-1.69c-10.58.06-21.17,0-31.75,0h-1.59c-2.77,8.56-5.53,17.06-8.29,25.58h-10.9c1.14-3.32,2.22-6.52,3.33-9.71q13.9-39.9,27.78-79.81c.48-1.39,1-2,2.58-1.88a47,47,0,0,0,6.5,0c1.46-.11,2,.5,2.44,1.78q10,28.8,20.08,57.57,5.25,15,10.53,30.09C913.32,183.88,913.49,184.46,913.77,185.3ZM862.4,149.87h28.42l-14.25-43.06C872,120.58,867.24,135.15,862.4,149.87Z" transform="translate(-39.53 -41)"/>
                <path fill={"#5caf45"} d="M1060.89,185.26h-10.82l-5.23-15.21c-1-3-2.12-6.07-3.12-9.12a1.5,1.5,0,0,0-1.66-1.29c-11.2,0-22.4,0-33.81,0-1,3-2,5.92-2.94,8.9-1.65,5.11-3.32,10.22-4.91,15.36a1.66,1.66,0,0,1-1.93,1.4c-3.08-.08-6.16,0-9.47,0,1.4-4.05,2.71-7.89,4-11.73q13.56-39,27.1-77.9a2.2,2.2,0,0,1,2.62-1.81,54.22,54.22,0,0,0,6.5,0,2,2,0,0,1,2.35,1.66q7.73,22.32,15.57,44.62l15,43C1060.43,183.81,1060.63,184.45,1060.89,185.26Zm-37.18-78.44c-4.54,13.82-9.35,28.43-14.18,43.12H1038C1033.13,135.25,1028.3,120.68,1023.71,106.82Z" transform="translate(-39.53 -41)"/>
                <path fill={"#5caf45"} d="M792.78,150.75v34.42h-9.69v-91c.13-.07.23-.18.33-.18,9.22.07,18.49-.44,27.65.37,13.93,1.25,22,10.48,22.68,24.54.43,9.17-1.73,17.42-8.52,24-5.53,5.4-12.33,7.6-19.88,7.82C801.22,150.84,797.08,150.75,792.78,150.75Zm0-10.05c5.12,0,10.09.37,15-.09,7.92-.72,13.55-5.76,15-13.2a31.7,31.7,0,0,0,.44-9c-.61-6.75-4-11.63-10.72-13.65-6.45-1.94-13.09-.95-19.74-1.2Z" transform="translate(-39.53 -41)"/>
                          
              
              </g>
          </g>
        </g>
      </svg>
    </Box>
  );
}
