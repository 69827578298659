import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import { uploadBiometricBegin, uploadBiometricSuccess, uploadBiometricFailure, uploadBiometricProgress } from "../ActionCreator";



export const uploadBiometric = (session) => {

   
   var formData = new FormData()

   formData.append('slot_id',session.slot_id);
   formData.append('content_type',session.content_type);
   formData.append('ec_id',session.ec_id);
   formData.append('exam_id',session.exam_id);
   formData.append('ci_id',session.ci_id);    
   formData.append('img',session.image1 );
     

   return (dispatch) => {
       dispatch(uploadBiometricBegin());

       axios.post(Base_URL + '/admin/uploadbio' , formData,{         
         onUploadProgress: data => {
           //Set the progress value to show the progress bar
           dispatch(uploadBiometricProgress(Math.round(((100 * data.loaded) / data.total)-1)));
         },
       })
           .then((res) => {
               dispatch(uploadBiometricSuccess(res.data));
           }).catch((err) => {
               console.log(err)
               if (!err.response) {
                   console.log("Something Went wrong : Please Check Network or Server Connectivity")

               }
               else {
                   dispatch(uploadBiometricFailure(err.response.data));

               }
           })
   }
}
