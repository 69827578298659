import 'react-perfect-scrollbar/dist/css/styles.css';
import React , {useState , Component , useEffect} from 'react';


import { useSelector, useDispatch, shallowEqual } from "react-redux";

import {makeStyles} from "@material-ui/styles";
import {
  Modal,
  Backdrop,
  Fade
} from "@material-ui/core"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import LoadingScreen from './components/LoadingScreen';
import NoInternet from 'src/components/NoInternet'


const useStyles = makeStyles((theme) => ({
 
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  
}));



const Load = () => {

  const [open, setOpen] = React.useState(true);  


  const loading1 = useSelector(state => state.AdminProfile.loading);
  const loading2 = useSelector(state => state.CiDetail.loading);
  const loading3 = useSelector(state => state.CiProfile.loading);
  const loading4 = useSelector(state => state.Dashboard.loading);
  const loading5 = useSelector(state => state.Exam.loading);
  const loading6 = useSelector(state => state.ExamCenter.loading);
  const loading7 = useSelector(state => state.History.loading);
  // const loading8 = useSelector(state => state.RejectionSummary.loading);

  
  
  
  useEffect(() =>{
    if(loading1 ||loading2 || loading3 || loading4 || loading5 || loading6 || loading7){
      setOpen(true);
    }else{
      setOpen(false);
    }
    
  }, [loading1,loading2, loading3, loading4, loading5, loading6, loading7])


  return (

   <>
   
{/* 
   
    <div>
      <Modal className={classes.modal} open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop} disableBackdropClick={true} >
        <Fade in={open}>
          <Loader type="Bars" color="#00BFFF" height={80} width={80} visible={true} />
        </Fade>
      </Modal>
    </div>
    */}
    {open?
   <LoadingScreen/>
      :
    <></>}
   
   </>
  );

};






export default Load;
