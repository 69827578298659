import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {addExamSlotBegin , addExamSlotSuccess , addExamSlotFailure} from '../ActionCreator';
import {getCenterSlotBegin , getCenterSlotSuccess , getCenterSlotFailure} from '../ActionCreator';
import {getAvlSlotBegin , getAvlSlotSuccess , getAvlSlotFailure} from '../ActionCreator';

export const addExamSlot = (data) => {

    return (dispatch) => {
        dispatch(addExamSlotBegin());

        axios.post(Base_URL + '/admin/addexamslots', data)
            .then((res) => {
                dispatch(addExamSlotSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(addExamSlotFailure(err.response.data));

                }
            })
    }
}


export const getAvlExamSlot = (x) => {

    return (dispatch) => {
        dispatch(getAvlSlotBegin());

        axios.get(Base_URL + '/admin/getslotsexamwise', {params:{exam_id:x}})
            .then((res) => {
                dispatch(getAvlSlotSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getAvlSlotFailure(err.response.data));

                }
            })
    }
}


export const getCenterSlot = (x) => {

    return (dispatch) => {
        dispatch(getCenterSlotBegin());

        axios.get(Base_URL + '/admin/getslotdetailscentrewise', {params:{ec_id:x}})
            .then((res) => {
                dispatch(getCenterSlotSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(getCenterSlotFailure(err.response.data));

                }
            })
    }
}

