import {combineReducers} from 'redux';

import login from './Login';
import LocationState from './LocationState';


import Dashboard from './Dashboard';
import Exam from './Admin/Exam';
import ExamCenter from './Admin/Center';
import CiDetail from './Admin/Ci'
import Slots from './Admin/Slots'
import UploadRequest from './Admin/ReuploadRequest';
import History from './Admin/History';
import AdminProfile from './Admin/Profile';
import VerifyData from './Admin/VerifyData';
import PostExam from './Admin/PostExam';

import Annexure from './Ci/Annexure';
import Biometric from './Ci/Biometric'
import ServerBackup from './Ci/Server';
import AttendanceSheet from './Ci/AttendanceSheet';
import RejectionSummary from './Ci/RejectionSummary';
import ResponseSheet from './Ci/ResponseSheet';
import Catelina from './Ci/Catelina';
import UploadedData from './Ci/uploadedData';
import CiProfile from './Ci/Profile';
import BackupStatus from './Ci/BackupStatus';


import DownloadLinks from './DownloadLinks';


export default combineReducers({
   login,   
   LocationState,

   AdminProfile,
   Dashboard,
   Exam,
   ExamCenter,
   CiDetail,
   Slots,
   UploadRequest,
   History,
   VerifyData,
   PostExam,

   Annexure,
   Biometric,
   ServerBackup,
   AttendanceSheet,
   RejectionSummary,
   ResponseSheet,
   Catelina,
   UploadedData,
   CiProfile,
   BackupStatus,
   DownloadLinks
});