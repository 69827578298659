import React ,  { useState , useEffect} from 'react';

import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, 
         Card, 
         Link, 
         Container, 
         Typography, 
         Stack, 
         Button, 
         Grid,         
         Avatar,
         CardActions,
         CardContent,
         Divider,         
         CardHeader,
         TextField} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useSelector , useDispatch , shallowEqual } from 'react-redux';
         
import {tostS  , tostW , tostE } from '../../../config/Toast'
// components
import Page from '../../../components/Page';


import {ToastContainer} from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// API 
import {addRejSummary} from 'src/Actions/CiActions/RejectionSummary';

import {cleanAddRejSummaryData} from 'src/Actions/ActionCreator';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  
  
  display: 'flex',
  
  flexDirection: 'column',
  padding: theme.spacing(0, 5, 0, 5)
  
}));


const useStyles = makeStyles((theme) => ({
   root: {
     backgroundColor: theme.palette.background.dark,
     minHeight: '100%',
     paddingBottom: theme.spacing(3),
   //   paddingTop: theme.spacing(3)
   },
   root2: {},
   avatar: {
     height: 250,
     width: 250
   },
   formControl: {
     margin: theme.spacing(0),
     minWidth: '100%',
   },
   selectEmpty: {
     marginTop: theme.spacing(2),
   },
 
 }));



// ----------------------------------------------------------------------

const AddSummary = ({className, ...rest}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const UserData = useSelector(state => state.login.data); 

 

  const [values, setValues] = useState({
    "exam_id" :UserData.exam_id,
    "slot_id" :"",
    "ci_id" :UserData.ci_id,
    "ec_id" :UserData.ec_id,
    "is_rej": false,
    "rej_type" : "",
    "is_awa":false,
    "is_rwa":false,
    "awa_data": [],
    "rwa_data":[],
    "rwa_count":0,
    "awa_count" : 0
  });
 
 
  const handleReject = () =>{
    if(values.slot_id!==null && values.slot_id!==undefined && values.slot_id!==""){
      const sendData = {
        "exam_id" :UserData.exam_id,
        "slot_id" :values.slot_id,
        "ci_id" :UserData.ci_id,
        "ec_id" :UserData.ec_id,
        "rej_type" : "",
        "awa_data": [],
        "is_rej": false,
        "awa_count" : 0,
        "is_awa":false,
        "rwa_data":[],
        "rwa_count":0,
        "is_rwa":false
      }
      dispatch(addRejSummary(sendData))

    }else{
      tostW("Please Select Slot!!")
    }
  }

  const handleAccept = () =>{
    setValues({
      ...values,
      ['is_rej']:true
    })
  }

  const handleChangeRejType = (event) =>{
    const rejection_type = event.target.value;
    if(rejection_type==="RWA"){
      setValues({
        ...values,
        ['rej_type']:rejection_type,
        ['is_rwa']:true,
        ['is_awa']:false,
        ['awa_count']:0
      })

    }
    if(rejection_type==="AWA"){
      setValues({
        ...values,
        ['rej_type']:rejection_type,
        ['is_rwa']:false,
        ['is_awa']:true,
        ['rwa_count']:0
      })
    }
  }

  const handleChangeRejCount = (event) =>{
    if(values.rej_type!==null && values.rej_type!==undefined && values.rej_type!==""){
      if(values.rej_type==="RWA" && values.is_rwa && (!values.is_awa)){
        setValues({
          ...values,
          ['rwa_count']:event.target.value
        })
      }
      if(values.rej_type==="AWA" && values.is_awa && (!values.is_rwa)){
        setValues({
          ...values,
          ['awa_count']:event.target.value
        })
      }
    }else{
      tostW("Please Select Rejection Type!!")
    }
  }

   const handleChangeSlot = (event) => {
     setValues({
       ...values,
       ['slot_id']: event.target.value,
     });
   };
 
   const SlotData = useSelector(state => state.login.data && state.login.data.slot_details && Array.isArray(state.login.data.slot_details) && state.login.data.slot_details.length ? state.login.data.slot_details : []);

  const addmsg = useSelector((state) => state.RejectionSummary  && state.RejectionSummary.fillData  && state.RejectionSummary.fillData.message ? state.RejectionSummary.fillData.message : null  )
  const adderror = useSelector((state) => state.RejectionSummary  && state.RejectionSummary.fillError && state.RejectionSummary.fillError.message ? state.RejectionSummary.fillError.message : null  )
   
   
useEffect( () => {


  let x  = new Promise((resolve ,reject)=>{
    if(addmsg !==null && typeof(addmsg) === 'string')
    {
      tostS(addmsg)
    }
    if(adderror !==null && typeof(adderror) === 'string')
    {
      tostE(adderror)
    }

    resolve()

  })
  x.then(()=>{

 dispatch(cleanAddRejSummaryData())


  })


  
  }, [addmsg , adderror])
  


  const handleSubmit =() =>{
    var flag = true;
    if(values.slot_id==="" && values.slot_id===null && values.slot_id===undefined){
      tostW("Please Select Slot!!")
    }else{
      if(values.rej_type==="" && values.rej_type===null && values.rej_type===undefined){
        tostW("Please Select Rejection Type!!")        
      }else{
        if(values.rej_type==="AWA" && values.is_awa){
          if(values.awa_count<1){
            tostW("Rejection Count can't be zero")
          }else{
            for(let z=0;z<inputList.length;z++){
              if(inputList[z]['hall_ticket']!==""){
                if(inputList[z]['reason']!==""){
                  console.log("validated")
                }else{
                  tostE(`Please Enter Reason for ${z+1}st Rejection!!`)
                  flag = false;
                  break;
                }
              }else{
                tostE(`Please Hall TIcket Number of ${z+1}st Rejection!!`)
                flag = false;
                break;
              }
            }
            if(flag){
              const sendData = {
                "exam_id" :UserData.exam_id,
                "slot_id" :values.slot_id,
                "ci_id" :UserData.ci_id,
                "ec_id" :UserData.ec_id,
                "is_rej": values.is_rej,
                "rej_type" : values.rej_type,
                "is_awa":values.is_awa,
                "is_rwa":false,
                "awa_data": [...inputList],
                "rwa_data":[],
                "rwa_count":0,
                "awa_count" : values.awa_count
              }
              console.log(sendData)
              
              dispatch(addRejSummary(sendData))
              setValues({
                ...values,
                ['slot_id'] :"",
                ['is_rej']: false,
                ['rej_type'] : "",
                ['is_awa']:false,
                ['is_rwa']:false,
                ['awa_data']: [],
                ['rwa_data']:[],
                ['rwa_count']:0,
                ['awa_count'] : 0
              })
            }
          } 
        }
        if(values.rej_type==="RWA" && values.is_rwa){
          if(values.rwa_count<1){
            tostW("Rejection Count can't be zero")
          }else{
            for(let z=0;z<inputList.length;z++){
              if(inputList[z]['hall_ticket']!==""){
                if(inputList[z]['reason']!==""){
                  console.log("validated")
                }else{
                  tostE(`Please Enter Reason for ${z+1}st Rejection!!`)
                  flag = false;
                  break;
                }
              }else{
                tostE(`Please Hall TIcket Number of ${z+1}st Rejection!!`)
                flag = false;
                break;
              }
            }
            if(flag){
              const sendData = {
                "exam_id" :UserData.exam_id,
                "slot_id" :values.slot_id,
                "ci_id" :UserData.ci_id,
                "ec_id" :UserData.ec_id,
                "is_rej": values.is_rej,
                "rej_type" : values.rej_type,
                "is_awa":false,
                "is_rwa":values.is_rwa,
                "awa_data": [],
                "rwa_data":[...inputList],
                "rwa_count":values.rwa_count,
                "awa_count" : 0
              }
              console.log(sendData)
              dispatch(addRejSummary(sendData))
              setValues({
                ...values,
                ['slot_id'] :"",
                ['is_rej']: false,
                ['rej_type'] : "",
                ['is_awa']:false,
                ['is_rwa']:false,
                ['awa_data']: [],
                ['rwa_data']:[],
                ['rwa_count']:0,
                ['awa_count'] : 0
              })
            }
          } 
        }

      } 
      
    }
  }
  

/* Multi card handling Start */

const [inputList, setInputList] = useState([{hall_ticket:"", reason:""}])


// handle Input Field Change

const handleChangeInputList = (event, index) => {
  var list = [...inputList]

  list[index][event.target.name] = event.target.value;

  setInputList(list);

}

// handle click event of remove


const handleRemoveClick = (index) => {
  const list = [...inputList];
  
  list.splice(index, 1);
  setInputList(list);
  
  };
  
  // handle click of add button
  
  const handleAddClick = () => {
    var flag = true;
    for(let a=0; a<inputList.length;a++){
      if(inputList[a]['hall_ticket']!==""){
        if(inputList[a]['reason']!==""){
          console.log("validated")
        }else{
          tostE(`Please Enter Reason for ${a+1}st Rejection!!`)
          flag = false;
          break;
        }
      }else{
        tostE(`Please Hall TIcket Number of ${a+1}st Rejection!!`)
        flag = false;
        break;
      }
    }
  
    if(flag){
      setInputList([...inputList, {hall_ticket:"", reason:""}])      
    }
  }
  
/* Multi card handling End */


  return (
    <RootStyle title="Add Center">    
      <ToastContainer    />      
      <Container maxWidth = "lg">         
        <ContentStyle>        
            <Card>
                <CardHeader subheader="" title="Rejection Summary Form" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3} mb={3}>                            
                        <Grid item md={6} xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select Slot</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={values.slot_id}
                                    onChange={e => handleChangeSlot(e)}
                                    label="Select Slot"
                                    name="slot_id"
                                >
                                    {SlotData.map((data , i)=>
                                    <MenuItem key={i} value={data.slot_id}>{data.slot_name}</MenuItem>
                                    )}        

                                </Select>
                            </FormControl>
                        </Grid>                                                                                                     
                    </Grid>
                    <Stack>
                      <Typography>Is there no rejection in this slot ? </Typography>
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        {/* <Button style={{margin:3}} color="primary" onClick={()=>{handleAccept()}} variant="contained">
                          Yes
                        </Button>          */}
                        <Button style={{margin:3}} color="primary" onClick={()=>{handleReject()}} variant="contained">
                          Agree
                        </Button>         
                        
                      </Box>
                    </Stack>
                    {values.is_rej ? 
                    <>
                      <Grid container spacing={3}>
                        <Grid item md={6} lg={6} sm={12}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Rejection Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Rejection Type"
                                name="rej_type"
                                onChange={(e)=>{handleChangeRejType(e)}}
                            >
                                
                                <MenuItem value="RWA">Rejected with Annexure</MenuItem>                           
                                <MenuItem value="AWA">Allowed with Annexure</MenuItem>                           

                            </Select>
                        </FormControl>
                        </Grid>
                        <Grid item md={6} lg={6} sm={12}>                          
                          <TextField
                            fullWidth
                            label="Rejection Count"
                            name="rej_count"
                            onChange={e => handleChangeRejCount(e)}
                            value={values.is_rwa ? values.rwa_count : values.awa_count}
                            required
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    {inputList.map((element,i)=>{
                      return(
                        <Card style={{border:"1px solid", borderColor:"#74CAFF", marginTop:"15px"}}>
                          <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6}>
                                  <TextField
                                    fullWidth
                                    label="Hall Ticket Number"
                                    name="hall_ticket"
                                    onChange={e => handleChangeInputList(e,i)}
                                    value={element.hall_ticket}
                                    required
                                    variant="outlined"
                                  />
                                  <Stack direction='row' mt={2} spacing={3}>
                                    <Button variant='contained' onClick={() => handleAddClick()}>Add More</Button>
                                    {inputList.length > 1 ? 
                                    <Button variant='contained' onClick={() => handleRemoveClick(i)}>Remove</Button>
                                    :<></>
                                    }

                                  </Stack>
                                </Grid>
                                <Grid item md={6}>
                                  <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    label="Reason"
                                    onChange={e => handleChangeInputList(e,i)}
                                    value={element.reason}
                                    name="reason"
                                    required
                                    variant="outlined"
                                  />
                                </Grid>
                            </Grid>
                          </CardContent>
                        </Card>

                      
                      )                    
                    })}

                    </>
                     : <></>}                  
                    

                    
                                  
                </CardContent>
                {values.is_rej ?
                <>
                  <Divider />
                  <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button color="primary" onClick={() => handleSubmit()} variant="contained">
                      Save details
                  </Button>
                  </Box>

                </>
                :<></>}
            </Card>    
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}


export default AddSummary;