import { Navigate, useRoutes } from 'react-router-dom';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// layouts../layouts/dashboard


import Intro from '../pages/Intro';
import LoginAdmin from '../pages/AdminScreen/LoginAdmin';
import LoginCi from '../pages/CiScreen/LoginCi';
import CiReg from 'src/pages/CiScreen/CIReg';
import CiRegSuccess from 'src/pages/CiScreen/RegSuccess';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'intro', element:<Intro />},               
        { path: 'register', element:<CiReg />},               
        { path: 'register/success', element:<CiRegSuccess />},               
        { path: 'login/admin', element: <LoginAdmin /> }, 
        { path: 'login/ci', element: <LoginCi /> }, 
        { path: '/', element: <Navigate to="/intro" /> },
        { path: '*', element: <Navigate to="/intro" /> },
      ]
    },

    { path: '*', element: <Navigate to="/intro" replace /> }
  ]);
}
