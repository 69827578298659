import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {changeCiPassBegin , changeCiPassSuccess , changeCiPassFailure} from '../ActionCreator';
import {changeCiProfilePicBegin , changeCiProfilePicSuccess , changeCiProfilePicFailure} from '../ActionCreator';

export const changeCiPass = (data) => {

    return (dispatch) => {
        dispatch(changeCiPassBegin());

        axios.post(Base_URL + '/admin/changePasswordCi', data)
            .then((res) => {
                dispatch(changeCiPassSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(changeCiPassFailure(err.response.data));

                }
            })
    }
}

export const changeCiProfilePic = (data) => {

    return (dispatch) => {
        dispatch(changeCiProfilePicBegin());

        axios.post(Base_URL + '/admin/uploadCiProfilePic', data)
            .then((res) => {
                dispatch(changeCiProfilePicSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(changeCiProfilePicFailure(err.response.data));

                }
            })
    }
}

