import axios from "axios";
import {Base_URL} from '../../config/BaseUrlConfig';

import {changeAdminPassBegin , changeAdminPassSuccess , changeAdminPassFailure} from '../ActionCreator';
import {changeAdminProfilePicBegin , changeAdminProfilePicSuccess , changeAdminProfilePicFailure} from '../ActionCreator';

export const changeAdminPass = (data) => {

    return (dispatch) => {
        dispatch(changeAdminPassBegin());

        axios.post(Base_URL + '/admin/changePasswordAdmin', data)
            .then((res) => {
                dispatch(changeAdminPassSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(changeAdminPassFailure(err.response.data));

                }
            })
    }
}

export const changeAdminProfilePic = (data) => {

    return (dispatch) => {
        dispatch(changeAdminProfilePicBegin());

        axios.post(Base_URL + '/admin/uploadAdminProfilePic', data)
            .then((res) => {
                dispatch(changeAdminProfilePicSuccess(res.data));
            }).catch((err) => {
                console.log(err)
                if (!err.response) {
                    console.log("Something Went wrong : Please Check Network or Server Connectivity")

                }
                else {
                    dispatch(changeAdminProfilePicFailure(err.response.data));

                }
            })
    }
}

