import { withStyles } from '@material-ui/styles';

// ----------------------------------------------------------------------




const GlobalStyles = withStyles((theme) => ({  
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    html: {
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch'
    },
    body: {
      width: '100%',
      height: '100%'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },
    '.cardBorder':{
      border:`1px solid ${theme.palette.primary.lighter}`, 
      marginTop:"25px",
    },
    '.dashBoardCardBorder':{
      border:`1px solid ${theme.palette.primary.lighter}`, 
      marginTop:"25px",
      boxShadow: theme.customShadows.z8
    },
    '.tableRowBottomBorder':{
      borderBottom:`1px solid ${theme.palette.primary.lighter}`, 
    },
    '.dashboardAdminUploadedDataTable':{
      border:`1px solid ${theme.palette.primary.lighter}`, 
      borderRadius:theme.shape.borderRadiusSm,
      marginTop:'15px',
      
    },    
    '.dashboardAdminUploadedDataCell':{
      border:`1px solid ${theme.palette.primary.lighter}`, 
      borderRadius:theme.shape.borderRadius,
      minWidth:'15px',
      textAlign:'start',      
    },    
    '.dashboardAdminUploadedDataCard':{
      padding:'10px',
      border:`1px solid ${theme.palette.primary.lighter}`, 
      boxShadow: theme.customShadows.z8

    },
    '.uploadTableCard':{
      border:`1px solid ${theme.palette.primary.lighter}`, 
      borderRadius:theme.shape.borderRadiusLg,
      marginTop:"25px"
    },
    
    '.uploadFileCard':{
      border:`1px solid ${theme.palette.primary.lighter}`, 
      backgroundColor:`${theme.palette.secondary.lighter}`,
      borderRadius:theme.shape.borderRadiusLg,
      marginTop:"25px"
    },
    
    '.uploadTableHead':{
      borderBottom:`1px solid ${theme.palette.primary.lighter}`,       
      borderRadius:theme.shape.borderRadiusLg,      
    },


    '.MuiInputLabel-root':{
      background:`${theme.palette.background.paper}`,
      padding:"0 5px 0 5px",
      lineHeight:"1em",
      fontWeight:'bold'
    },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    }
  }
}))(() => null);

export default GlobalStyles;
