import React, {useEffect, useState} from 'react';
import { filter } from 'lodash';
import {useNavigate, useLocation} from 'react-router-dom'

import plusFill from '@iconify/icons-eva/plus-fill';

// material
import { 
  Box, 
  Grid, 
  Stack,
  Button,
  Container, 
  Typography, 
  Card, 
  CardHeader,
  Table,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Checkbox,
  Tooltip
} from '@material-ui/core';
import { alpha, styled,useTheme } from '@material-ui/core/styles';

// Icons
import { Icon } from '@iconify/react';

import moreVerticalFill from '@iconify/icons-eva/layers-fill';

// components
import Page from '../../../components/Page';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../../components/_dashboard/user';
import { useFormik } from 'formik';
import Scrollbar from '../../../components/Scrollbar';
import SearchNotFound from '../../../components/SearchNotFound';
import { useSelector , useDispatch , shallowEqual } from 'react-redux';



// API

import {getAllCiList} from '../../../Actions/AdminActions/Ci'
import { color } from '@material-ui/system';
// --------------------------------------------------------------------------------------


const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '50vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 0)
}));


// --------------------------------------------------------------------------------------




// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },  
  { id: 'mobile', label: 'Mobile', alignRight: false },  
  { id: 'email', label: 'Email', alignRight: false },  
  { id: 'is_busy', label: 'Busy Status' },
  { id: 'is_active', label: 'Active Status' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}



const CenterScreen = ()=> {
  const theme = useTheme();
  const {state} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = listData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const listData = useSelector(state => state.CiDetail.allGetData && 
    Array.isArray(state.CiDetail.allGetData) && 
    state.CiDetail.allGetData.length ? state.CiDetail.allGetData : [])


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listData.length) : 0;

  const filteredUsers = applySortFilter(listData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;


   

  useEffect(() =>{
    dispatch(getAllCiList())
    
    

  }, [])

  const addCi = () => {
    navigate('/ci/add')
  }


  return (
    <Page title="All CI">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
              All CI Details
          </Typography>
          <Button
          variant="contained"
          onClick = {() => addCi()}
          startIcon={<Icon icon={plusFill} />}
          >
              Add CI
          </Button>
        </Stack>
        <Card className='cardBorder'>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={listData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { name, mobile, email, address, ci_id, ec_id, exam_id, is_active } = row;
                      const isItemSelected = selected.indexOf(mobile) !== -1;

                      return (
                        <TableRow
                          hover
                          key={mobile}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          className="tableRowBottomBorder"
                        >                                                    
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{mobile}</TableCell>
                          <TableCell align="left">{email}</TableCell>
                          {(exam_id===null || exam_id===undefined || exam_id==="") && (exam_id===null || exam_id===undefined || exam_id==="") ?
                          
                          <TableCell align="left" style={{color:'green'}}>{"Free"}</TableCell>
                          :
                          <TableCell align="left" style={{color:"red"}}>{"Busy"}</TableCell>
                           }
                          <TableCell align="left" style={{color:is_active ? 'green' : 'red'}}>{is_active ? 'Active' : 'In-Active'}</TableCell>

                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={listData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}


export default CenterScreen;