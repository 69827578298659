import React, {useEffect, useState, useRef} from 'react';

// material
import { 
  Box, 
  Stack,
  Alert,
  AlertTitle,
  Container, 
  Typography, 
  Card,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Tooltip,
  Dialog, 
  DialogTitle, 
  DialogActions, 
  DialogContent, 
  DialogContentText
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../../../components/Page';
import {tostS  , tostW , tostE } from '../../../config/Toast'
import Label from '../../../components/Label';

// Icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import { useSelector, useDispatch } from 'react-redux';

import {useNavigate} from 'react-router-dom';

// API

import { uploadRejSummary } from 'src/Actions/CiActions/RejectionSummary';
import {cleanRejSummaryData, cleanUploadedData} from 'src/Actions/ActionCreator'

import {getUploadedData, reqUploadData} from 'src/Actions/CiActions/uploadedData'



export default function DashboardApp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserData = useSelector(state => state.login.data) 
  const AllowedFileExt = ['pdf']

  const [values, setValues] = useState({
     slot_id:"",
     content_type:"Rejection_Summary",
     ec_id:UserData.ec_id,
     exam_id:UserData.exam_id,
     ci_id:UserData.ci_id,      
     img:""
  })

  const SlotData = useSelector(state => state.login.data && state.login.data.slot_details && Array.isArray(state.login.data.slot_details) && state.login.data.slot_details.length ? state.login.data.slot_details : [])
  const uploadLoading = useSelector(state => state.RejectionSummary && state.RejectionSummary.loading ? state.RejectionSummary.loading : false);
  const uploadMsg = useSelector(state => state.RejectionSummary && state.RejectionSummary.data && state.RejectionSummary.data.message ? state.RejectionSummary.data.message : null )
  const uploadErr = useSelector(state => state.RejectionSummary && state.RejectionSummary.error && state.RejectionSummary.error.message ? state.RejectionSummary.error.message : null )
  const uploadProgress = useSelector(state => state.RejectionSummary && state.RejectionSummary.progress ? state.RejectionSummary.progress : null )

  const reqUploadMsg = useSelector(state => state.UploadedData.reqData && state.UploadedData.reqData.status && state.UploadedData.reqData.status.message ? state.UploadedData.reqData.status.message : null )
  const reqUploadErr = useSelector(state => state.UploadedData && state.UploadedData.reqError && state.UploadedData.reqError.message ? state.UploadedData.reqError.message : null )


  const uploadedData = useSelector(state => state.UploadedData.data && Array.isArray(state.UploadedData.data) && state.UploadedData.data.length ? state.UploadedData.data : [])


   const handleChangeSlots = (event) => {
     setValues({
       ...values,
       ['slot_id']:event.target.value
     })
   }
   useEffect(() =>{
    dispatch(getUploadedData(UserData.ec_id, "Rejection_Summary"))

    let x  = new Promise((resolve ,reject)=>{
      if(uploadMsg !==null && typeof(uploadMsg) === 'string')
      {
        tostS(uploadMsg)
        navigate('/ci/rejection-summary')

      }
      if(uploadErr !==null && typeof(uploadErr) === 'string')
      {
        tostE(uploadErr)
        navigate('/ci/rejection-summary')

      }
      if(reqUploadMsg !==null && typeof(reqUploadMsg) === 'string')
      {
        tostS(reqUploadMsg)

      }
      if(reqUploadErr !==null && typeof(reqUploadErr) === 'string')
      {
        tostE(reqUploadErr)
      }

      resolve()

    })
    x.then(()=>{
    dispatch(cleanRejSummaryData())
    dispatch(cleanUploadedData())
   

    })

  }, [uploadMsg, uploadErr, reqUploadMsg, reqUploadErr])

  const handleSubmit = () => {
     console.log(values);
     if(values.slot_id==="" || values.slot_id===null || values.slot_id===undefined) {
       tostW("Please select Slot!!");
     }else if(values.image1==="" || values.image1===null || values.image1===undefined){
       tostW("Please Select File!!");
     }else if(values.img==="" || values.img===null || values.img===undefined){
      tostW("Please Select File!!");
    }else{
      new Promise((resolve, reject) => {

        dispatch(uploadRejSummary(values))

        resolve()
      }).then(() => {
        setValues({
          ...values,
          ["slot_id"]:"",
          ['img']:"",
          ['image1']:""
        })
      })

     }
  }


       /********* File Handling   ************** */
       const hiddenFileInput = useRef(null);
  
       const handleClick = event => {
         hiddenFileInput.current.click();
       };
       const handleChangeimg = event => {
         if(event.target.files.length!==0 && event.target.files[0]){
           const fileUploaded = event.target.files[0];
           const file_ext = fileUploaded.name.split(".")
          
          const is_allowed  = AllowedFileExt.includes(file_ext[file_ext.length -1]);

          if(is_allowed){
            setValues({...values , ['image1']:fileUploaded , ['img']:URL.createObjectURL(fileUploaded)})
            console.log("::::::::::::::::")
          }else{
            tostE("Only PDF files are Allowed!!")
          }


         }
     
     
       };
       
       /********* File Handling   ************** */
    
  const [open, setOpen] = useState({
    show:false,
    id:""
  });
    
  const handleOpen = (data) => {
    setOpen({
      ...open,
      ['show']:true,
      ['id']:data.up_id
    });
  } 

  const handleClickSubmit = () => {
    dispatch(reqUploadData({"up_id" : open.id}))
    setOpen({
      ...open,
      ['show']:false,
      ['id']:""
    });
  };

  const handleClose = () => {
    setOpen({
      ...open,
      ['show']:false,
      ['id']:""
    });
  };

  const addSummary = () => {
    navigate('/rejection-summary/add')
  }


  return (
    <Page title="Rejection Summary">
      <Dialog open={open.show} onClose={handleClose}>
        <DialogTitle>Request to Re upload Rejection Summary</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure You want to Re upload this Rejection Summary?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClickSubmit} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      {uploadLoading && uploadProgress!==null ?
            <Stack spacing={2}>
            <Alert severity="error">
                <AlertTitle>Please Do Not Refresh The Page</AlertTitle>
                This may result to — <strong>Corruption In Your Data.</strong>
            </Alert>
            </Stack>        
        :<></>}
      <Container maxWidth="xl">        
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Upload Rejection Summary
          </Typography>
          <Button
          variant="contained"
          onClick = {() => addSummary()}
          startIcon={<Icon icon={plusFill} />}
          >
              No Rejection
          </Button>
        </Stack>
        
        <Grid item md={6} xs={12} style={{marginBottom:"25px"}}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Slot</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={values.slot_id}
              label="Select Slot"
              name="slot_id"
              onChange={handleChangeSlots}
            >
              {SlotData.map((data , i)=>
                <MenuItem key={i} value={data.slot_id}>{data.slot_name}</MenuItem>
              )}        

            </Select>
          </FormControl>
          </Grid> 

          <Card className="uploadFileCard">
            <CardHeader title="Upload Rejection Summary PDF" />
              {uploadLoading ?
                <LinearProgress variant="buffer" value={uploadProgress} valueBuffer={uploadProgress+5} />
                :
                <Divider />
              }
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              m={6}
              textAlign='center'
            >
              
              <Typography
                color="#2D99FF"
                gutterBottom
                variant="h3"
              >
                {values.image1 ? values.image1.name : uploadProgress!==null ? `${uploadProgress} %` : "No File Selected"}             
                <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChangeimg}
              style={{display: 'none'}} 
            />
              <LoadingButton
                color="primary"
                fullWidth
                variant="contained"
                loading={uploadLoading}
                onClick={handleClick}
              >
                {typeof(uploadMsg)==='number' && uploadLoading ?uploadMsg:  "Select File"}
              </LoadingButton>
              </Typography>              
            </Box>
            <Divider />
            <CardActions>
               <LoadingButton
                  color="primary"
                  fullWidth
                  loading={uploadLoading}
                  variant="outlined"
                  onClick={handleSubmit}
               >
                 Upload Data
               </LoadingButton>
            
            </CardActions>
            </Card>
          <Card className="uploadTableCard">
            <TableContainer>

              <Table>
                <TableHead className='uploadTableHead'>
                  <TableRow>
                    <TableCell>Center Code</TableCell>
                    <TableCell>Slot</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Request To Re-Upload</TableCell>
                  </TableRow>
                </TableHead>
                {uploadedData.length ? 
                  <TableBody>
                    {uploadedData.map((row, i) => (
                      <TableRow key={i} style={{borderBottom: i<uploadedData.length-1 ? "1px solid" : null}}>                    
                        <TableCell>{row.centre_code}</TableCell>
                        <TableCell>{row.slot_name}</TableCell>
                        <TableCell>{row.ci_name}</TableCell>
                        <TableCell>{row.file_name}</TableCell>
                        
                        <TableCell>
                        {row.reupload_req && row.can_reupload ? 
                          <Label
                            variant='filled'
                            color='info'
                          >
                          Request Accepted
                          </Label>
                          :null
                        }
                        {row.reupload_req && !row.can_reupload ? 
                          <Label
                            variant='filled'
                            color='warning'
                          >
                          Request In Progress
                          </Label>
                          :null
                        }
                        {!row.reupload_req && !row.can_reupload ? 
                          <Button variant="outlined" onClick={() => handleOpen(row)}>Reupload</Button>
                          :null
                        }
                          
                            
                          
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                :
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          Not found
                        </Typography>
                        <Typography variant="body2" align="center">
                          No results found. 
                        </Typography>
                      
                    </TableCell>
                  </TableRow>
                </TableBody>
                }
              </Table>
            </TableContainer>
          </Card>
      </Container>
    </Page>
  );
}
