// hooks
import { useSelector } from 'react-redux';
//
import { MAvatar } from './@material-extend';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const user = useSelector(state => state.login.data);

  return (
    <MAvatar
      src={user.images}
      alt={user.name}
      color={user.images ? 'primary' : createAvatar(user.name).color}
      {...other}
    >
      {createAvatar(user.name).name}
    </MAvatar>
  );
}
