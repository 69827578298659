
import React, {useEffect, useState, useRef} from 'react';

// material
import { TextField, IconButton, InputAdornment } from '@material-ui/core';

import axios from 'axios';

import { 
  Box, 
  Stack,
  Alert,
  AlertTitle,
  Container, 
  Typography, 
  Card,
  CardHeader,
  CardActions,
  Button,
  Divider,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell, 
  TableContainer,
  TablePagination,
  Tooltip,
  Dialog, 
  DialogTitle, 
  DialogActions, 
  DialogContent, 
  DialogContentText,
  CardContent,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

// components
import Page from '../../../components/Page';
import {tostS  , tostW , tostE } from '../../../config/Toast'
import Label from '../../../components/Label';

// Icons
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';

import { useSelector, useDispatch } from 'react-redux';

import {useNavigate} from 'react-router-dom';

// API
// import Page from '../../../components/Page';

import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';

import {ToastContainer} from 'react-toastify';

import { uploadRejSummary } from 'src/Actions/CiActions/RejectionSummary';
import {cleanRejSummaryData, cleanUploadedData} from 'src/Actions/ActionCreator'

import {getUploadedData, reqUploadData} from 'src/Actions/CiActions/uploadedData'
import { Base_URL } from 'src/config/BaseUrlConfig';

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  }));
  
  const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // margin: theme.spacing(2, 0, 2, 2)
  }));
  
  const ContentStyle = styled('div')(({ theme }) => ({
    
    
    display: 'flex',
    
    flexDirection: 'column',
    padding: theme.spacing(0, 5, 0, 5)
    
  }));
  
  
  const useStyles = makeStyles((theme) => ({
     root: {
       backgroundColor: theme.palette.background.dark,
       minHeight: '100%',
       paddingBottom: theme.spacing(3),
     //   paddingTop: theme.spacing(3)
     },
     root2: {},
     avatar: {
       height: 250,
       width: 250
     },
     formControl: {
       margin: theme.spacing(0),
       minWidth: '100%',
     },
     selectEmpty: {
       marginTop: theme.spacing(2),
     },
   
   }));
  




const UploadAppendix = () =>{
    const UserData = useSelector(state => state.login.data); 


    const SlotData = useSelector(state => state.login.data && state.login.data.slot_details && Array.isArray(state.login.data.slot_details) && state.login.data.slot_details.length ? state.login.data.slot_details : []);


    const [values , setValues]  = useState(
        {slot_id : "" ,  
        hn : "" ,  
        name : "" ,  
        appendix   :""  , 
        al_or_nal  : "" ,  
        reason : "" ,  
        appendix_file   :"" })


    const handleInput = (e) =>{

        setValues({...values ,  [e.target.name] : e.target.value})


    }

    const handleChangeAppendixType = (e) =>{

        setValues({...values, ['appendix'] : e.target.value })

    }
    const handleChangeSlot = (e) =>{

        setValues({...values, ['slot_id'] : e.target.value })

    }

    const handleChangeAllowedNotAllowed = (e) =>{

        if(e.target.value == 'alw')
        {
            setValues({...values, ['al_or_nal'] : true })

        }
        else
        {
            setValues({...values, ['al_or_nal'] : false })

        }


    }

    const onChangeFile  = (e) =>{

        setValues({...values , ['appendix_file']  :e.target.files[0]})
    }

    const [getData , setGetData] =   useState([])


    const FetcchAppendix  =() =>{
        axios.get(Base_URL + '/admin/getAppendix' , {params : {"exam_id" :UserData.exam_id,
        "ci_id" :UserData.ci_id,
        "ec_id" :UserData.ec_id}}).then((rsult)=>{
            console.log(rsult)
            setGetData(rsult.data.data)
        }).catch((err)=>{
            tostE(err.response.data.message)
        })
    }

    const onSubmit =  () =>{

        if(!values.slot_id)
        {
            tostE("Please Select Slot ")
        }
        else if(!values.hn)
        {
            tostE("Please Enter Hall Ticker Number")
        }
        else if(!values.name){
            
            tostE("Please Enter Candidate Name")
        }
        
        else if(!values.appendix){
            
            tostE("Please Select Appendix Type")
        }
        else if(!values.al_or_nal){
            
            tostE("Please Select Allowed or Not Allowed")
        }
        else if(!values.appendix_file){
            
            tostE("Please Select Appendix File")
        }
        
        else
        {

            
            let data  =  {
                
                "exam_id" :UserData.exam_id,
                "ci_id" :UserData.ci_id,
                "ec_id" :UserData.ec_id,
                "content_type" : "Appendix",
                ...values
                
            }
            
            
            var formData = new FormData()
            
            formData.append('slot_id',data.slot_id);
            formData.append('content_type',data.content_type);
            formData.append('ec_id',data.ec_id);
            formData.append('exam_id',data.exam_id);
            formData.append('ci_id',data.ci_id);    
            formData.append('name',data.name);    
            formData.append('reason',data.reason);    
            formData.append('hn',data.hn);    
            formData.append('appendix',data.appendix);    
            formData.append('al_or_nal',data.al_or_nal);    
            formData.append('img',data.appendix_file);
            
            axios.post(Base_URL+'/admin/uploadAppendix' ,  formData).then((res)=>{
                
                tostS(res.data.message)
                
                window.location.reload()
                
            }).catch((err)=>{
                
                tostE(err.response.data.message)
                
            })
            
        }



    }

    useEffect(()=>{
        FetcchAppendix()
    },[])







    return(
        <RootStyle title="Add Center">    
      <Container maxWidth = "lg">         
      <ToastContainer    />      
        <ContentStyle>        
            <Card>
                <CardHeader subheader="" title="Rejection Summary Form" />
                <Divider />
                <CardContent>

                

                <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Slot</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Slot"
                                name="app_type"
                                onChange={(e)=>{handleChangeSlot(e)}}
                            >
                                 {SlotData.map((data , i)=>
                                    <MenuItem key={i} value={data.slot_id}>{data.slot_name}</MenuItem>
                                    )}                             

                            </Select>
                        </FormControl>
            </Grid>
                <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Hall Ticket Number"
                required
                name="hn"
                onChange={handleInput}
                value={values.hn}
                variant="outlined"
              />
            </Grid>

                <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Candidate Name"
                required
                name="name"
                onChange={handleInput}
                value={values.name}
                variant="outlined"
              />
            </Grid>

                <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Appendix Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Appendix Type"
                                name="app_type"
                                onChange={(e)=>{handleChangeAppendixType(e)}}
                            >
                                
                                <MenuItem value="Appendix_AB">FORMAT OF UNDER TAKING BY CANDIDATE PERMITTED</MenuItem>                           
                                <MenuItem value="Appendix_AC">FORMAT OF UNDER TAKING BY CANDIDATE NOT PERMITTED</MenuItem>                           
                                <MenuItem value="Appendix_AD">FORMAT OF REQUEST LETTER BY CANDIDATE REPORTING LATE</MenuItem>                           
                                <MenuItem value="Appendix_AJ">CERTIFICATE OF ACTION ON MALPRACTICE</MenuItem>                           
                                <MenuItem value="Appendix_AK">CERTIFICATE OF ACTION ON MALPRACTICE</MenuItem>                           

                            </Select>
                        </FormControl>
            </Grid>

                <Grid item md={6} xs={12}>
                <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Allowed or Not Allowed</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Allowed or Not Allowed"
                                name="app_type"
                                onChange={(e)=>{handleChangeAllowedNotAllowed(e)}}
                            >
                                
                                <MenuItem value="alw">Allowed</MenuItem>                           
                                <MenuItem value="nalw">Not Allowed</MenuItem>                           

                            </Select>
                        </FormControl>
            </Grid>


            <Grid item md={6}>
                                  <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    label="Reason"
                                    onChange={handleInput}
                                    value={values.reason}
                                    name="reason"
                                    required
                                    variant="outlined"
                                  />
                                </Grid>

                                <Grid item md={6}>
                           <input type='file'  onChange={onChangeFile} />
               </Grid>

               <Grid  item md={12} >
               <LoadingButton
                  color="primary"
                  fullWidth
                //   loading={uploadLoading}
                  variant="outlined"
                  onClick={onSubmit}
               >
                 Upload Appendix
               </LoadingButton>

               </Grid>

</Grid>




                    


                 </CardContent>
            </Card>  
            <Card className="uploadTableCard">
            <TableContainer>

              <Table>
                <TableHead className='uploadTableHead'>
                  <TableRow>
                    <TableCell>Center Code</TableCell>
                    <TableCell>Slot</TableCell>
                    <TableCell>Uploaded By</TableCell>
                    <TableCell>File Name</TableCell>
                    {/* <TableCell>Request To Re-Upload</TableCell> */}
                  </TableRow>
                </TableHead>
                {getData.length ? 
                  <TableBody>
                    {getData.map((row, i) => (
                      <TableRow key={i} style={{borderBottom: i<getData.length-1 ? "1px solid" : null}}>                    
                        <TableCell>{row.centre_code}</TableCell>
                        <TableCell>{row.file_name.split("_")[1]}</TableCell>
                        <TableCell>{row.ci_name}</TableCell>
                        <TableCell>{row.file_name}</TableCell>
                        
                        {/* <TableCell>
                        {row.reupload_req && row.can_reupload ? 
                          <Label
                            variant='filled'
                            color='info'
                          >
                          Request Accepted
                          </Label>
                          :null
                        }
                        {row.reupload_req && !row.can_reupload ? 
                          <Label
                            variant='filled'
                            color='warning'
                          >
                          Request In Progress
                          </Label>
                          :null
                        }
                        {!row.reupload_req && !row.can_reupload ? 
                          <Button variant="outlined" onClick={() => handleOpen(row)}>Reupload</Button>
                          :null
                        }
                          
                            
                          
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                :
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Typography gutterBottom align="center" variant="subtitle1">
                          Not found
                        </Typography>
                        <Typography variant="body2" align="center">
                          No results found. 
                        </Typography>
                      
                    </TableCell>
                  </TableRow>
                </TableBody>
                }
              </Table>
            </TableContainer>
          </Card>  
        </ContentStyle>
      </Container>
    </RootStyle>
    )

}

export default UploadAppendix